// @flow
import types from './settingConstants';
import {deleteAsync, getAsync, patchAsync, postAsync} from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getCompanySetting = () => async dispatch => {
    dispatch({
        type: types.GET_COMPANY_SETTINGS,
    })
    const response = await getAsync(`/settings/company`);
    dispatch({
        type: types.GET_COMPANY_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postCompanySetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_COMPANY_SETTINGS,
    })
    const response = await postAsync(`/settings/company`,values);
    dispatch({
        type: types.POST_COMPANY_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getInvoiceSetting = () => async dispatch => {
    dispatch({
        type: types.GET_INVOICE_SETTINGS,
    })
    const response = await getAsync(`/settings/invoice`);
    dispatch({
        type: types.GET_INVOICE_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postInvoiceSetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_INVOICE_SETTINGS,
    })
    const response = await postAsync(`/settings/invoice`,values);
    dispatch({
        type: types.POST_INVOICE_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getLookupValues = (type) => async dispatch => {

    const response = await getAsync(`/lookup/${type}/values`);
    dispatch({
        type: types.GET_LOOKUP_VALUES,
        payload: response,
    })
}

export const getQuotationSetting = () => async dispatch => {
    dispatch({
        type: types.GET_QUOTATION_SETTINGS,
    })
    const response = await getAsync(`/settings/quotation`);
    dispatch({
        type: types.GET_QUOTATION_SETTINGS_SUCCESS,
        payload: response
    })
}

export const postQuotationSetting = (values) => async dispatch => {
    dispatch({
        type: types.POST_QUOTATION_SETTINGS,
    })
    const response = await postAsync(`/settings/quotation`, values);
    dispatch({
        type: types.POST_QUOTATION_SETTINGS_SUCCESS,
        payload: response
    })
}

export const getSmtpSettings = () => async (dispatch) => {
    dispatch({ type: types.GET_SMTP_SETTINGS });
    
    const response = await getAsync('/settings/email/smtp');
    
    if (response.isSuccess) {
        dispatch({
            type: types.GET_SMTP_SETTINGS_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.GET_SMTP_SETTINGS_FAILURE,
            payload: response
        });
    }
};

export const patchSmtpSettings = (id, settings) => async (dispatch) => {
    dispatch({ type: types.PATCH_SMTP_SETTINGS });

    const response = await patchAsync(`/settings/email/smtp/${id}`, settings);
    
    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_SMTP_SETTINGS_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_SMTP_SETTINGS_FAILURE,
            payload: response
        })
    }
};

export const postSmtpSettings = (settings) => async (dispatch) => {
    dispatch({ type: types.POST_SMTP_SETTINGS });
    
    const response = await postAsync('/settings/email/smtp', settings);
    
    if (response.isSuccess) {
        dispatch({
            type: types.POST_SMTP_SETTINGS_SUCCESS,
            payload: response
        })
        dispatch(getSmtpSettings())
    } else {
        dispatch({
            type: types.POST_SMTP_SETTINGS_FAILURE,
            payload: response
        })
    }

    return response;
};

export const deleteTestEamil = (id) => async (dispatch) => {
    dispatch({ type: types.DELETE_SMTP_SETTINGS });
    
    const response = await deleteAsync(`/settings/email/smtp/${id}`);
    
    if (response.isSuccess) {
        dispatch({
            type: types.DELETE_SMTP_SETTINGS_SUCCESS,
            payload: response
        })
        dispatch(getSmtpSettings())
    } else {
        dispatch({
            type: types.DELETE_SMTP_SETTINGS_FAILURE,
            payload: response
        })
    }

    return response;
};

export const postTestEamil = (id, testEmail) => async (dispatch) => {
    dispatch({ type: types.POST_TEST_EMAIL });
    
    const response = await postAsync(`/settings/email/smtp/${id}/test`, testEmail);
    
    if (response.isSuccess) {
        dispatch({
            type: types.POST_TEST_EMAIL_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.POST_TEST_EMAIL_FAILURE,
            payload: response
        })
    }

    return response;
};

export const getNotificationTriggers = () => async (dispatch) => {
    dispatch({ type: types.GET_NOTIFICATION_TRIGGERS });
    
    const response = await getAsync('/settings/notification/triggers');
    
    if (response.isSuccess) {
        dispatch({
            type: types.GET_NOTIFICATION_TRIGGERS_SUCCESS,
            payload: response
        });
        return response.data.items;
    } else {
        dispatch({
            type: types.GET_NOTIFICATION_TRIGGERS_FAILURE,
            payload: response
        });
        throw new Error(response);
    }
};

export const getNotificationTemplates = () => async (dispatch) => {
    dispatch({ type: types.GET_NOTIFICATION_TEMPLATES });
    
    const response = await getAsync('/settings/notification/templates');
    
    if (response.isSuccess) {
        dispatch({
            type: types.GET_NOTIFICATION_TEMPLATES_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.GET_NOTIFICATION_TEMPLATES_FAILURE,
            payload: response
        });
    }
};

export const patchNotificationTemplates = (id, templates) => async (dispatch) => {
    dispatch({ type: types.PATCH_NOTIFICATION_TEMPLATES });

    const response = await patchAsync(`/settings/notification/templates/${id}`, templates);
    
    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_NOTIFICATION_TEMPLATES_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_NOTIFICATION_TEMPLATES_FAILURE,
            payload: response
        })
    }
};

export const postNotificationTemplates = (templates) => async (dispatch) => {
    dispatch({ type: types.POST_NOTIFICATION_TEMPLATES });
    
    const response = await postAsync('/settings/notification/templates', templates);
    
    if (response.isSuccess) {
        dispatch(getNotificationTemplates())
        dispatch({
            type: types.POST_NOTIFICATION_TEMPLATES_SUCCESS,
            payload: response
        })
        
    } else {
        dispatch({
            type: types.POST_NOTIFICATION_TEMPLATES_FAILURE,
            payload: response
        })
    }

    return response;
};

export const getLanguageCodes = () => async (dispatch) => {
    dispatch({ type: types.GET_LANGUAGE_CODES });
    
    const response = await getAsync('/languages');

    if (response.isSuccess) {
        const languageCodes = response.data.map((item) => item.code);
        dispatch({
            type: types.GET_LANGUAGE_CODES_SUCCESS,
            payload: languageCodes
        });
        return languageCodes;
    } else {
        dispatch({
            type: types.GET_LANGUAGE_CODES_FAILURE,
            payload: response
        });
        throw new Error(response);
    }

};
