import { createContext, useContext, FC, ReactNode, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useAuth } from '../auth/core/Auth'
import { getAsync } from '../../../_metronic/helpers/httpClient'

interface SystemPolicies {
  data: number[]
  isSuccess: boolean
  status: string
}

interface SystemSettingsContextType {
  policies: number[] | null
  isLoading: boolean
  error: Error | null
}

const SystemSettingsContext = createContext<SystemSettingsContextType | null>(null)

const fetchSystemPolicies = async (): Promise<number[]> => {
  const response = await getAsync("/system-policies")
  if (!response.isSuccess) {
    throw new Error(`Failed to fetch system settings: ${response.status}`)
  }
  return response.data.map((obj: { policyType: number }) => obj.policyType)
}

interface SystemSettingsProviderProps {
  children: ReactNode
}

export const SystemSettingsProvider: FC<SystemSettingsProviderProps> = ({ children }) => {
  const { currentUser } = useAuth()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!currentUser) {
      queryClient.removeQueries(['systemPolicies'])
    }
  }, [currentUser, queryClient])

  const { data: policies, isLoading, error } = useQuery({
    queryKey: ['systemPolicies'],
    queryFn: fetchSystemPolicies,
    enabled: !!currentUser,
    staleTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    cacheTime: Infinity,
  })

  return (
    <SystemSettingsContext.Provider
      value={{
        policies: policies || null,
        isLoading,
        error: error as Error | null,
      }}
    >
      {children}
    </SystemSettingsContext.Provider>
  )
}

export const useSystemSettings = () => {
  const context = useContext(SystemSettingsContext)
  const queryClient = useQueryClient()

  if (!context) {
    throw new Error('useSystemSettings must be used within SystemSettingsProvider')
  }

  const refreshPolicies = () => {
    queryClient.invalidateQueries(['systemPolicies'])
  }

  return {
    ...context,
    refreshPolicies,
  }
}