import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { enumSystemPolicyType, Loader, } from '../../../_metronic/helpers'
import { Row, Col, CardBody, Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { SystemPolicyChecker } from '../../../_metronic/helpers/components/SystemPolicyChecker';
import Swal from 'sweetalert2'
import moment from 'moment';

import "flatpickr/dist/themes/light.css";

import { clearState, getCheckoutPay, postCheckoutPay } from './checkoutActions';
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';


const Pay = (props) => {
    //inputs from url
    const intl = useIntl();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);
    const [paymentMethodType, setPaymentMethodType] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    //inputs from redux
    const {
        order,
        paymentResult,
        paymentMethods,
        payResult,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getCheckoutPay,
        postCheckoutPay
    } = props;

    useEffect(() => {
        getCheckoutPay(searchParams.get("boid"));
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (paymentResult) {
            if (paymentResult.success && order) {
                navigate(`/checkout-management/checkout-thanks/${order.id}`)
            }
            else if (!paymentResult.success && paymentResult.errorMessage) {
                toast.error(paymentResult.errorMessage)
            } else if (!paymentResult.success) {
                toast.error(intl.formatMessage({ id: 'CHECKOUTPAY.PAY.FAILED' }))
            }
        }

    }, [paymentResult])

    useEffect(() => {
        if (payResult) {
            if (payResult.success && !payResult.redirect) {
                navigate(`/checkout-management/checkout-thanks/${order.id}`)
            } else if (payResult.success && payResult.redirect && payResult.redirectUrl) {
                Swal.fire({
                    title: intl.formatMessage({ id: 'CHECKOUTPAY.QUESTION.PAY' }),
                    text: intl.formatMessage({ id: 'CHECKOUTPAY.QUESTION.REDIRECTOPAGE' }),
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: PRIMARY_COLOR,
                    cancelButtonColor: "#dbdfe9",
                    confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
                }).then((result) => {
                    if (result.isConfirmed) {
                        setInloading(true)
                        window.location.href = payResult.redirectUrl
                    }
                });
            }
        }

    }, [payResult])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CHECKOUTPAY.BREADCRUMB.TITLE' }),
            path: '/checkout-management/checkout',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onPaymentMethodSelected = (method) => {
        setPaymentMethodType(method.id)
    }

    const onPaymentClick = () => {
        if (paymentMethodType > 0) {
            if (paymentMethodType == 1) {
                Swal.fire({
                    title: "",
                    text: intl.formatMessage({ id: 'CHECKOUT.CASH.SENTENCE' }),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: intl.formatMessage({ id: 'GENERAL.I_AGREE' })
                }).then((result) => {
                    if (result.isConfirmed) {
                        postCheckoutPay(paymentMethodType)
                    }
                });
            } else {
                postCheckoutPay(paymentMethodType)
            }
        }
        else
            Swal.fire({
                title: "",
                text: intl.formatMessage({ id: 'CHECKOUTPAY.SELECT.PAYMENT.METHOD' }),
                icon: "warning",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: intl.formatMessage({ id: 'GENERAL.OK' })
            })
    }

    const renderOnlineTab = () => {

        return (
            <>
                <li className="nav-item">
                    <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_4"
                        onClick={() => setPaymentMethodType(null)}
                    >
                        {intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.ONLINE' })}
                    </a>
                </li>
            </>
        )
    }

    const renderOnlineTabBody = () => {

        return (
            <>
                <div
                    className="tab-pane fade show active"
                    id="kt_tab_pane_4"
                    role="tabpanel"
                >
                    <ul class="nav nav-pills nav-pills-custom mb-3" role="tablist">
                        {paymentMethods && paymentMethods.map((paymentMethod) => {
                            return (
                                <>
                                    <li class="nav-item mb-3 me-3 me-lg-6" role="presentation">
                                        <a 
                                            class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-100px h-100px py-4" 
                                            data-bs-toggle="pill" 
                                            role="tab" 
                                            onClick={() => onPaymentMethodSelected(paymentMethod)}
                                        >
                                            <div class="nav-icon">
                                                <img alt="" src={paymentMethod.image} class="" />
                                            </div>
                                            <span class="nav-text text-gray-700 text-center fw-bold fs-6 lh-1">
                                                {paymentMethod.description}
                                            </span>
                                            <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                        </a>
                                    </li>
                                </>
                            )
                        })}
                    </ul>
                </div>
            </>
        )
    }

    const renderOfflineTab = (isActive) => {

        return (
            <>
                <li className="nav-item">
                    <a
                        className={`nav-link ${isActive ? 'active' : ''}`}
                        data-bs-toggle="tab"
                        href="#kt_tab_pane_5"
                        onClick={() => setPaymentMethodType(1)}
                    >
                        {intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.OFFLINE' })}
                    </a>
                </li>
            </>
        )
    }

    const renderOfflineTabBody = (isActive) => {

        return (
            <>
                <div 
                    className={`tab-pane fade ${isActive ? 'show active' : ''}`}
                    id="kt_tab_pane_5" 
                    role="tabpanel"
                >
                    <Row>
                        <Col>
                            <ul class="nav nav-pills nav-pills-custom mb-3" role="tablist">
                                <li class="nav-item mb-3 me-3 me-lg-6" role="presentation">
                                    <a 
                                        class="nav-link d-flex justify-content-between flex-column flex-center overflow-hidden w-100px h-100px py-4" 
                                        data-bs-toggle="pill"
                                        onClick={() => setPaymentMethodType(1)}
                                        role="tab" 
                                    >
                                        <div class="nav-icon">
                                            <i class="bi bi-cash-coin fs-2x"></i>
                                        </div>
                                        <span class="nav-text text-gray-700 text-center fw-bold fs-6 lh-1">
                                            {intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.CASH' })}
                                        </span>
                                        <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary"></span>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    const renderPayment = () => {
        if (order == null || order.id <= 0)
            return;

        return (
            <>
                <Card>
                    <CardHeader className="py-3 d-flex bg-primary">
                        <CardTitle>
                            <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.PAYMENT' })}</h4>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>

                        <Row>
                            <Col md={4}>
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                                    <tbody className="fw-semibold text-gray-600">
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    <i class="ki-duotone ki-basket-ok  fs-2 me-2">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                        <span class="path3"></span>
                                                        <span class="path4"></span>
                                                    </i>{intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.ORDER' })}</div>
                                            </td>
                                            <td className="fw-bold text-end">#{order.id}</td>

                                        </tr>
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    <i class="ki-duotone ki-euro  fs-2 me-2">
                                                        <span class="path1"></span>
                                                        <span class="path2"></span>
                                                        <span class="path3"></span>
                                                    </i>{intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.PRICE' })}</div>
                                            </td>
                                            <td className="fw-bold text-end">€{order.price}</td>

                                        </tr>
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    <i className="ki-duotone ki-calendar fs-2 me-2">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>{intl.formatMessage({ id: 'CHECKOUTPAY.FIELD.WISHDATE' })}</div>
                                            </td>
                                            <td className="fw-bold text-end">{moment(order.wishedDeliveryDate).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <br></br>
                        <br></br>

                        <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                            <SystemPolicyChecker 
                                policyId={enumSystemPolicyType.OnlinePayment}
                                fallback={renderOfflineTab(true)}
                            >
                                {renderOnlineTab()}
                                {renderOfflineTab(false)}
                            </SystemPolicyChecker>
                        </ul>
                        <div className="tab-content">
                            <SystemPolicyChecker 
                                policyId={enumSystemPolicyType.OnlinePayment}
                                fallback={renderOfflineTabBody(true)}
                            >
                                {renderOnlineTabBody()}
                                {renderOfflineTabBody(false)}
                            </SystemPolicyChecker>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col md={12}>
                                <button className='btn btn-primary w-100' onClick={() => onPaymentClick()}><span className='fs-2'>{intl.formatMessage({ id: 'GENERAL.PAY' })} </span></button>
                            </Col>
                        </Row>
                    </CardFooter>
                </Card >
            </>
        )
    }


    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'CHECKOUTPAY.BREADCRUMB.DESCRIPTION' })}</PageTitle>
            <Row className='mt-10'></Row>
            {renderPayment()}

            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        order: state.Checkout.order,
        paymentMethods: state.Checkout.paymentMethods,
        paymentResult: state.Checkout.paymentResult,
        payResult: state.Checkout.payResult,
        loading: state.Checkout.loading,
        success: state.Checkout.success
    };
};

const mapActionsToProps = {
    clearState, getCheckoutPay, postCheckoutPay
}

export default connect(mapStateToProps, mapActionsToProps)(Pay)