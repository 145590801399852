import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import {useIntl} from 'react-intl'
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/light.css";

interface FormData {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
}

interface FormErrors {
  startDate?: string;
  endDate?: string;
}

interface DateRangeModalProps {
  show: boolean;
  onHide: () => void;
  onSubmit: (data: FormData) => void;
  initialData?: FormData;
}

const defaultFormData: FormData = {
  title: '',
  description: '',
  startDate: '',
  endDate: ''
};

const CalendarDatePickerComponent: React.FC<DateRangeModalProps> = ({ 
  show, 
  onHide, 
  onSubmit,
  initialData = defaultFormData
}) => {
  const intl = useIntl();
  const [formData, setFormData] = useState<FormData>(initialData);
  const [errors, setErrors] = useState<FormErrors>({});
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const validateDates = () => {
    const newErrors: FormErrors = {};
    const currentDateTime = new Date();

    if (new Date(formData.startDate) < currentDateTime) {
      newErrors.startDate = "Start date and time cannot be in the past";
    }

    if (formData.endDate && formData.startDate) {
      if (new Date(formData.endDate) <= new Date(formData.startDate)) {
        newErrors.endDate = "End date and time must be after start date and time";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (name === 'startDate' || name === 'endDate') {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  const handleDateChange = (date: Date[], name: string) => {
    setFormData(prev => ({
      ...prev,
      [name]: date[0].toISOString()
    }));
    setErrors(prev => ({
      ...prev,
      [name]: undefined
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateDates()) {
      onSubmit(formData);
      onHide();
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{intl.formatMessage({id: 'CALENDAR.DATE.PICKER.MODAL.TITLE'})}</Modal.Title>
      </Modal.Header>
      
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{intl.formatMessage({id: 'CALENDAR.DATE.PICKER.TITLE.FIELD'})}</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              placeholder={intl.formatMessage({id: 'CALENDAR.DATE.PICKER.TITLE.FIELD.TITLE.PLACEHOLDER'})}
              required
            />
          </Form.Group>

          <Row className="mb-3">
            <Col>
              <Form.Group>
                <Form.Label>{intl.formatMessage({id: 'CALENDAR.DATE.PICKER.START.FIELD'})}</Form.Label>
                <Flatpickr
                  value={formData.startDate}
                  onChange={(date) => handleDateChange(date, 'startDate')}
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    minuteIncrement: 15,
                    time_24hr: true,
                  }}
                  className={`form-control ${errors.startDate ? 'is-invalid' : ''}`}
                  required
                />
                {errors.startDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.startDate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group>
                <Form.Label>{intl.formatMessage({id: 'CALENDAR.DATE.PICKER.END.FIELD'})}</Form.Label>
                <Flatpickr
                  value={formData.endDate}
                  onChange={(date) => handleDateChange(date, 'endDate')}
                  options={{
                    enableTime: true,
                    dateFormat: "Y-m-d H:i",
                    minDate: formData.startDate,
                    minuteIncrement: 15,
                    time_24hr: true,
                  }}
                  className={`form-control ${errors.endDate ? 'is-invalid' : ''}`}
                  required
                />
                {errors.endDate && (
                  <Form.Control.Feedback type="invalid">
                    {errors.endDate}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>{intl.formatMessage({id: 'CALENDAR.DATE.PICKER.DESCRIPTION.FIELD'})}</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleChange}
              placeholder={intl.formatMessage({id: 'CALENDAR.DATE.PICKER.DESCRIPTION.FIELD.PLACEHOLDER'})}
              rows={4}
              required
            />
          </Form.Group>

          <div className="d-flex justify-content-end gap-2">
            <Button variant="secondary" onClick={onHide}>
              {intl.formatMessage({id: 'CALENDAR.DATE.PICKER.CANCEL.BUTTON'})}
            </Button>
            <Button variant="primary" type="submit">
              {intl.formatMessage({id: 'CALENDAR.DATE.PICKER.SAVE.BUTTON'})}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CalendarDatePickerComponent;