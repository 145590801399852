
const InvoicePrint = (props) => {

    const { invoice, component, intl } = props;

    const cdnDomain = process.env.REACT_APP_CDN_DOMAIN

    if (invoice === undefined || invoice === null || invoice.id == 0) {
        return null;
    }

    const renderHtml = (text) => {
        if (text === null || text === undefined) {
            return <></>;
        }
        return (
            <div dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }}></div>
        )
    }

    const renderTransactions = () => {
        if (invoice && invoice.paidAmount > 0 && invoice.paidAmount < invoice.totalPriceIncVat) {
            return (
                <>
                    <tr>
                        <td colSpan={2}></td>
                        <td >
                            {intl.formatMessage({ id: 'INVOICE.ALREADY_PAID' })}:
                        </td>
                        <td >
                            € {invoice.paidAmount.toFixed(2)}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}></td>
                        <td>
                            {intl.formatMessage({ id: 'INVOICE.OUTSTANDING' })}:
                        </td>
                        <td >
                            € {(invoice.totalPriceIncVat - invoice.paidAmount).toFixed(2)}
                        </td>
                        <td colSpan={1}>
                        </td>
                    </tr>

                </>
            )
        }
    }

    var pageStyle = `
  
   @page {
       size: A4;
       margin: 20mm;
   }
   body {
       font-family: Arial, sans-serif;
       margin: 0;
       padding: 0;
       width: 100%;
   }
   .container {
       width: 210mm;
       padding: 20mm;
       margin: auto;
   }
   .header, .footer {
       text-align: left;
       margin-bottom: 20px;
   }
       .header{
       height: 100px;
       }
   .header h1 {
       font-size: 24px;
   }
   .header p {
       margin: 0;            
   }
                .logo{
       margin-bottom: 50px;
       }
   .client-info, .company-info {
        height: 150px;
   }        
   .invoice-title {
       font-size: 22px;
       margin-bottom: 10px;
   }
       .invoice-details{
       display: inline-flex;
       }
       .invoice-details p {
       margin-right: 50px;
       }
   table {
       width: 100%;
       border-collapse: collapse;
       margin-top: 20px;
   }
   th, td {
       padding: 8px;
       text-align: left;
       border-bottom: 1px solid #ddd;
   }
   th {
       background-color: #f2f2f2;
   }
   .totals {
       margin-top: 20px;
   }
   .totals p {
       margin: 4px 0;
       text-align: right;
   }
   .bold {
       font-weight: bold;
   }
   .small-text {
       font-size: 0.8em;
       color: #666;
   }
         .total-section {

       }
       .total-section td{
       border:none;
       }

       .border-top-solid{
       border-top: 1px solid black !important;
       }

       `;

    var componentStyle = `
  
   .header, .footer {

       text-align: left;
       margin-bottom: 20px;
   }
       .header{
       height: 50px;
       }
   .header h1 {
       font-size: 24px;
   }
   .header p {
       margin: 0;            
   }
         .logo{
       margin-bottom: 50px;
       }
   .client-info, .company-info {
        height: 150px;
   }        
   .invoice-title {
       font-size: 22px;
       margin-bottom: 10px;
   }
       .invoice-details{
       display: inline-flex;
       }
       .invoice-details p {
       margin-right: 50px;
       }
   table {
       width: 100%;
       border-collapse: collapse;
       margin-top: 20px;
   }
   th, td {
       padding: 8px;
       text-align: left;
       border-bottom: 1px solid #ddd;
   }
   th {
       background-color: #f2f2f2;
   }
   .totals {
       margin-top: 20px;
   }
   .totals p {
       margin: 4px 0;
       text-align: right;
   }
   .bold {
       font-weight: bold;
   }
   .small-text {
       font-size: 0.8em;
       color: #666;
   }
         .total-section {

       }
       .total-section td{
       border:none;
       }

       .border-top-solid{
       border-top: 1px solid black !important;
       }

       `;

    return (
        <>
            <title>&nbsp;</title>
            <style>
                {component ? componentStyle : pageStyle}
            </style>
            <body style={{ minWidth: "700px" }}>
                <div className="container">
                    <div className="header">
                        <p>{invoice.kvk}</p>
                    </div>

                    <div className="logo">
                        <img src={`${cdnDomain}/company/logo.png`} width={300}></img>
                    </div>

                    <div className="client-info">
                        {renderHtml(invoice.customContact)}
                    </div>

                    <div className="invoice-title">{intl.formatMessage({ id: 'PRINT.INVOICE' })}</div>

                    <div className="invoice-details">
                        <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_NUMBER'})}</strong><br></br> {invoice.invoiceNr}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_DATE'})}</strong><br></br> {invoice.invoiceDate}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_EXPIRE_DATE'})}</strong><br></br> {invoice.expiryDate}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_RELATIONSHIPNR'})}</strong><br></br> {invoice.relationshipNr}</p>
                        <p><strong>{intl.formatMessage({id: 'PRINT.INVOICE_ORDER_NUMBER'})}</strong><br></br> {invoice.orderId}</p>

                    </div>

                    <table>
                        <thead>
                        <tr>
                            <th>{intl.formatMessage({id: 'PRINT.INVOICE_Q'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.INVOICE_DESCRIPTION'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.INVOICE_PRICE'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.INVOICE_AMOUNT'})}</th>
                            <th>{intl.formatMessage({id: 'PRINT.INVOICE_VAT'})}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                                invoice && invoice.invoiceItems && invoice.invoiceItems.map((item, index) => {
                                    return (
                                        <tr key={`table_row_${index}`}>
                                            <td>{item.quantity}</td>
                                            <td style={{ wordBreak: "break-all", width: "300px" }}>{renderHtml(item.description)}</td>
                                            <td>€{item.unitPrice}</td>
                                            <td>€{item.totalPrice}</td>
                                            <td>{item.vat}%</td>
                                        </tr>
                                    );
                                })
                            }
                        </tbody>
                        <tfoot className="total-section">

                        <tr>
                            <td colSpan={2}></td>
                            <td>{intl.formatMessage({id: 'PRINT.TOTAL_EXCLUSIEF_BTW'})}</td>
                            <td>€{invoice.totalPrice}</td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td>{intl.formatMessage({id: 'PRINT.TOTAL_BTW'})}</td>
                            <td>€{invoice.vatPrice}</td>
                            <td colSpan={1}></td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                            <td colSpan={3}></td>
                            </tr>

                            <tr >
                                <td colSpan={2}></td>
                                <td className="bold border-top-solid">{intl.formatMessage({id: 'PRINT.TOTAL_INC_BTW'})}</td>
                                <td className='border-top-solid'>€{invoice.totalPriceIncVat}</td>
                                <td colSpan={1} className="border-top-solid"></td>
                            </tr>
                            {renderTransactions()}
                        </tfoot>
                    </table>

                    <div className="footer small-text">
                        <p> </p>
                    </div>
                </div>
            </body>
        </>
    );
};


export default InvoicePrint;