import { FC, ReactNode, ReactElement } from 'react';
import { useSystemSettings } from "../../../app/modules/system-settings/SystemSettingsContext";

interface MultiSystemPolicyCheckerProps {
  policies: number[];
  requireAll?: boolean;
  children: ReactNode;
  fallback?: ReactNode;
}

export const MultiSystemPolicyChecker: FC<MultiSystemPolicyCheckerProps> = ({ 
  policies: requiredPolicies, 
  requireAll = true, 
  children,
  fallback = null,
}): ReactElement | null => {
  const { policies, isLoading } = useSystemSettings();
  
  if (isLoading) return null;

  if (!policies) return <>{fallback}</>;

  const hasAccess = requireAll
    ? requiredPolicies.every(id => policies.includes(id))
    : requiredPolicies.some(id => policies.includes(id));

  if (!hasAccess) return <>{fallback}</>;

  return <>{children}</>;
};

interface SystemPolicyCheckerProps {
  policyId: number;
  children: ReactNode;
  fallback?: ReactNode;
}

export const SystemPolicyChecker: FC<SystemPolicyCheckerProps> = ({ 
  policyId, 
  children, 
  fallback = null,
}): ReactElement | null => {
  return (
    <MultiSystemPolicyChecker 
      policies={[policyId]} 
      fallback={fallback}
    >
      {children}
    </MultiSystemPolicyChecker>
  );
};

export default MultiSystemPolicyChecker;
