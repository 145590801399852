export enum enumPolicies {
    UserRead = 1,
    UserEdit = 2,
    UserWrite = 3,
    UserDealerRead = 4,
    
    ProductRead = 5,
    ProductEdit = 6,
    ProductWrite = 7,
    ProductPriceRead = 8,
    
    PriceRead = 9,
    PriceEdit = 10,
    
    StockRead = 11,
    StockEdit = 12,
    StockWrite = 13,
    
    OrderCheckoutRead = 14,
    OrderCheckoutEdit = 15,
    OrderCheckoutWrite = 16,
    
    OrderRead = 17,
    OrderEdit = 18,
    OrderWrite = 19,
    
    OrderAdmin = 20,
    OrderPersonnel = 21,
    OrderDealer = 22,
    
    DevSupportRead = 23,
    DevSupportEdit = 24,
    DevSupportWrite = 25,

    Settings = 26,

    AdsLeadEdit = 27,
      
    InvoiceRead = 28,
    InvoiceEdit = 29,
    InvoiceWrite = 30,
    
    SystemSettingsRead = 31,
    SystemSettingsWrite = 32,
    
    CustomerRead = 33,
    CustomerEdit = 34,
    CustomerWrite = 35,
    
    QuoteRead = 36,
    QuoteEdit = 37,
    QuoteWrite = 38,

    CalendarEventRead = 39,
    
    DiscountRead = 40,
    DiscountEdit = 41,
    DiscountWrite = 42
};
