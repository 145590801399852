import React, { useState, useEffect } from 'react';
import { useAuth } from './auth'
import { getAsync } from '../../_metronic/helpers/httpClient'
import { useNavigate } from "react-router-dom";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { enumPolicies, enumCalendarEventType } from '../../_metronic/helpers'

const CalendarComponent: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [ calendarEvents, setCalendarEvents ] = useState([]);

  useEffect(() => {
    const getCalendarEvents = async () => {
      const response = await getAsync("/calendar-events")
      if (response && response.data) {
        setCalendarEvents(response.data)
      }
    }

    if (currentUser && currentUser.policyIds && currentUser.policyIds.some(policy => policy === enumPolicies.CalendarEventRead)) {
      getCalendarEvents();
    }
  }, [])

  const ToFullCalendarEvent = (calendarEvents) => {
    if (calendarEvents.items == undefined)
      return;

    const ToClassName = (eventIdentifier) => {
      switch (eventIdentifier) {
        case enumCalendarEventType.QuotePreparation:
          return "fc-event-success"
        case enumCalendarEventType.OrderPreparation:
          return "fc-event-solid-primary fc-event-light"
        case enumCalendarEventType.OrderInstallation:
        default:
          return "fc-event-solid-info fc-event-light"
      }
    }

    const ToIsoFormat = (date) => {
      if (date) {
        const dateClass = new Date(date);
        return dateClass.toISOString()
      } else {
        return undefined
      }
    }

    const calendarEventDtos = calendarEvents.items.map(e => ({
      title: e.title,
      description: e.description,
      start: ToIsoFormat(e.start),
      end: ToIsoFormat(e.end),
      className: ToClassName(e.eventIdentifier),
      extendedProps: {
        eventIdentifier: e.eventIdentifier,
        eventSourceId: e.eventSourceId
      }
    }))
    return calendarEventDtos
  }

  const handleEventClick = (clickInfo) => {
    // Prevent default action (like following the URL if specified)
    clickInfo.jsEvent.preventDefault();

    const eventIdentifier = clickInfo.event.extendedProps.eventIdentifier;
    const eventSourceId = clickInfo.event.extendedProps.eventSourceId

    switch (eventIdentifier) {
      case enumCalendarEventType.QuotePreparation:
        navigate(`/order-management/quotes/${eventSourceId}`);
        break;
      case enumCalendarEventType.OrderPreparation:
        case enumCalendarEventType.OrderInstallation:
        navigate(`/order-management/orders/${eventSourceId}`);
        break;
      default:
        console.log('Default event handling');
    }
  }

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
      }}
      height={800}
      contentHeight={780}
      aspectRatio={3}
      nowIndicator={true}
      now={`${new Date().toISOString()}`}
      editable={true}
      dayMaxEvents={true}
      navLinks={true}
      events={calendarEvents && ToFullCalendarEvent(calendarEvents)}
      eventClick={handleEventClick}
      eventContent={(eventInfo) => {
        const description = eventInfo.event.extendedProps.description;
        return (
          <div>
            <div>{eventInfo.event.title}</div>
            {description && <div className="fc-description">{description}</div>}
          </div>
        );
      }}
    />
  );
};

export default CalendarComponent;