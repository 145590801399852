import React, {useEffect, useState, useRef} from 'react';
import {connect} from 'react-redux';
import {useNavigate} from "react-router-dom";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, Loader, Pagination, enumPolicies, enumDiscountType} from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import moment from 'moment';
import {toast} from 'react-toastify';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker';

import {clearState, getDiscounts, getDiscountTypes, getDiscountScopeTypes} from './discountActions';

const Discounts = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();

    const [keyword, setKeyword] = useState('');
    const [isActive, setIsActive] = useState(null);
    const [page, setPage] = useState(1);
    const [discountScopes, setDiscountScopes] = useState([]);
    const [discountTypes, setDiscountTypes] = useState([]);

    //inputs from redux
    const {
        discounts,
        hasNextPage,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getDiscounts,
        getDiscountTypes,
        getDiscountScopeTypes
    } = props;

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [fetchedDiscountTypes, fetchedDiscountScopeTypes] = await Promise.all([
                    props.getDiscountTypes(),
                    props.getDiscountScopeTypes()
                ]);

                if (fetchedDiscountTypes) {
                    setDiscountTypes(fetchedDiscountTypes);
                }
                
                if (fetchedDiscountScopeTypes) {
                    setDiscountScopes(fetchedDiscountScopeTypes);
                }
            } catch (error) {
                toast.error(intl.formatMessage({ id: 'ERROR.FETCH_INITIAL_DATA' }));
            }
        };

        fetchInitialData();
        getDiscounts();
        return () => {
            clearState();
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success]);

    const discountsBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'DISCOUNTS.BREADCRUMB.TITLE'}),
            path: '/order-management/discounts',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ];

    useEffect(() => {
        setPage(1);
        getDiscounts({
            keyword: keyword,
            isActive: isActive,
            // page: 1
        });
    }, [keyword, isActive]);

    const onPreviousClick = () => {
        var x = page - 1 <= 1 ? 1 : page - 1;
        setPage(x);
        getDiscounts({
            keyword: keyword,
            isActive: isActive,
            // page: x
        });
    };

    const onNextClick = () => {
        setPage(page + 1);
        getDiscounts({
            keyword: keyword,
            isActive: isActive,
            // page: page + 1
        });
    };

    const renderValue = (discountType, value) => {
        switch (discountType) {
            case enumDiscountType.PercentageBased:
                return (
                    <td className="pe-0">
                        <span className="fw-bold">{value}%</span>
                    </td>
                );
            case enumDiscountType.FixedAmount:
                return (
                    <td className="pe-0">
                        <span className="fw-bold">€ {value}</span>
                    </td>
                );
            case enumDiscountType.DiscountCode:
                return (
                    <td className="pe-0">
                        <span className="fw-bold">{value}</span>
                    </td>
                );
            default:
                return (
                    <td className="pe-0">
                        <span className="fw-bold"></span>
                    </td>
                );
        }
    };

    return (
        <>
            <PageTitle breadcrumbs={discountsBreadcrumbs}>{intl.formatMessage({id: 'DISCOUNTS.BREADCRUMB.DESCRIPTION'})}</PageTitle>
            <KTCard>
                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input 
                                type="text" 
                                data-kt-user-table-filter="search" 
                                className="form-control form-control-solid w-250px ps-14" 
                                placeholder={intl.formatMessage({ id: 'DISCOUNTS.FIELD.SEARCHDISCOUNT' })} 
                                value={keyword} 
                                onChange={(e) => 
                                    setKeyword(e.target.value)
                                } 
                            />
                        </div>
                    </div>
                    <div className="card-toolbar">
                        <PolicyChecker policies={[enumPolicies.DiscountWrite]}>
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <Link to={`/order-management/discounts/0`} type="button" className="btn btn-success">
                                    <i className="ki-duotone ki-plus fs-2"></i>
                                    {intl.formatMessage({ id: 'DISCOUNTS.BUTTON.ADDNEW' })}
                                </Link>
                            </div>
                        </PolicyChecker>
                    </div>
                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.NAME'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.TYPE'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.DISCOUNTCODE'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.VALUE'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.STARTDATE'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.ENDDATE'})}</th>
                                <th>{intl.formatMessage({id: 'DISCOUNTS.FIELD.STATUS'})}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                discounts && discounts.map((item, index) => {
                                    return (
                                        <tr key={`discount_${index}`}>
                                            <td data-kt-ecommerce-order-filter="discount_id" onClick={() => navigate(`/order-management/discounts/${item.id}`)}>
                                                <button className="btn btn-link text-gray-800 text-hover-primary fw-bold">{item.id}</button>
                                            </td>
                                            <td className="pe-0" onClick={() => navigate(`/order-management/discounts/${item.id}`)}>
                                                <span className="fw-bold text-hover-primary cursor-pointer">{item.name}</span>
                                            </td>
                                            <td className="pe-0" data-order="New" onClick={() => navigate(`/order-management/discounts/${item.id}`)}>
                                                <div className="badge badge-light-dark">{discountTypes[item.type]}</div>
                                            </td>
                                            <td className="pe-0">
                                                <span className="fw-bold">{item.discountCode}</span>
                                            </td>
                                            {renderValue(item.type, item.value)}
                                            <td className="">
                                                <span className="fw-bold">{moment(item.startDate).format('DD-MM-YYYY')}</span>
                                            </td>
                                            <td className="">
                                                <span className="fw-bold">{item.endDate ? moment(item.endDate).format('DD-MM-YYYY') : '-'}</span>
                                            </td>
                                            <td>
                                                {item.isActive ? 
                                                    <div className="badge badge-light-success fw-bolder">
                                                        {intl.formatMessage({ id: 'DISCOUNTS.FIELD.ACTIVE' })}
                                                    </div> : 
                                                    <div className="badge badge-light-danger">
                                                        {intl.formatMessage({ id: 'DISCOUNTS.FIELD.INACTIVE' })}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
                <div className='card-footer'>
                    <Pagination page={page} hasNextPage={hasNextPage} onPreviousClick={() => onPreviousClick()} onNextClick={() => onNextClick()}></Pagination>
                </div>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        discounts: state.Discount.discounts,
        hasNextPage: state.Discount.hasNextPage,
        loading: state.Discount.loading,
        success: state.Discount.success
    };
};

const mapActionsToProps = {
    clearState, 
    getDiscounts,
    getDiscountTypes, 
    getDiscountScopeTypes
}

export default connect(mapStateToProps, mapActionsToProps)(Discounts)