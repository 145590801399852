// @flow
import types from './calendarEventConstants';

const INIT_STATE = {
    calendarEventList: [],
    calendarEvent: {},
    loading: false,
    success: null,
    error: null
};

const CalendarEvent = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }
        //List
        case types.GET_CALENDAR_EVENTS:
            return {
                ...state,
                loading: true
            };
        case types.GET_CALENDAR_EVENTS_SUCCESS:

            return {
                ...state,
                calendarEventList: action.payload.data.items,
                loading: false,
                error: null
            };
        case types.GET_CALENDAR_EVENTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //List

        //Get Calendar Event
        case types.GET_CALENDAR_EVENT:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case types.GET_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                calendarEvent: action.payload.data,
                loading: false,
                error: null
            };
        case types.GET_CALENDAR_EVENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        //Get Calendar Event

        //Patch Calendar Event
        case types.PATCH_CALENDAR_EVENT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.PATCH_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                loading: false,
                success: types.PATCH_CALENDAR_EVENT_SUCCESS,
                error: null
            };
        case types.PATCH_CALENDAR_EVENT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Patch Calendar Event

        //Post Calendar Event
        case types.POST_CALENDAR_EVENT:
            return {
                ...state,
                success: null,
                loading: true
            };
        case types.POST_CALENDAR_EVENT_SUCCESS:
            return {
                ...state,
                customer: action.payload.data,
                loading: false,
                success: types.POST_CALENDAR_EVENT_SUCCESS,
                error: null
            };
        case types.POST_CALENDAR_EVENT_FAILED:
            return {
                ...state,
                error: action.payload,
                success: null,
                loading: false
            };
        //Post Calendar Event

        default:
            return state;
    }
};

export default CalendarEvent;
