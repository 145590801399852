import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useAuth } from '../../../app/modules/auth'
import { KTSVG, KTCardBody, Loader, toAbsoluteUrl, KTIcon, enumProductTypes, enumUnitPriceTypes, enumVerandaPriceTypes } from '../../../_metronic/helpers'
import { Button, Row, Col, CardBody, Card, CardHeader, CardTitle, CardFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { clearState, getForm, getCart, postCart, postCalculateCart, uploadFile } from './checkoutActions';


const Checkout = (props) => {
    //inputs from url
    const intl = useIntl();
    const { currentUser } = useAuth()
    const [inLoading, setInloading] = useState(false);
    const navigate = useNavigate();

    //DO NOT CHANGE
    const perMeter = "per meter";
    const perM2 = "per m2";
    const perSet = "per set";
    const perStuck = "per stuk";
    //DO NOT CHANGE

    //inputs from redux
    const {
        data,
        cart,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getForm,
        getCart,
        postCart,
        postCalculateCart,
        uploadFile
    } = props;

    const [checkoutModel, setCheckoutModel] = useState({});
    const [formType, setFormType] = useState(1);
    const [customFile, setCustomFile] = useState();
    const [bookmark, setBookmark] = useState('');
    const [bookmarkOpen, setBookmarkOpen] = useState(false);

    const sideDropdown = useRef();

    useEffect(() => {

        getForm();
        //getCart();

        runScript();


        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.TOTALPRICE' }) + ": €" + success);
        }
    }, [success])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CHECKOUT.BREADCRUMB.TITLE' }),
            path: '/checkout-management/checkout',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const runScript = () => {
        var floatingDiv = document.getElementsByClassName('fixed-div')[0];
        var targetSections = document.getElementsByClassName('target-section')

        window.addEventListener('scroll', function () {
            if (floatingDiv && window.innerWidth > 992)
                floatingDiv.style.top = 70 + 'px';
            else
                floatingDiv.style.top = 0 + 'px';

            if (targetSections && targetSections.length > 0) {
                for (var i = 0; i < targetSections.length; i++) {
                    targetSections[i].style.scrollMarginTop = window.innerWidth < 992 ? '120px' : '200px';
                }
            }
        });
    }

    const initialValues = {
        verandaData: {
            width: '0',
            depth: '0',
            rearHeight: '0',
            frontHeight: '0',
            roofType: 0,
            materialColor: 0,
            modelType: 0,
            frameColor: 0,
            otherFrameColor: '',
            isVerandaPriceType: true,
            priceType: 0,
            quantity: 1,
            standType: 0,

            ledType: 0,
            ledColor: 0,
            nrOfLedPerColumn: 0
        },
        totalPrice: 0
    }

    const glazenSchuifwandInitialValues = {
        glazenSchuifwandData: {
            width: '0',
            height: '0',
            frameColor: 0,
            glassWidth: 0,
            otherGlassWidth: 0,
            glassHeight: 0,
            otherGlassHeight: 0,
            priceType: 0,
            productType: 0,
            quantity: 0,
            isMeenemerAdded: false
        },
        totalPrice: 0
    }

    const glazenSchuifwandSetInitialValues = {
        glazenSchuifwandSetData: {
            frameColor: 0,
            otherFrameColor: 0,
            priceType: '5',
            productType: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const glazenSchuifwandAccessoiresInitialValues = {
        basicData: [
            {
                priceType: enumUnitPriceTypes.GlazenSchuifwandAccessoiresLosseOnderdelen
            }
        ],
        areaData: [
            {
                priceType: enumUnitPriceTypes.GlazenSchuifwandAccessoiresLosseOnderdelen
            }
        ],
        totalPrice: 0
    }

    const spieInitialValues = {
        spieData: {
            materialType: 0,
            frameColor: 0,
            otherFrameColor: 0,
            materialColor: 0,
            width: 0,
            frontHeight: 0,
            rearHeight: 0,
            side: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const geheelInitialValues = {
        geheelPolyData: {
            priceType: enumUnitPriceTypes.AccessoiresGeheelPolycarbonaat,
            productType: enumProductTypes.Geheel_Polycarbonaat,
            widthTypes: 0,
            heightTypes: 0,
            frameColor: 0,
            otherFrameColor: 0,
            materialColor: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const aluminiumRabatInitialValues = {
        aluminiumRabatData: {
            priceType: enumUnitPriceTypes.AccessoiresAluminiumRabat,
            productType: enumProductTypes.Aluminium_Rabat,
            frameColor: 0,
            otherFrameColor: '',
            materialColor: 0,
            width: 0,
            height: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const zonweringInitialValues = {
        zonweringData: {
            priceType: enumVerandaPriceTypes.Zonwering,
            productType: enumProductTypes.Zonwering,
            isVerandaPriceType: true,
            motorPositionType: 0,
            frameColor: 0,
            materialColor: 0,
            width: 0,
            depth: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const rsScreenInitialValues = {
        rsScreenData: {
            priceType: enumVerandaPriceTypes.RsScreen,
            productType: enumProductTypes.RS_Screen,
            isVerandaPriceType: true,
            motorPositionType: 0,
            frameColor: 0,
            materialColor: 0,
            width: 0,
            height: 0,
            quantity: 0
        },
        totalPrice: 0
    }

    const lamellendakInitialValues = {
        lamellendakData: {
            priceType: enumVerandaPriceTypes.Lamellendak,
            productType: enumProductTypes.Lamellendak,
            isVerandaPriceType: true,
            frameColor: 0,
            otherFrameColor: '',
            width: 0,
            depth: 0,
            quantity: 0,
        },
        totalPrice: 0
    }

    const verandaAccessoiresInitialValues = {
        losseData: [
        ],
        glassData: [
        ],
        overigeData: [
        ],
        totalPrice_1: 0,
        totalPrice_2: 0,
        totalPrice_3: 0
    }

    const accessoiresInitialValues = {
        itemsData: [
        ],
        totalPrice: 0
    }

    const glazenSchuifwandValidationSchema = Yup.object().shape({
        glazenSchuifwandData: Yup.object({
            productType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            priceType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            glassWidth: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            otherGlassWidth: Yup.number().when(['glassWidth'], {
                is: (glassWidth) => glassWidth == "9999",
                then: (schema) => schema
                    .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            glassHeight: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            otherGlassHeight: Yup.number().when(['glassHeight'], {
                is: (glassHeight) => glassHeight == "9999",
                then: (schema) => schema
                    .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            frontHeight: Yup.number().when('materialType', {
                is: (materialType) => materialType == 1,
                then: (schema) => schema
                    .min(12, intl.formatMessage({ id: "VALIDATION.MIN_FIELD" }) + 12)
                    .max(28, intl.formatMessage({ id: "VALIDATION.MAX_FIELD" }) + 28)
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            rearHeight: Yup.number().when('materialType', {
                is: (materialType) => materialType == 1,
                then: (schema) => schema
                    .min(12, intl.formatMessage({ id: "VALIDATION.MIN_FIELD" }) + 12)
                    .max(80, intl.formatMessage({ id: "VALIDATION.MAX_FIELD" }) + 80)
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const glazenSchuifwandSetValidationSchema = Yup.object().shape({
        glazenSchuifwandSetData: Yup.object({
            productType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const glazenSchuifwandAccessoiresValidationSchema = Yup.object().shape({

    })

    const spieValidationSchema = Yup.object().shape({
        spieData: Yup.object({
            materialType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frontHeight: Yup.number().when('materialType', {
                is: (materialType) => materialType == 1,
                then: (schema) => schema
                    .min(12, intl.formatMessage({ id: "VALIDATION.MIN_FIELD" }) + 12)
                    .max(28, intl.formatMessage({ id: "VALIDATION.MAX_FIELD" }) + 28)
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            rearHeight: Yup.number().when('materialType', {
                is: (materialType) => materialType == 1,
                then: (schema) => schema
                    .min(12, intl.formatMessage({ id: "VALIDATION.MIN_FIELD" }) + 12)
                    .max(80, intl.formatMessage({ id: "VALIDATION.MAX_FIELD" }) + 80)
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const geheelValidationSchema = Yup.object().shape({
        geheelPolyData: Yup.object({
            widthTypes: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            heightTypes: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            materialColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const aluminiumRabatValidationSchema = Yup.object().shape({
        aluminiumRabatData: Yup.object({
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            height: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            otherFrameColor: Yup.string().when(['frameColor'], {
                is: (frameColor) => frameColor == "9999",
                then: (schema) => schema
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const zonweringValidationSchema = Yup.object().shape({
        zonweringData: Yup.object({
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            depth: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            materialColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            motorPositionType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const rsScreenValidationSchema = Yup.object().shape({
        rsScreenData: Yup.object({
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            height: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            materialColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            motorPositionType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const lamellendakValidationSchema = Yup.object().shape({
        lamellendakData: Yup.object({
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            depth: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            otherFrameColor: Yup.string().when(['frameColor'], {
                is: (frameColor) => frameColor == 9999,
                then: (schema) => schema
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            quantity: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
        })
    })

    const verandaAccessoiresValidationSchema = Yup.object().shape({})

    const accessoiresValidationSchema = Yup.object().shape({})

    const validationSchema = Yup.object().shape({
        verandaData: Yup.object({
            width: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            depth: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            roofType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            materialColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            modelType: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            frameColor: Yup.number()
                .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
            otherFrameColor: Yup.string().when(['frameColor'], {
                is: (frameColor) => frameColor == 9999,
                then: (schema) => schema
                    .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),

            ledType: Yup.number(),
            ledColor: Yup.number().when('ledType', {
                is: (ledType) => ledType && ledType > 0,
                then: (schema) => schema
                    .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            }),
            nrOfLedPerColumn: Yup.number().when('ledType', {
                is: (ledType) => ledType && ledType > 0,
                then: (schema) => schema
                    .min(1, intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
                    .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
                otherwise: (schema) => schema
            })
        })
    })

    const customValidationSchema = Yup.object().shape({
        customData: Yup.object({
            description: Yup.string(),
            attachment: Yup.string()
        })
    })

    const action = async (request, model, formik) => {

        try {
            if (request.button == 0) {
                var response = await postCalculateCart(JSON.stringify(model))
                formik.setFieldValue('totalPrice', JSON.parse(response.data.data).TotalPrice)
            } else {
                var response = await postCart(JSON.stringify(model))
                formik.resetForm();
                //formik.setFieldValue('totalPrice', JSON.parse(response.data.data).TotalPrice)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const verandaFormik = useFormik({
        initialValues: initialValues,
        validationSchema: formType == 1 ? validationSchema : customValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            if (request.verandaData.roofType == "1")
                request.verandaData.priceType = enumVerandaPriceTypes.VerandaDeluxeMiniCubeClassicWithGetint;
            else
                request.verandaData.priceType = enumVerandaPriceTypes.VerandaDeluxeMiniCubeClassic;

            if (request.verandaData.frameColor != "9999")
                request.verandaData.otherFrameColor = "";

            var model = {
                verandaData: [request.verandaData]
            }

            await action(request, model, verandaFormik)

            // var file = customFile && customFile.target && customFile.target.files && customFile.target.files[0];
            // if (file) {

            //     var uploadResponse = await uploadFile({
            //         id: file.name,
            //         file: file,
            //         name: file.name,
            //         actionType: 5,
            //         extension: file.type.split('/')[1],
            //     })

            //     if (uploadResponse.isSuccess) {
            //         request.customData.attachment = uploadResponse.data.path;

            //         await postCart(JSON.stringify(request))
            //     }

            // } else {
            //     await postCart(JSON.stringify(request))
            // }

            setInloading(false);
        },
    })

    const glazenSchuifwandFormik = useFormik({
        initialValues: glazenSchuifwandInitialValues,
        validationSchema: glazenSchuifwandValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            if (request.glazenSchuifwandData.isMeenemerAdded == "true")
                request.glazenSchuifwandData.isMeenemerAdded = true;
            else
                request.glazenSchuifwandData.isMeenemerAdded = false;

            if (request.glazenSchuifwandData.glassHeight != "9999")
                request.glazenSchuifwandData.otherGlassHeight = 0;

            if (request.glazenSchuifwandData.glassWidth != "9999")
                request.glazenSchuifwandData.otherGlassWidth = 0;

            var model = {
                glazenSchuifwandData: [request.glazenSchuifwandData]
            }

            await action(request, model, glazenSchuifwandFormik)

            setInloading(false);
        },
    })

    const glazenSchuifwandSetFormik = useFormik({
        initialValues: glazenSchuifwandSetInitialValues,
        validationSchema: glazenSchuifwandSetValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                glazenSchuifwandSetData: [request.glazenSchuifwandSetData]
            }

            await action(request, model, glazenSchuifwandSetFormik)

            setInloading(false);
        },
    })

    const glazenSchuifwandAccessoiresFormik = useFormik({
        initialValues: glazenSchuifwandAccessoiresInitialValues,
        validationSchema: glazenSchuifwandAccessoiresValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                basicData: [...request.basicData],
                areaData: [...request.areaData]
            }

            await action(request, model, glazenSchuifwandAccessoiresFormik)

            setInloading(false);
        },
    })

    const spieFormik = useFormik({
        initialValues: spieInitialValues,
        validationSchema: spieValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            //glass
            if (request.spieData.materialType == "1") {
                request.spieData.priceType = enumUnitPriceTypes.AccesoiresSpieGlas;
                request.spieData.productType = enumProductTypes.Spie_Glas;
            }
            else {
                request.spieData.priceType = enumUnitPriceTypes.AccessoiresSpiePolycarbonaat;
                request.spieData.productType = enumProductTypes.Spie_Polycarbonaat;
                request.spieData.frontHeight = 0;
                request.spieData.rearHeight = 0;
                request.spieData.side = 0;
            }

            var model = {
                spieData: [request.spieData]
            }

            await action(request, model, spieFormik);

            setInloading(false);
        },
    })

    const geheelFormik = useFormik({
        initialValues: geheelInitialValues,
        validationSchema: geheelValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                areaWithColorData: [
                    {
                        ...request.geheelPolyData,
                        width: request.geheelPolyData.widthTypes,
                        height: request.geheelPolyData.heightTypes
                    }
                ]
            };

            await action(request, model, geheelFormik)

            setInloading(false);
        },
    })

    const aluminiumRabatFormik = useFormik({
        initialValues: aluminiumRabatInitialValues,
        validationSchema: aluminiumRabatValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            if (request.aluminiumRabatData.frameColor != "9999")
                request.aluminiumRabatData.otherFrameColor = '';

            var model = {
                aluminiumRabatData: [request.aluminiumRabatData]
            }

            await action(request, model, aluminiumRabatFormik)

            setInloading(false);
        },
    })

    const zonweringFormik = useFormik({
        initialValues: zonweringInitialValues,
        validationSchema: zonweringValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                accessorizeWithMotorData: [request.zonweringData]
            }

            await action(request, model, zonweringFormik)

            setInloading(false);
        },
    })

    const rsScreenFormik = useFormik({
        initialValues: rsScreenInitialValues,
        validationSchema: rsScreenValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                accessorizeWithMotorData: [request.rsScreenData]
            }

            await action(request, model, rsScreenFormik)

            setInloading(false);
        },
    })

    const lamellendakFormik = useFormik({
        initialValues: lamellendakInitialValues,
        validationSchema: lamellendakValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            if (request.lamellendakData.frameColor != "9999")
                request.lamellendakData.otherFrameColor = '';

            var model = {
                areaWithColorData: [request.lamellendakData]
            }

            await action(request, model, lamellendakFormik)

            setInloading(false);
        },
    })

    const verandaAccessoiresFormik = useFormik({
        initialValues: verandaAccessoiresInitialValues,
        validationSchema: verandaAccessoiresValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                lengthyData: [],
                areaData: [],
                basicData: [],
            }

            if (request.type == 1) {
                model.lengthyData = request.losseData.filter(s => s.unit == perMeter && s.quantity > 0 && s.length > 0 && s.productType != enumProductTypes.Goot);
                model.areaData = request.losseData.filter(s => s.unit == perM2 && s.quantity > 0 && s.width > 0 && s.height > 0);
                model.basicData = request.losseData.filter(s => s.unit == perSet && s.quantity > 0);
                model.gootData = request.losseData.filter(s => s.unit == perMeter && s.quantity > 0 && s.length > 0 && s.productType == enumProductTypes.Goot);
            } else if (request.type == 2) {
                model.areaData = request.glassData.filter(s => s.unit == perM2 && s.quantity > 0 && s.width > 0 && s.height > 0);
            } else if (request.type == 3) {
                model.areaData = request.overigeData.filter(s => s.unit == perM2 && s.quantity > 0 && s.width > 0 && s.height > 0);
                model.basicData = request.overigeData.filter(s => s.unit == perStuck && s.quantity > 0);
            }

            try {
                console.log(request)
                console.log(model)

                if (request.button == 0) {
                    var response = await postCalculateCart(JSON.stringify(model))
                    verandaAccessoiresFormik.setFieldValue('totalPrice' + "_" + request.type, JSON.parse(response.data.data).TotalPrice)
                } else {
                    var response = await postCart(JSON.stringify(model))
                }
            }
            catch (e) {
                console.log(e)
            }

            setInloading(false);
        },
    })

    const accessoiresFormik = useFormik({
        initialValues: accessoiresInitialValues,
        validationSchema: accessoiresValidationSchema,
        onSubmit: async (values) => {

            setInloading(true)

            var request = JSON.parse(JSON.stringify(values));

            var model = {
                areaWithColorData: [...request.itemsData.filter(s => s.quantity > 0 && s.width > 0 && s.height > 0)]
            }

            await action(request, model, accessoiresFormik)

            setInloading(false);
        },
    })


    const onFormTypeChange = (value) => {
        setFormType(value)
    }

    const showChildClass = (propName, propValue, operator, formik) => {
        if (operator == '>')
            return formik.getFieldProps(propName).value > propValue ? '' : 'display-none';

        if (operator == '=')
            return formik.getFieldProps(propName).value == propValue ? '' : 'display-none';

        return '';
    }

    const renderHidden = (element, value, formik) => {
        if (formik.getFieldProps(element).value != value)
            formik.setFieldValue(element, value);

        return (
            <input type='hidden' id={element} {...formik.getFieldProps(element)} value={value} />
        )
    }

    const renderElement = (label, element, type, hide, formik, inputProps = {}) => {
        var mainProp = element.split('.')[0];
        var subProp = element.split('.')[1];
        if (!hide) {
            return (
                <div className='fv-row mb-5'>
                    <label htmlFor={element} className='form-label fs-7 fw-bolder mb-3'>
                        {intl.formatMessage({ id: `CHECKOUT.INPUT.${element.split('.').pop().toUpperCase()}` })}
                    </label>
                    <input
                        type={type}
                        className='form-control form-control-sm'
                        id={element}
                        {...inputProps}
                        {...formik.getFieldProps(element)}
                        min={inputProps.min ? inputProps.min : 0}
                    />
                    {formik.touched[mainProp] && formik.touched[mainProp][subProp] && formik.errors[mainProp] && formik.errors[mainProp][subProp] && (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors[mainProp][subProp]}</div>
                        </div>
                    )}
                </div>
            )
        }
        return;
    }

    const renderDropdown = (label, element, list, addOther, firstItem = intl.formatMessage({ id: `GENERAL.SELECT` }), formik, inputProps = {}) => {
        var mainProp = element.split('.')[0];
        var subProp = element.split('.')[1];
        return (
            <div className='fv-row mb-5'>
                <label htmlFor={element} className='form-label fs-7 fw-bolder mb-3'>
                    {intl.formatMessage({ id: `CHECKOUT.INPUT.${element.split('.').pop().toUpperCase()}` })}
                </label>
                <select
                    className='form-control form-select-sm form-select'
                    id={element}
                    {...inputProps}
                    {...formik.getFieldProps(element)}
                >
                    {firstItem != 'none' ? <option value='0'>{firstItem}</option> : <></>}
                    {list && list.map((item, index) => {
                        return (
                            <option key={`dropdown_${element}_${index}`} value={item.value} >{item.name}</option>
                        )
                    })}
                    {addOther ? <option value={"9999"}>{intl.formatMessage({ id: `GENERAL.OTHER` })}</option> : <></>}
                </select>
                {formik.touched[mainProp] && formik.touched[mainProp][subProp] && formik.errors[mainProp] && formik.errors[mainProp][subProp] && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors[mainProp][subProp]}</div>
                    </div>
                )}
            </div>
        )
    }

    const getImagePath = (path, var0, var1) => {
        if (path) {
            path = path.replace("{0}", var0);

            if (var1) {
                path = path.replace("{1}", var1);
            }
        }

        return path;
    }

    const renderFooterButtons = (formik) => {
        return (
            <Row>
                <Col md={3} className='mt-1'>
                    <button 
                        style={{ width: "100%" }} 
                        type='submit' 
                        className='btn btn-secondary fs-4' 
                        onClick={() => 
                            formik.setFieldValue("button", 0)
                        }
                    >
                        {intl.formatMessage({ id: `GENERAL.CALCULATE` })}
                    </button>
                </Col>
                <Col md={3} className='mt-1'>
                    <div className='fv-row text-center  border border-primary rounded'>
                        <label className='form-label fs-2 fw-bolder mt-2'>
                            {"€ " + formik.getFieldProps('totalPrice').value}
                        </label>
                    </div>
                </Col>
                <Col md={6} className='mt-1'>
                    <button 
                        style={{ width: "100%" }} 
                        type='submit' 
                        className='btn btn-primary fs-4' 
                        onClick={() => 
                            formik.setFieldValue("button", 1)
                        }
                    >
                        {intl.formatMessage({ id: `CHECKOUT.FIELD.ADDCART` })}
                    </button>
                </Col>
            </Row>
        )
    }

    const renderVeranda = () => {
        var size = (data.verandaData && data.verandaData.ledTypes && data.verandaData.ledTypes.length) + 1;

        return (
            <Card>
                <CardHeader className="d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.VERANDA` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="collapse show">
                    <form
                        onSubmit={verandaFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderElement('Width (cm)', 'verandaData.width', 'number', null, verandaFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Depth (cm)', 'verandaData.depth', 'number', null, verandaFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Stand Height (cm)', 'verandaData.standType', data && data.verandaData && data.verandaData.standArray, false, 'Select', verandaFormik)}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Roof Type', 'verandaData.roofType', data && data.verandaData && data.verandaData.roofTypes, false, 'Select', verandaFormik)}
                                </Col>
                                <Col md={6}>

                                    {/* glass */}
                                    {verandaFormik.getFieldProps("verandaData.roofType").value == 1 ? renderDropdown('Material Color', 'verandaData.materialColor', data && data.verandaData && data.verandaData.glassColors, false, 'Select', verandaFormik) : <></>}
                                    {/* glass */}

                                    {/* poly */}
                                    {verandaFormik.getFieldProps("verandaData.roofType").value == 2 ? renderDropdown('Material Color', 'verandaData.materialColor', data && data.verandaData && data.verandaData.polyColors, false, 'Select', verandaFormik) : <></>}
                                    {/* poly */}

                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Model', 'verandaData.modelType', data && data.verandaData && data.verandaData.modelTypes, false, 'Select', verandaFormik)}
                                </Col>
                                <Col md={6} >
                                    {renderDropdown('Color', 'verandaData.frameColor', data && data.verandaData && data.verandaData.frameColors, true, 'Select', verandaFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className={showChildClass('verandaData.modelType', 0, '>', verandaFormik)}>
                                    <img className='symbol symbol-circle' width={"200px"} height={"200px"} src={getImagePath(data && data.verandaData && data && data.verandaData.imagePath, verandaFormik.getFieldProps("verandaData.modelType").value)}></img>
                                </Col>
                                <Col md={6}>
                                    {renderElement('Other Color', 'verandaData.otherFrameColor', 'text', verandaFormik.getFieldProps("verandaData.frameColor").value != "9999", verandaFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Lighting Option', 'verandaData.ledType', data.verandaData && data.verandaData.ledTypes, false, 'None', verandaFormik, { size: size })}
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6} className={showChildClass('verandaData.ledType', 0, '>', verandaFormik)}>
                                    {renderDropdown('Color', 'verandaData.ledColor', data.verandaData && data.verandaData.ledColors, false, 'Select', verandaFormik)}
                                </Col>
                                <Col md={6} className={showChildClass('verandaData.ledType', 0, '>', verandaFormik)}>
                                    {renderElement('#of Led per column', 'verandaData.nrOfLedPerColumn', 'number', null, verandaFormik)}
                                </Col>
                            </Row>


                            {renderFooterButtons(verandaFormik)}

                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderGlazenSchuifwand = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SCHUIFWAND` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={glazenSchuifwandFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Name', 'glazenSchuifwandData.productType', data && data.glazenSchuifwandData && data.glazenSchuifwandData.productTypes, false, 'Select', glazenSchuifwandFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Color', 'glazenSchuifwandData.priceType', data && data.glazenSchuifwandData && data.glazenSchuifwandData.priceTypes, false, 'Select', glazenSchuifwandFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} className={showChildClass('glazenSchuifwandData.productType', 0, '>', glazenSchuifwandFormik)}>
                                    <strong>Inclusief</strong>
                                    <p>Bovenrail <br></br> Onderrail <br></br> 2x U-profiel <br></br>{glazenSchuifwandFormik.getFieldProps("glazenSchuifwandData.productType").value - 14}x Tochstrip <br></br>1x Handvat</p>
                                </Col>
                                <Col md={6} className={showChildClass('glazenSchuifwandData.productType', 0, '>', glazenSchuifwandFormik) + " " + showChildClass('glazenSchuifwandData.priceType', 0, '>', glazenSchuifwandFormik)}>
                                    <img className='symbol symbol-circle' width={"200px"} height={"200px"} src={getImagePath(data && data.glazenSchuifwandData && data && data.glazenSchuifwandData.imagePath, glazenSchuifwandFormik.getFieldProps("glazenSchuifwandData.priceType").value, glazenSchuifwandFormik.getFieldProps("glazenSchuifwandData.productType").value)}></img>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Glass Width (cm)', 'glazenSchuifwandData.glassWidth', data && data.glazenSchuifwandData && data.glazenSchuifwandData.glassWidths, true, 'Select', glazenSchuifwandFormik)}
                                </Col>
                                <Col md={6} className={showChildClass('glazenSchuifwandData.glassWidth', "9999", '=', glazenSchuifwandFormik)}>
                                    {renderElement('Other Glass Width (cm)', 'glazenSchuifwandData.otherGlassWidth', 'number', null, glazenSchuifwandFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Glass Height', 'glazenSchuifwandData.glassHeight', data && data.glazenSchuifwandData && data.glazenSchuifwandData.glassHeights, true, 'Select', glazenSchuifwandFormik)}
                                </Col>
                                <Col md={6} className={showChildClass('glazenSchuifwandData.glassHeight', "9999", '=', glazenSchuifwandFormik)}>
                                    {renderElement('Other Glass Height (cm)', 'glazenSchuifwandData.otherGlassHeight', 'number', null, glazenSchuifwandFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Frame Color', 'glazenSchuifwandData.frameColor', data && data.glazenSchuifwandData && data.glazenSchuifwandData.frameColors, false, 'Select', glazenSchuifwandFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Meenemer', 'glazenSchuifwandData.isMeenemerAdded', data && data.glazenSchuifwandData && data.glazenSchuifwandData.boolenArray, false, 'none', glazenSchuifwandFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderElement('Quantity', 'glazenSchuifwandData.quantity', 'number', null, glazenSchuifwandFormik)}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                            {renderFooterButtons(glazenSchuifwandFormik)}

                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderGlazenSchuifwandAccessoires = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SCHUIFWAND` })} - {intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <CardBody>
                        <Card>
                            <CardHeader className="d-flex bg-primary">
                                <CardTitle>
                                    <h5 className="text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.LOSSE.ONDERDELEN` })}</h5>
                                </CardTitle>
                            </CardHeader>
                            <form
                                onSubmit={glazenSchuifwandAccessoiresFormik.handleSubmit}
                                id='kt_checkout'
                                className='form'
                                noValidate
                            >
                                <CardBody>

                                    {
                                        data && data.glazenSchuifwandAccessoiresData && data.glazenSchuifwandAccessoiresData.basicData ? data.glazenSchuifwandAccessoiresData.basicData.map((item, index) => {
                                            return (
                                                <Row key={`glazen_suf_index_${index}`} className=''>
                                                    <Col md={3}>

                                                        <div className='fv-row mt-5'>
                                                            <label className='form-label fs-6 fw-bolder mb-3'>
                                                                {`${item.name} `}
                                                            </label><br></br>
                                                            <label className='form-label fs-6 fw-light mb-3'>
                                                                {` ${item.unit}  ${item.price}€`}
                                                            </label>
                                                        </div>

                                                        {renderHidden(`basicData[${index}].priceType`, item.type, glazenSchuifwandAccessoiresFormik)}
                                                        {renderHidden(`basicData[${index}].productType`, item.productType, glazenSchuifwandAccessoiresFormik)}
                                                    </Col>
                                                    <Col md={3}>
                                                        {item.productType == enumProductTypes.Meenemer ? renderDropdown('Frame Color', `basicData[${index}].frameColor`, data && data.glazenSchuifwandAccessoiresData && data.glazenSchuifwandAccessoiresData.milfinishColors, false, 'Select', glazenSchuifwandAccessoiresFormik) : null}
                                                        {item.productType != enumProductTypes.Meenemer ? renderDropdown('Frame Color', `basicData[${index}].frameColor`, data && data.glazenSchuifwandAccessoiresData && data.glazenSchuifwandAccessoiresData.frameColors, false, 'Select', glazenSchuifwandAccessoiresFormik) : null}
                                                    </Col>
                                                    <Col md={3}>
                                                        {renderElement(`Quantity`, `basicData[${index}].quantity`, 'number', null, glazenSchuifwandAccessoiresFormik)}
                                                    </Col>
                                                </Row>
                                            )
                                        }) : <></>
                                    }

                                    {
                                        data && data.glazenSchuifwandAccessoiresData && data.glazenSchuifwandAccessoiresData.areaData ? data.glazenSchuifwandAccessoiresData.areaData.map((item, index) => {
                                            return (
                                                <>
                                                    <Row key={`glazen_suf_ac_index_${index}`} className=''>
                                                        <Col md={3}>

                                                            <div className='fv-row mt-5'>
                                                                <label className='form-label fs-6 fw-bolder mb-3'>
                                                                    {`${item.name} `}
                                                                </label><br></br>
                                                                <label className='form-label fs-6 fw-light mb-3'>
                                                                    {` ${item.unit}  ${item.price}€`}
                                                                </label>
                                                            </div>
                                                            {renderHidden(`areaData[${index}].priceType`, item.type, glazenSchuifwandAccessoiresFormik)}
                                                            {renderHidden(`areaData[${index}].productType`, item.productType, glazenSchuifwandAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Width (cm)`, `areaData[${index}].width`, 'number', null, glazenSchuifwandAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Height (cm)`, `areaData[${index}].height`, 'number', null, glazenSchuifwandAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Quantity`, `areaData[${index}].quantity`, 'number', null, glazenSchuifwandAccessoiresFormik)}
                                                        </Col>
                                                    </Row>
                                                </>

                                            )
                                        }) : <></>
                                    }

                                </CardBody>
                                <CardFooter>
                                    {renderFooterButtons(glazenSchuifwandAccessoiresFormik)}
                                </CardFooter>
                            </form>
                        </Card>

                        <Card className='mt-5'>
                            <CardHeader className="py-3 d-flex bg-primary">
                                <CardTitle>
                                    <h5 className="text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SCHUIFWAND.SET` })}</h5>
                                </CardTitle>
                            </CardHeader>
                            <div className="fs-6 collapse show">
                                <form
                                    onSubmit={glazenSchuifwandSetFormik.handleSubmit}
                                    id='kt_checkout'
                                    className='form'
                                    noValidate
                                >
                                    <CardBody>

                                        <Row>
                                            <Col md={6}>
                                                {renderDropdown('Name', 'glazenSchuifwandSetData.productType', data && data.glazenSchuifwandSetData && data.glazenSchuifwandSetData.productTypes, false, 'Select', glazenSchuifwandSetFormik)}
                                            </Col>
                                            <Col md={6}>
                                                {renderDropdown('Frame Color', 'glazenSchuifwandSetData.frameColor', data && data.glazenSchuifwandSetData && data.glazenSchuifwandSetData.frameColors, false, 'Select', glazenSchuifwandSetFormik)}
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                {renderElement('Quantity', 'glazenSchuifwandSetData.quantity', 'number', null, glazenSchuifwandSetFormik)}
                                            </Col>
                                            <Col md={6}>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        {renderFooterButtons(glazenSchuifwandSetFormik)}

                                    </CardFooter>
                                </form>
                            </div>
                        </Card>

                    </CardBody>
                </div>
            </Card>

        )
    }

    const renderSpie = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SPIE` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={spieFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Name', 'spieData.materialType', data && data.spieData && data.spieData.roofTypes, false, 'Select', spieFormik)}
                                </Col>
                                <Col md={6}>
                                    {/* glass */}
                                    {spieFormik.getFieldProps("spieData.materialType").value == 1 ? renderDropdown('Material Color', 'spieData.materialColor', data && data.spieData && data.spieData.glassColors, false, 'Select', spieFormik) : <></>}
                                    {/* glass */}

                                    {/* poly */}
                                    {spieFormik.getFieldProps("spieData.materialType").value == 2 ? renderDropdown('Material Color', 'spieData.materialColor', data && data.spieData && data.spieData.polyColors, false, 'Select', spieFormik) : <></>}
                                    {/* poly */}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {/* glass */}
                                    {spieFormik.getFieldProps("spieData.materialType").value == 1 ? renderElement('Width (cm)', 'spieData.width', 'number', null, spieFormik) : <></>}
                                    {/* glass */}

                                    {/* poly */}
                                    {spieFormik.getFieldProps("spieData.materialType").value == 2 ? renderDropdown('Width (cm)', 'spieData.width', data && data.spieData && data.spieData.polyWidthTypes, false, 'Select', spieFormik) : <></>}
                                    {/* poly */}
                                </Col>
                                <Col md={6} className={showChildClass('spieData.materialType', 1, '=', spieFormik)}>
                                    {renderDropdown('Side', 'spieData.side', data && data.spieData && data.spieData.sides, false, 'Select', spieFormik)}
                                </Col>
                            </Row>

                            <Row className={showChildClass('spieData.materialType', 1, '=', spieFormik)}>
                                <Col md={6}>
                                    {renderElement('Front Height (cm)', 'spieData.frontHeight', 'number', null, spieFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Rear Height (cm)', 'spieData.rearHeight', 'number', null, spieFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Frame Color', 'spieData.frameColor', data && data.spieData && data.spieData.frameColors, false, 'Select', spieFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Quantity', 'spieData.quantity', 'number', null, spieFormik)}
                                </Col>
                            </Row>

                            {renderFooterButtons(spieFormik)}

                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderGeheel = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GEHEEL.POLYCARBONAAT` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={geheelFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Width (cm)', 'geheelPolyData.widthTypes', data && data.geheelPolyData && data.geheelPolyData.widthTypes, false, 'Select', geheelFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Height (cm)', 'geheelPolyData.heightTypes', data && data.geheelPolyData && data.geheelPolyData.heightTypes, false, 'Select', geheelFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Frame Color', 'geheelPolyData.frameColor', data && data.geheelPolyData && data.geheelPolyData.frameColors, false, 'Select', geheelFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Material Color', 'geheelPolyData.materialColor', data && data.geheelPolyData && data.geheelPolyData.polyColors, false, 'Select', geheelFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderElement('Quantity', 'geheelPolyData.quantity', 'number', null, geheelFormik)}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                            {renderFooterButtons(geheelFormik)}

                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderaluminiumRabat = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.ALUMINIUM.RABAT` })} </h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={aluminiumRabatFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderElement('Width (cm)', 'aluminiumRabatData.width', 'number', null, aluminiumRabatFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Height (cm)', 'aluminiumRabatData.height', 'number', null, aluminiumRabatFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Frame Color', 'aluminiumRabatData.frameColor', data && data.aluminiumRabatData && data.aluminiumRabatData.frameColors, true, 'Select', aluminiumRabatFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Other Frame Color', 'aluminiumRabatData.otherFrameColor', 'text', aluminiumRabatFormik.getFieldProps("aluminiumRabatData.frameColor").value != "9999", aluminiumRabatFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderElement('Quantity', 'aluminiumRabatData.quantity', 'number', null, aluminiumRabatFormik)}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                            {renderFooterButtons(aluminiumRabatFormik)}


                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderZonwering = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.ZONWERING` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={zonweringFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderElement('Width (cm)', 'zonweringData.width', 'number', null, zonweringFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Depth (cm)', 'zonweringData.depth', 'number', null, zonweringFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Casing Color', 'zonweringData.frameColor', data && data.zonweringData && data.zonweringData.casingColors, null, 'Select', zonweringFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Fabric Color', 'zonweringData.materialColor', data && data.zonweringData && data.zonweringData.fabricColors, null, 'Select', zonweringFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Motor Side', 'zonweringData.motorPositionType', data && data.zonweringData && data.zonweringData.motorTypes, null, 'Select', zonweringFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Quantity', 'zonweringData.quantity', 'number', null, zonweringFormik)}
                                </Col>
                            </Row>

                            {renderFooterButtons(zonweringFormik)}


                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderRsScreen = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.RS.SCREEN` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={rsScreenFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderElement('Width (cm)', 'rsScreenData.width', 'number', null, rsScreenFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Height (cm)', 'rsScreenData.height', 'number', null, rsScreenFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Casing Color', 'rsScreenData.frameColor', data && data.rsScreenData && data.rsScreenData.casingColors, null, 'Select', rsScreenFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderDropdown('Fabric Color', 'rsScreenData.materialColor', data && data.rsScreenData && data.rsScreenData.fabricColors, null, 'Select', rsScreenFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Motor Side', 'rsScreenData.motorPositionType', data && data.rsScreenData && data.rsScreenData.motorTypes, null, 'Select', rsScreenFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Quantity', 'rsScreenData.quantity', 'number', null, rsScreenFormik)}
                                </Col>
                            </Row>

                            {renderFooterButtons(rsScreenFormik)}


                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderlamellendak = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.LAMELLENDAK` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={lamellendakFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Row>
                                <Col md={6}>
                                    {renderElement('Width (cm)', 'lamellendakData.width', 'number', null, lamellendakFormik)}
                                </Col>
                                <Col md={6}>
                                    {renderElement('Depth (cm)', 'lamellendakData.depth', 'number', null, lamellendakFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderDropdown('Color', 'lamellendakData.frameColor', data && data.lamellendakData && data.lamellendakData.frameColors, true, 'Select', lamellendakFormik)}
                                </Col>
                                <Col md={6} className={showChildClass('lamellendakData.frameColor', "9999", '=', lamellendakFormik)}>
                                    {renderElement('Other Color', 'lamellendakData.otherFrameColor', 'number', null, lamellendakFormik)}
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    {renderElement('Quantity', 'lamellendakData.quantity', 'number', null, lamellendakFormik)}
                                </Col>
                                <Col md={6}>
                                </Col>
                            </Row>

                            {renderFooterButtons(lamellendakFormik)}


                        </CardBody>
                    </form>
                </div>
            </Card>

        )
    }

    const renderVerandaAccessoires = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.VERANDA` })} - {intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={verandaAccessoiresFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>
                            <Card>
                                <CardHeader className="d-flex bg-primary">
                                    <CardTitle>
                                        <h5 className="text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.LOSSE.ONDERDELEN` })}</h5>
                                    </CardTitle>
                                </CardHeader>

                                <CardBody>
                                    {
                                        data && data.verandaAccessoiresData && data.verandaAccessoiresData.losse ? data.verandaAccessoiresData.losse.map((item, index) => {
                                            var colors = data.verandaAccessoiresData.frameColors;
                                            if (item.productType == enumProductTypes.Funderingskoker_4X13_cm || item.productType == enumProductTypes.Middenligger_versterkt)
                                                colors = data.verandaAccessoiresData.singleColors;

                                            return (
                                                <Row className=''>
                                                    <Col md={3}>

                                                        <div className='fv-row mt-5'>
                                                            <label className='form-label fs-6 fw-bolder mb-3'>
                                                                {`${item.name} `}
                                                            </label><br></br>
                                                            <label className='form-label fs-6 fw-light mb-3'>
                                                                {` ${item.unit}  ${item.price}€`}
                                                            </label>
                                                        </div>

                                                        {renderHidden(`losseData[${index}].priceType`, item.type, verandaAccessoiresFormik)}
                                                        {renderHidden(`losseData[${index}].productType`, item.productType, verandaAccessoiresFormik)}
                                                        {renderHidden(`losseData[${index}].unit`, item.unit, verandaAccessoiresFormik)}
                                                    </Col>

                                                    <Col md={9}>
                                                        <Row>
                                                            {
                                                                data.gootData && (item.type == data.gootData.priceType) && (item.productType == data.gootData.productType) ? <Col md={3}>{renderDropdown('Type', `losseData[${index}].modelType`, data && data.gootData && data.gootData.modelTypes, false, 'Select', verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            <Col md={3}>{renderDropdown('Frame Color', `losseData[${index}].frameColor`, colors, false, 'Select', verandaAccessoiresFormik)}</Col>

                                                            {
                                                                item.productType == enumProductTypes.Staander ? <Col md={3}>{renderDropdown('Stand Height (cm)', `losseData[${index}].length`, data && data.verandaAccessoiresData && data.verandaAccessoiresData.standArray, false, 'Select', verandaAccessoiresFormik)}</Col> : null
                                                            }

                                                            {
                                                                item.unit == perMeter && item.productType != enumProductTypes.Staander ? <Col md={3}>{renderElement(`Length (cm)`, `losseData[${index}].length`, 'number', null, verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            {
                                                                item.unit == perM2 && item.productType != enumProductTypes.Staander ? <Col md={3}>{renderElement(`Width (cm)`, `losseData[${index}].width`, 'number', null, verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            {
                                                                item.unit == perM2 && item.productType != enumProductTypes.Staander ? <Col md={3}>{renderElement(`Height (cm)`, `losseData[${index}].height`, 'number', null, verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            <Col md={3}>
                                                                {renderElement(`Quantity`, `losseData[${index}].quantity`, 'number', null, verandaAccessoiresFormik)}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            )
                                        }) : <></>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={3} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-secondary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 1) && verandaAccessoiresFormik.setFieldValue('button', 0)}>{intl.formatMessage({ id: `GENERAL.CALCULATE` })}</button>
                                        </Col>
                                        <Col md={3} className='mt-1'>
                                            <div className='fv-row text-center  border border-primary rounded'>
                                                <label className='form-label fs-2 fw-bolder mt-2'>
                                                    {verandaAccessoiresFormik.getFieldProps('totalPrice_1').value + " €"}
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={6} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-primary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 1) && verandaAccessoiresFormik.setFieldValue('button', 1)}>{intl.formatMessage({ id: `CHECKOUT.FIELD.ADDCART` })}</button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                            <Card className='mt-5'>
                                <CardHeader className="d-flex bg-primary">
                                    <CardTitle>
                                        <h5 className="text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.GLASPRIJZEN` })}</h5>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {
                                        data && data.verandaAccessoiresData && data.verandaAccessoiresData.glass ? data.verandaAccessoiresData.glass.map((item, index) => {
                                            return (
                                                <>
                                                    <Row className=''>
                                                        <Col md={3}>

                                                            <div className='fv-row mt-5'>
                                                                <label className='form-label fs-6 fw-bolder mb-3'>
                                                                    {`${item.name} `}
                                                                </label><br></br>
                                                                <label className='form-label fs-6 fw-light mb-3'>
                                                                    {` ${item.unit}  ${item.price}€`}
                                                                </label>
                                                            </div>
                                                            {renderHidden(`glassData[${index}].priceType`, item.type, verandaAccessoiresFormik)}
                                                            {renderHidden(`glassData[${index}].productType`, item.productType, verandaAccessoiresFormik)}
                                                            {renderHidden(`glassData[${index}].unit`, item.unit, verandaAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Width (cm)`, `glassData[${index}].width`, 'number', null, verandaAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Height (cm)`, `glassData[${index}].height`, 'number', null, verandaAccessoiresFormik)}
                                                        </Col>
                                                        <Col md={3}>
                                                            {renderElement(`Quantity`, `glassData[${index}].quantity`, 'number', null, verandaAccessoiresFormik)}
                                                        </Col>
                                                    </Row>
                                                </>

                                            )
                                        }) : <></>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={3} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-secondary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 2) && verandaAccessoiresFormik.setFieldValue('button', 0)}>{intl.formatMessage({ id: `GENERAL.CALCULATE` })}</button>
                                        </Col>
                                        <Col md={3} className='mt-1'>
                                            <div className='fv-row text-center  border border-primary rounded'>
                                                <label className='form-label fs-2 fw-bolder mt-2'>
                                                    {verandaAccessoiresFormik.getFieldProps('totalPrice_2').value + " €"}
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={6} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-primary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 2) && verandaAccessoiresFormik.setFieldValue('button', 1)}>{intl.formatMessage({ id: `CHECKOUT.FIELD.ADDCART` })}</button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>

                            <Card className='mt-5'>
                                <CardHeader className="d-flex bg-primary">
                                    <CardTitle>
                                        <h5 className="text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.OVERIGE.ONDERDELEN` })}</h5>
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    {
                                        data && data.verandaAccessoiresData && data.verandaAccessoiresData.overige ? data.verandaAccessoiresData.overige.map((item, index) => {

                                            var colors = [];
                                            if (item.productType == enumProductTypes.Polycarbonaat)
                                                colors = data.verandaAccessoiresData.polyColors;

                                            return (
                                                <Row className=''>
                                                    <Col md={3}>

                                                        <div className='fv-row mt-5'>
                                                            <label className='form-label fs-6 fw-bolder mb-3'>
                                                                {`${item.name} `}
                                                            </label><br></br>
                                                            <label className='form-label fs-6 fw-light mb-3'>
                                                                {` ${item.unit}  ${item.price}€`}
                                                            </label>
                                                        </div>

                                                        {renderHidden(`overigeData[${index}].priceType`, item.type, verandaAccessoiresFormik)}
                                                        {renderHidden(`overigeData[${index}].productType`, item.productType, verandaAccessoiresFormik)}
                                                        {renderHidden(`overigeData[${index}].unit`, item.unit, verandaAccessoiresFormik)}
                                                    </Col>
                                                    <Col md={9}>
                                                        <Row>
                                                            {
                                                                colors && colors.length > 0 ? <Col md={3}>{renderDropdown('Frame Color', `overigeData[${index}].materialColor`, colors, false, 'Select', verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            {
                                                                item.unit == perM2 ? <Col md={3}>{renderElement(`Width (cm)`, `overigeData[${index}].width`, 'number', null, verandaAccessoiresFormik)}</Col> : <></>
                                                            }

                                                            {
                                                                item.unit == perM2 ? <Col md={3}>{renderElement(`Height (cm)`, `overigeData[${index}].height`, 'number', null, verandaAccessoiresFormik)}</Col> : <></>
                                                            }
                                                            <Col md={3}>
                                                                {renderElement(`Quantity`, `overigeData[${index}].quantity`, 'number', null, verandaAccessoiresFormik)}
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                </Row>
                                            )
                                        }) : <></>
                                    }
                                </CardBody>
                                <CardFooter>
                                    <Row>
                                        <Col md={3} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-secondary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 3) && verandaAccessoiresFormik.setFieldValue('button', 0)}>{intl.formatMessage({ id: `GENERAL.CALCULATE` })}</button>
                                        </Col>
                                        <Col md={3} className='mt-1'>
                                            <div className='fv-row text-center  border border-primary rounded'>
                                                <label className='form-label fs-2 fw-bolder mt-2'>
                                                    {verandaAccessoiresFormik.getFieldProps('totalPrice_3').value + " €"}
                                                </label>
                                            </div>
                                        </Col>
                                        <Col md={6} className='mt-1'>
                                            <button style={{ width: "100%" }} type='submit' className='btn btn-primary fs-4' onClick={() => verandaAccessoiresFormik.setFieldValue('type', 3) && verandaAccessoiresFormik.setFieldValue('button', 1)}>{intl.formatMessage({ id: `CHECKOUT.FIELD.ADDCART` })}</button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </CardBody>

                    </form>
                </div>
            </Card>

        )
    }

    const renderAccessoires = () => {

        return (
            <Card>
                <CardHeader className="py-3 d-flex bg-primary">
                    <CardTitle>
                        <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` })}</h4>
                    </CardTitle>
                </CardHeader>
                <div className="fs-6 collapse show">
                    <form
                        onSubmit={accessoiresFormik.handleSubmit}
                        id='kt_checkout'
                        className='form'
                        noValidate
                    >
                        <CardBody>

                            {
                                data && data.accessoiresData && data.accessoiresData.items ? data.accessoiresData.items.map((item, index) => {
                                    return (
                                        <Row className=''>
                                            <Col md={2}>
                                                <div className='fv-row mt-5'>
                                                    <label className='form-label fs-6 fw-bolder mb-3'>
                                                        {`${item.name} `}
                                                    </label><br></br>
                                                    <label className='form-label fs-6 fw-light mb-3'>
                                                        {` ${item.unit} `}
                                                    </label>
                                                </div>
                                                {renderHidden(`itemsData[${index}].priceType`, item.type, accessoiresFormik)}
                                                {renderHidden(`itemsData[${index}].productType`, item.productType, accessoiresFormik)}
                                            </Col>

                                            <Col md={2}>{renderDropdown('Frame Color', `itemsData[${index}].frameColor`, data && data.accessoiresData && data.accessoiresData.frameColors, false, 'Select', accessoiresFormik)}</Col>
                                            <Col md={2}>{renderDropdown('Material Color', `itemsData[${index}].materialColor`, data && data.accessoiresData && data.accessoiresData.materialColors, false, 'Select', accessoiresFormik)}</Col>
                                            <Col md={2}>{renderElement(`Width (cm)`, `itemsData[${index}].width`, 'number', null, accessoiresFormik)}</Col>
                                            <Col md={2}>{renderElement(`Height (cm)`, `itemsData[${index}].height`, 'number', null, accessoiresFormik)}</Col>
                                            <Col md={2}>{renderElement(`Quantity`, `itemsData[${index}].quantity`, 'number', null, accessoiresFormik)}</Col>
                                        </Row>
                                    )
                                }) : <></>
                            }

                            {renderFooterButtons(accessoiresFormik)}

                        </CardBody>

                    </form>
                </div>
            </Card>

        )
    }

    const renderBookmarkItem = (id, name) => {
        const textColor = bookmark == id ? "text-primary" : "";
        return (
            <>
                <a href={`#${id}`} className={`btn btn-link btn-color-gray-500 btn-active-color-primary btn-bookmark-item text-left ${textColor}`} onClick={() => {
                    setBookmark(id);
                    setBookmarkOpen(!bookmarkOpen);
                }}>{name}</a>
            </>
        )
    }

    const renderBookmark = () => {
        return (
            <div className='accordion  fixed-div' id='kt_accordion_1'>
                <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                        <button
                            className={`accordion-button fs-4 fw-bold ${bookmarkOpen ? '' : 'collapsed'} bg-secondary text-white`}
                            data-bs-toggle={`collapse`}
                            data-bs-target='#kt_accordion_1_body_1'
                            aria-controls='kt_accordion_1_body_1'
                            aria-expanded={`false`}
                            onClick={() => setBookmarkOpen(!bookmarkOpen)}
                        >
                            Bookmarks
                        </button>
                    </h2>
                    <div
                        id='kt_accordion_1_body_1'
                        className={`accordion-collapse collapse ${bookmarkOpen ? 'show' : ''}`}
                        aria-labelledby='kt_accordion_1_header_1'
                        data-bs-parent='#kt_accordion_1'
                    >
                        <div className='accordion-body'>
                            {renderBookmarkItem("row_veranda", intl.formatMessage({ id: `CHECKOUT.FIELD.VERANDA` }))}
                            {renderBookmarkItem("row_glazenSchuifwand", intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SCHUIFWAND` }))}
                            {renderBookmarkItem("row_glazenSchuifwandAccessoires", intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SCHUIFWAND` }) + " - " + intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` }))}
                            {renderBookmarkItem("row_spie", intl.formatMessage({ id: `CHECKOUT.FIELD.GLAZEN.SPIE` }))}
                            {renderBookmarkItem("row_geheel", intl.formatMessage({ id: `CHECKOUT.FIELD.GEHEEL.POLYCARBONAAT` }))}
                            {renderBookmarkItem("row_aluminiumRabat", intl.formatMessage({ id: `CHECKOUT.FIELD.ALUMINIUM.RABAT` }))}
                            {renderBookmarkItem("row_verandaAccessoires", intl.formatMessage({ id: `CHECKOUT.FIELD.VERANDA` }) + " - " + intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` }))}
                            {renderBookmarkItem("row_accessoires", intl.formatMessage({ id: `CHECKOUT.FIELD.ACCESSOIRES` }))}
                            {renderBookmarkItem("row_zonwering", intl.formatMessage({ id: `CHECKOUT.FIELD.ZONWERING` }))}
                            {renderBookmarkItem("row_rsScreen", intl.formatMessage({ id: `CHECKOUT.FIELD.RS.SCREEN` }))}
                            {renderBookmarkItem("row_lamellendak", intl.formatMessage({ id: `CHECKOUT.FIELD.LAMELLENDAK` }))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'CHECKOUT.BREADCRUMB.DESCRIPTION' })}</PageTitle>

            {renderBookmark()}

            <Row id="row_veranda" className='target-section'></Row>
            {renderVeranda()}

            <Row id="row_glazenSchuifwand" className='target-section'></Row>
            {renderGlazenSchuifwand()}

            <Row id="row_glazenSchuifwandAccessoires" className='target-section'></Row>
            {renderGlazenSchuifwandAccessoires()}

            <Row id="row_spie" className='target-section'></Row>
            {renderSpie()}

            <Row id="row_geheel" className='target-section'></Row>
            {renderGeheel()}

            <Row id="row_aluminiumRabat" className='target-section'></Row>
            {renderaluminiumRabat()}

            <Row id="row_verandaAccessoires" className='target-section'></Row>
            {renderVerandaAccessoires()}

            <Row id="row_accessoires" className='target-section'></Row>
            {renderAccessoires()}

            <Row id="row_zonwering" className='target-section'></Row>
            {renderZonwering()}

            <Row id="row_rsScreen" className='target-section'></Row>
            {renderRsScreen()}

            <Row id="row_lamellendak" className='target-section'></Row>
            {renderlamellendak()}

            <Row>
                <Col md={12}>
                    <button style={{ width: "100%" }} onClick={() => navigate("/checkout-management/checkout-summary")} type='submit' className='btn btn-primary mt-10'><span className='fs-2'>{intl.formatMessage({ id: `GENERAL.NEXT` })} </span> <KTIcon iconName='arrow-right fs-2x mb-1'></KTIcon> </button>
                </Col>
            </Row>

            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        data: state.Checkout.data,
        cart: state.Checkout.cart,
        loading: state.Checkout.loading,
        success: state.Checkout.success
    };
};

const mapActionsToProps = {
    clearState, getCart, getForm, postCart, postCalculateCart, uploadFile
}

export default connect(mapStateToProps, mapActionsToProps)(Checkout)