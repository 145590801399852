import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { toast } from 'react-toastify';
import { Loader } from '../../../_metronic/helpers';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    CardTitle,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane 
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    clearState,
    getNotificationTriggers,
    getNotificationTemplates,
    getLanguageCodes,
    patchNotificationTemplates,
    postNotificationTemplates
} from './settingActions';

// Enum-like objects for notification types
const NotificationType = {
    UserNotification: 2,
    EmailNotification: 1
};

// Form component remains the same...
const NotificationForm = ({ 
    template, 
    isEmail, 
    updateTemplate,
    intl 
}) => {
    const validationSchema = Yup.object().shape({
        emailSmtpId: Yup.number().nullable(),
        redirect: Yup.string().nullable(),
        subject: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        body: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: template.id,
            emailSmtpId: template.emailSmtpId,
            redirect: template.redirect || '',
            subject: template.subject || '',
            body: template.body || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            updateTemplate(values.id, {
                emailSmtpId: {
                    set: true,
                    value: values.emailSmtpId
                },
                redirect: {
                    set: true,
                    value: values.redirect
                },
                subject: {
                    set: true,
                    value: values.subject
                },
                body: {
                    set: true,
                    value: values.body
                }
            });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit} className='form' noValidate>
            {isEmail && (
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder'>
                        {intl.formatMessage({ id: 'SETTING.SMTP_ID' })}
                    </label>
                    <input
                        type='number'
                        className='form-control'
                        {...formik.getFieldProps('emailSmtpId')}
                    />
                </div>
            )}
            
            {!isEmail && (
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder'>
                        {intl.formatMessage({ id: 'SETTING.REDIRECT' })}
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('redirect')}
                    />
                </div>
            )}

            <div className='fv-row mb-7'>
                <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({ id: 'SETTING.SUBJECT' })}
                </label>
                <input
                    type='text'
                    className='form-control'
                    {...formik.getFieldProps('subject')}
                />
            </div>

            <div className='fv-row mb-7'>
                <label className='form-label fs-6 fw-bolder'>
                    {intl.formatMessage({ id: 'SETTING.BODY' })}
                </label>
                <textarea
                    className='form-control'
                    rows={6}
                    {...formik.getFieldProps('body')}
                />
            </div>

            <div className='d-flex justify-content-end'>
                <button
                    type='submit'
                    className='btn btn-primary'
                    disabled={!formik.dirty}
                >
                    {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                </button>
            </div>
        </form>
    );
};

const NotificationTemplateSettings = (props) => {
    const intl = useIntl();
    const [activeTriggerTab, setActiveTriggerTab] = useState('');
    const [activeLanguageTabs, setActiveLanguageTabs] = useState({});
    const [languageCodes, setLanguageCodes] = useState([]);
    const [triggers, setTriggers] = useState([]);

    // Redux state
    const {
        notificationTemplates,
        triggerTypes,
        loading,
        success,
        error
    } = props;

    // Redux actions
    const {
        clearState,
        getNotificationTriggers,
        getNotificationTemplates,
        getLanguageCodes,
        patchNotificationTemplates,
        postNotificationTemplates
    } = props;

    // Fetch initial data
    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const [fetchedTriggers, fetchedCodes] = await Promise.all([
                    props.getNotificationTriggers(),
                    props.getLanguageCodes()
                ]);

                if (fetchedTriggers && fetchedTriggers.length > 0) {
                    setTriggers(fetchedTriggers.sort((a, b) => a - b));
                    setActiveTriggerTab(fetchedTriggers[0].triggerType.toString());
                }
                
                if (fetchedCodes && fetchedCodes.length > 0) {
                    setLanguageCodes(fetchedCodes);
                }
            } catch (error) {
                toast.error(intl.formatMessage({ id: 'ERROR.FETCH_INITIAL_DATA' }));
            }
        };

        fetchInitialData();
        getNotificationTemplates();

        return () => {
            clearState();
        }
    }, []);

    const handleTemplateUpdate = (templateId, triggerType, notificationType, languageCode, values) => {
        const existingTemplate = notificationTemplates.find(t => 
            t.triggerType.toString() === triggerType &&
            t.notificationType === notificationType &&
            t.languageCode === languageCode
        );

        if (existingTemplate) {
            // Update existing template
            patchNotificationTemplates(templateId, values);
        } else {
            // Create new template
            const newTemplate = {
                triggerType: parseInt(triggerType),
                notificationType,
                languageCode,
                emailSmtpId: values.emailSmtpId.value,
                redirect: values.redirect.value,
                subject: values.subject.value,
                body: values.body.value
            };
            postNotificationTemplates(newTemplate);
        }
    };

    // Success and error effects remain the same...
    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({ id: error }));
        }
    }, [error]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'SETTING.MANAGEMENT.TITLE' }),
            path: '/setting-management/notification-templates',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        },
    ];

    const getTemplateData = (triggerType, notificationType, languageCode) => {
        const initial = {
            triggerType: parseInt(triggerType),
            notificationType,
            languageCode,
            emailSmtpId: null,
            redirect: '',
            subject: '',
            body: ''
        };
        if (notificationTemplates === undefined)
            return initial;

        return notificationTemplates.find(t => 
            t.triggerType.toString() === triggerType &&
            t.notificationType === notificationType &&
            t.languageCode === languageCode
        ) || initial;
    };

    const renderLanguageTabs = (triggerType, notificationType) => {
        const tabKey = `${triggerType}-${notificationType}`;
        const activeTab = activeLanguageTabs[tabKey] || (languageCodes[0] || '');

        return (
            <div>
                <Nav tabs>
                    {languageCodes.map((code) => (
                        <NavItem key={code}>
                            <NavLink
                                className={activeTab === code ? 'active' : ''}
                                onClick={() => setActiveLanguageTabs(prev => ({ 
                                    ...prev, 
                                    [tabKey]: code 
                                }))}
                                style={{ cursor: 'pointer' }}
                            >
                                {code.toUpperCase()}
                            </NavLink>
                        </NavItem>
                    ))}
                </Nav>

                <TabContent activeTab={activeTab} className="mt-4">
                    {languageCodes.map((code) => {
                        const template = getTemplateData(triggerType, notificationType, code);
                        return (
                            <TabPane key={code} tabId={code}>
                                <NotificationForm
                                    template={template}
                                    triggerType={triggerType}
                                    notificationType={notificationType}
                                    languageCode={code}
                                    isEmail={notificationType === NotificationType.EmailNotification}
                                    updateTemplate={(templateId, values) => 
                                        handleTemplateUpdate(
                                            templateId, 
                                            triggerType, 
                                            notificationType, 
                                            code, 
                                            values
                                        )
                                    }
                                    intl={intl}
                                />
                            </TabPane>
                        );
                    })}
                </TabContent>
            </div>
        );
    };

    // Rest of the component (render method) remains the same...
    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>
                {intl.formatMessage({ id: 'SETTING.NOTIFICATION_TEMPLATES' })}
            </PageTitle>

            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({ id: 'SETTING.NOTIFICATION_TEMPLATES' })}</h3>
                    </CardTitle>
                </CardHeader>
                <CardBody>
                    <Nav tabs>
                        {triggers.map((trigger) => (
                            <NavItem key={trigger.triggerType.toString()}>
                                <NavLink
                                    className={activeTriggerTab === trigger.triggerType.toString() ? 'active' : ''}
                                    onClick={() => setActiveTriggerTab(trigger.triggerType.toString())}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {trigger.triggerName}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>

                    <TabContent activeTab={activeTriggerTab}>
                        {triggers.map((trigger) => (
                            <TabPane key={trigger.triggerType.toString()} tabId={trigger.triggerType.toString()}>
                                <div className="row mt-5">
                                    <div className="col-md-6">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle>
                                                    <h4>{intl.formatMessage({ id: 'SETTING.EMAIL_TEMPLATE' })}</h4>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                {renderLanguageTabs(
                                                    trigger.triggerType.toString(),
                                                    NotificationType.EmailNotification
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className="col-md-6">
                                        <Card>
                                            <CardHeader>
                                                <CardTitle>
                                                    <h4>{intl.formatMessage({ id: 'SETTING.NOTIFICATION_TEMPLATE' })}</h4>
                                                </CardTitle>
                                            </CardHeader>
                                            <CardBody>
                                                {renderLanguageTabs(
                                                    trigger.triggerType.toString(),
                                                    NotificationType.UserNotification
                                                )}
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </TabPane>
                        ))}
                    </TabContent>
                </CardBody>
            </Card>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        notificationTemplates: state.Setting.notificationTemplates,
        triggerTypes: state.Setting.triggerTypes,
        loading: state.Setting.loading,
        success: state.Setting.success,
        error: state.Setting.error
    };
};

const mapActionsToProps = {
    clearState,
    getNotificationTriggers,
    getNotificationTemplates,
    getLanguageCodes,
    patchNotificationTemplates,
    postNotificationTemplates
};

export default connect(mapStateToProps, mapActionsToProps)(NotificationTemplateSettings);