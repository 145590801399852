// @flow
import types from './calendarEventConstants';
import { getAsync, patchAsync, postAsync, putAsync, uploadAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    })
}

export const getCalendarEvents = (filter) => async dispatch => {

    dispatch({
        type: types.GET_CALENDAR_EVENTS
    })

    var path = `/calendar-events?`;

    if (filter) {
        path += "keyword=" + filter.keyword + "&";
        path += "type=" + filter.type + "&";
    }

    const response = await getAsync(path);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CALENDAR_EVENTS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_CALENDAR_EVENTS_FAILED,
            payload: response,
        })
    }

    return response;
}

export const getCalendarEvent = (id) => async dispatch => {

    dispatch({
        type: types.GET_CALENDAR_EVENT
    })

    const response = await getAsync(`/calendar-events/${id}`);

    if (response.isSuccess) {
        dispatch({
            type: types.GET_CALENDAR_EVENT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.GET_CALENDAR_EVENT_FAILED,
            payload: response
        })
    }

    return response;
}

export const patchCalendarEvent = (id, request) => async dispatch => {

    dispatch({
        type: types.PATCH_CALENDAR_EVENT
    })

    const response = await patchAsync(`/calendar-events/${id}`, request, /*showError*/false);

    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_CALENDAR_EVENT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.PATCH_CALENDAR_EVENT_FAILED,
            payload: response
        })
    }

    return response;
}

export const postCalendarEvent = (calendarEvent) => async dispatch => {

    dispatch({
        type: types.POST_CALENDAR_EVENT
    })

    const response = await postAsync(`/calendar-events`, calendarEvent, /*showError*/false);

    if (response.isSuccess) {
        dispatch({
            type: types.POST_CALENDAR_EVENT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.POST_CALENDAR_EVENT_FAILED,
            payload: response
        })
    }

    return response;
}
