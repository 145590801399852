// discountActions.js
import types from './discountConstants';
import { deleteAsync, getAsync, patchAsync, postAsync } from '../../../_metronic/helpers/httpClient';

export const clearState = () => async dispatch => {
    dispatch({
        type: types.CLEAR_STATE
    });
};

export const getDiscounts = (filter) => async dispatch => {
    dispatch({
        type: types.GET_DISCOUNTS,
    });

    var path = `/discounts?`;

    if (filter) {
        path += "keyword=" + filter.keyword + "&";
        path += "isActive=" + filter.isActive;
    }

    const response = await getAsync(path);
    if (response.isSuccess) {
        dispatch({
            type: types.GET_DISCOUNTS_SUCCESS,
            payload: response,
        })
    } else {
        dispatch({
            type: types.GET_DISCOUNTS_FAILED,
            payload: response,
        })
    }
};

export const getDiscount = (id) => async dispatch => {
    dispatch({
        type: types.GET_DISCOUNT,
    });

    const response = await getAsync(`/discounts/${id}`);
    if (response.isSuccess) {
        dispatch({
            type: types.GET_DISCOUNT_SUCCESS,
            payload: response
        })
    } else {
        dispatch({
            type: types.GET_DISCOUNT_FAILED,
            payload: response
        })
    }
};

export const patchDiscount = (id, values) => async dispatch => {
    dispatch({
        type: types.PATCH_DISCOUNT,
    });

    const response = await patchAsync(`/discounts/${id}`, values);
    if (response.isSuccess) {
        dispatch({
            type: types.PATCH_DISCOUNT_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.PATCH_DISCOUNT_FAILED,
            payload: response
        });
    }
};

export const postDiscount = (values) => async dispatch => {
    dispatch({
        type: types.POST_DISCOUNT,
    });

    const response = await postAsync(`/discounts`, values);
    if (response.isSuccess) {
        dispatch(getDiscount(response.data.id));
        dispatch({
            type: types.POST_DISCOUNT_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.POST_DISCOUNT_FAILED,
            payload: response
        });
    }
};

export const patchDiscountActivationState = (id, activationState) => async dispatch => {
    dispatch({
        type: types.PATCH_DISCOUNT_ACTIVATION_STATE,
    });

    const response = await patchAsync(`/discounts/${id}`, {
        isActive: {
            set: true,
            value: activationState
        }
    });
    if (response.isSuccess) {
        dispatch(getDiscount(response.data.id));
        dispatch({
            type: types.PATCH_DISCOUNT_ACTIVATION_STATE_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.PATCH_DISCOUNT_ACTIVATION_STATE_FAILED,
            payload: response
        });
    }
};

export const deleteDiscount = (id) => async dispatch => {
    dispatch({
        type: types.DELETE_DISCOUNT,
    });

    const response = await deleteAsync(`/discounts/${id}`);
    if (response.isSuccess) {
        dispatch({
            type: types.DELETE_DISCOUNT_SUCCESS,
            payload: response
        });
    } else {
        dispatch({
            type: types.DELETE_DISCOUNT_FAILED,
            payload: response
        });
    }
};

export const getDiscountTypes = () => async dispatch => {
    dispatch({
        type: types.GET_DISCOUNT_TYPES,
    });

    const response = await getAsync(`/discounts/discount-types`);
    if (response.isSuccess) {
        dispatch({
            type: types.GET_DISCOUNT_TYPES_SUCCESS,
            payload: response
        });
        return response.data;
    } else {
        dispatch({
            type: types.GET_DISCOUNT_TYPES_FAILED,
            payload: response
        })
        throw new Error(response);
    }
};

export const getDiscountScopeTypes = () => async dispatch => {
    dispatch({
        type: types.GET_DISCOUNT_SCOPE_TYPES,
    });

    const response = await getAsync(`/discounts/scope-types`);
    if (response.isSuccess) {
        dispatch({
            type: types.GET_DISCOUNT_SCOPE_TYPES_SUCCESS,
            payload: response
        });
        return response.data;
    } else {
        dispatch({
            type: types.GET_DISCOUNT_SCOPE_TYPES_FAILED,
            payload: response
        })
        throw new Error(response);
    }
};

export const getScopeValues = (scopeType) => async dispatch => {
    dispatch({
        type: types.GET_DISCOUNT_SCOPE_VALUES,
    });

    const response = await getAsync(`/discounts/scope-values/${scopeType}`);
    if (response.isSuccess) {
        dispatch({
            type: types.GET_DISCOUNT_SCOPE_VALUES_SUCCESS,
            payload: response
        });
        return response.data;
    } else {
        dispatch({
            type: types.GET_DISCOUNT_SCOPE_VALUES_FAILED,
            payload: response
        })
        throw new Error(response);
    }
};