import React, { useEffect, useState, dispatch } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { PageTitle } from '../../../_metronic/layout/core';
import { toast } from 'react-toastify';
import {KTCard, KTCardBody, Loader, enumDiscountType} from '../../../_metronic/helpers'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import { useFormik } from 'formik';
import Swal from 'sweetalert2'
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';
import * as Yup from 'yup';
import { useParams, useNavigate } from 'react-router-dom';
import {
    getDiscount,
    postDiscount,
    patchDiscount,
    patchDiscountActivationState,
    deleteDiscount,
    getDiscountTypes,
    getDiscountScopeTypes,
    getScopeValues
} from './discountActions';

const DiscountScopesTable = ({ scopes, scopeTypeOptions, onAddScope, onDeleteScope, getScopeValues }) => {
    const intl = useIntl();
    const [newScope, setNewScope] = useState({
        scopeType: null,
        scopeValue: '',
        scopeLabel: ''
    });
    const [scopeValueOptions, setScopeValueOptions] = useState([]);
    const [selectedScopeType, setSelectedScopeType] = useState();
    const [selectedScopeValue, setSelectedScopeValue] = useState();

    useEffect(() => {
        const fetchScopeValues = async (scopeType) => {
            try {
                const [fetchedScopeValues] = await Promise.all([
                    getScopeValues(scopeType)
                ]);

                if (fetchedScopeValues) {
                    setScopeValueOptions(
                        fetchedScopeValues.map(v => ({ value: v.id, label: v.name }))
                    );
                    setNewScope({ ...newScope, scopeType: scopeType });
                }
            } catch (error) {
                toast.error(intl.formatMessage({ id: 'ERROR.FETCH_INITIAL_DATA' }));
            }
        };
        if (selectedScopeType)
            fetchScopeValues(selectedScopeType);

    }, [selectedScopeType])
    
    const handleScopeTypeChange = async (selectedOption) => {
        const scopeType = selectedOption.target.value;
        setSelectedScopeType(scopeType);
    };

    const handleScopeValueChange = async (selectedOption) => {
        const scopeValue = selectedOption.target.value;
        setSelectedScopeValue(scopeValue);
        setNewScope({ ...newScope, scopeValue: scopeValue });
    };

    const handleAddScope = () => {
        if (newScope.scopeType && newScope.scopeValue) {
            onAddScope({
                scopeType: Number(newScope.scopeType),
                scopeValue: newScope.scopeValue,
                scopeLabel: scopeValueOptions.find(v => v.value === Number(newScope.scopeValue))?.label
            });
            setSelectedScopeValue('');
            setNewScope({ ...newScope, scopeValue: '', scopeLabel: '' });
        }
    };

    // Filter options to exclude already selected scope values
    const filteredScopeValueOptions = scopeValueOptions.filter(option => 
        !scopes.some(scope => 
            scope.scopeType === Number(selectedScopeType) &&
            Number(scope.scopeValue) === option.value)
    );

    return (
        <KTCard>
            <div className="card-header border-0 pt-6">
                <div className="card-title">
                    <h3>{intl.formatMessage({ id: 'DISCOUNT.SCOPES' })}</h3>
                </div>
                <div className="card-toolbar">
                    <div className="d-flex justify-content-end">
                        <div className="d-flex align-items-center">
                            <select
                                className='form-control form-select me-2'
                                value={selectedScopeType}
                                onChange={handleScopeTypeChange}
                            >
                                <option value="">
                                    {intl.formatMessage({id: 'DISCOUNT.SCOPE_TYPE' })}
                                </option>
                                {scopeTypeOptions && scopeTypeOptions.map((option) => (
                                    <option key={`option_${option.value}`} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <select
                                className='form-control form-select me-2'
                                value={selectedScopeValue}
                                onChange={handleScopeValueChange}
                                disabled={!newScope.scopeType}
                            >
                                <option value="">
                                    {intl.formatMessage({id: 'DISCOUNT.SCOPE_VALUE' })}
                                </option>
                                {filteredScopeValueOptions.map((option) => (
                                    <option key={`option_${option.value}`} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                            <button 
                                type='button'
                                className="btn btn-primary"
                                onClick={handleAddScope}
                                disabled={!newScope.scopeType || !newScope.scopeValue}
                            >
                                {intl.formatMessage({ id: 'GENERAL.ADD' })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <KTCardBody className='table-responsive'>
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                    <thead>
                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th>{intl.formatMessage({ id: 'DISCOUNT.SCOPE_TYPE' })}</th>
                            <th>{intl.formatMessage({ id: 'DISCOUNT.SCOPE_VALUE' })}</th>
                            <th>{intl.formatMessage({ id: 'GENERAL.ACTIONS' })}</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                        {scopes?.map((scope, index) => (
                            <tr key={index}>
                                <td>
                                    {scopeTypeOptions.find(
                                        option => option.value === scope.scopeType
                                    )?.label}
                                </td>
                                <td>{scope.scopeLabel}</td>
                                <td>
                                    <button 
                                        type='button'
                                        className="btn btn-danger btn-sm"
                                        onClick={() => onDeleteScope(index)}
                                    >
                                        {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </KTCardBody>
        </KTCard>
    );
};

const DiscountUsagesTable = ({ usages }) => {
    const intl = useIntl();
    
    return (
        <Card className="mt-5">
            <CardHeader>
                <CardTitle>{intl.formatMessage({ id: 'DISCOUNT.USAGES' })}</CardTitle>
            </CardHeader>
            <CardBody>
                <table className="table">
                    <thead>
                        <tr>
                            <th>{intl.formatMessage({ id: 'DISCOUNT.USER_ID' })}</th>
                            <th>{intl.formatMessage({ id: 'DISCOUNT.ORDER_ID' })}</th>
                            <th>{intl.formatMessage({ id: 'DISCOUNT.USAGE_DATE' })}</th>
                            <th>{intl.formatMessage({ id: 'DISCOUNT.AMOUNT_USED' })}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {usages?.map((usage, index) => (
                            <tr key={index}>
                                <td>{usage.userId}</td>
                                <td>{usage.orderId}</td>
                                <td>{new Date(usage.usageDate).toLocaleString()}</td>
                                <td>{usage.amountUsed}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </CardBody>
        </Card>
    );
};

const DiscountForm = ({ 
    discount, 
    discountTypes,
    discountScopeTypes,
    postDiscount,
    patchDiscount,
    patchDiscountActivationState,
    deleteDiscount,
    getScopeValues,
    intl 
}) => {
    const [scopes, setScopes] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewDiscount = id === '0';

    useEffect(() => {
        setScopes(discount?.scopes || []);
    }, [discount]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        type: Yup.number().required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        numberValue: Yup.lazy(() => {
            const currentType = Number(formik.values.type);
            switch (currentType) {
              case enumDiscountType.PercentageBased:
                return Yup.number()
                  .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
                  .min(0, intl.formatMessage({ id: 'VALIDATION.PERCENTAGE_MIN' }))
                  .max(100, intl.formatMessage({ id: 'VALIDATION.PERCENTAGE_MAX' }));
              default:
                return Yup.number()
                  .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
                  .positive(intl.formatMessage({ id: 'VALIDATION.POSITIVE_NUMBER' }));
            }
        }),
        discountCode: Yup.lazy(() => {
            const currentType = Number(formik.values.type);
            switch (currentType) {
              case enumDiscountType.DiscountCode:
                return Yup.string()
                    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' }))
                    .max(50, intl.formatMessage({ id: 'VALIDATION.CODE_MAX_LENGTH' }));
              default:
                return Yup.string().notRequired();
            }
        }),
        startDate: Yup.date().required(intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })),
        endDate: Yup.date().nullable().min(
            Yup.ref('startDate'), 
            intl.formatMessage({ id: 'VALIDATION.END_DATE_AFTER_START' })
        ),
    });

    const handleAddScope = (newScope) => {
        const updatedScopes = [...scopes, newScope];
        setScopes(updatedScopes);
        formik.setFieldValue('scopes', updatedScopes, true);
    };

    const handleDeleteScope = (index) => {
        const updatedScopes = scopes.filter((_, i) => i !== index);
        setScopes(updatedScopes);
        formik.setFieldValue('scopes', updatedScopes, true);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: discount?.id || '',
            name: discount?.name || '',
            type: Number(discount?.type) || '',
            numberValue: discount?.value || '',
            discountCode: discount?.discountCode || '',
            startDate: discount?.startDate ? new Date(discount.startDate).toISOString().split('T')[0] : '',
            endDate: discount?.endDate ? new Date(discount.endDate).toISOString().split('T')[0] : '',
            maximumUsages: discount?.maximumUsages || '',
            perUserLimit: discount?.perUserLimit || ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const discountData = {
                ...values,
                startDate: new Date(values.startDate),
                endDate: values.endDate ? new Date(values.endDate) : null,
                type: Number(values.type),
                value: values.numberValue,
                discountCode: values.discountCode,
                maximumUsages: values.maximumUsages ? Number(values.maximumUsages) : null,
                perUserLimit: values.perUserLimit ? Number(values.perUserLimit) : null,
                scopes: scopes.map(scope => ({
                    scopeType: scope.scopeType,
                    scopeValue: scope.scopeValue,
                    scopeLabel: scope.scopeLabel
                }))
            };

            if (isNewDiscount) {
                postDiscount(discountData);
            } else {
                patchDiscount(id, {
                    name: {
                        set: true,
                        value: values.name
                    },
                    type: {
                        set: true,
                        value: values.type
                    },
                    value: {
                        set: true,
                        value: values.numberValue
                    },
                    discountCode: {
                        set: true,
                        value: Number(values.type) === enumDiscountType.DiscountCode ? values.discountCode : ''
                    },
                    startDate: {
                        set: true,
                        value: values.startDate
                    },
                    endDate: {
                        set: true,
                        value: values.endDate === '' ? null : values.endDate
                    },
                    maximumUsages: {
                        set: true,
                        value: values.maximumUsages === '' ? null : values.maximumUsages
                    },
                    perUserLimit: {
                        set: true,
                        value: values.perUserLimit === '' ? null : values.perUserLimit
                    },
                    scopes: {
                        set: true,
                        value: discountData.scopes
                    }
                });
            }
        },
    });

    const handleDeleteDiscount = () => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'CONFIRM.DELETE_DISCOUNT' }),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then(async (result) => {
            if (result.isConfirmed) {
                deleteDiscount(id);
                navigate('/order-management/discounts');
            }
        });
    }

    return (
        <div>
            <form onSubmit={formik.handleSubmit} className='form' noValidate>
                <div className='fv-row mb-7'>
                    <label className='form-label fs-6 fw-bolder'>
                        {intl.formatMessage({ id: 'DISCOUNT.NAME' })}
                    </label>
                    <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('name')}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <div className='text-danger'>{formik.errors.name}</div>
                    )}
                </div>

                <div className='row'>
                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.TYPE' })}
                        </label>
                        <select
                            className='form-control form-select'
                            {...formik.getFieldProps('type')}
                        >
                            <option value="">
                                {intl.formatMessage({ id: 'GENERAL.SELECT' })}
                            </option>
                            {Object.entries(discountTypes || {}).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value}
                                </option>
                            ))}
                        </select>
                        {formik.touched.type && formik.errors.type && (
                            <div className='text-danger'>{formik.errors.type}</div>
                        )}
                    </div>

                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.VALUE' })}
                        </label>
                        <input
                            type='number'
                            className='form-control'
                            {...formik.getFieldProps('numberValue')}
                        />
                        {(formik.touched.numberValue && formik.errors.numberValue) && (
                            <div className='text-danger'>{formik.errors.numberValue}</div>
                        )}
                    </div>
                </div>

                { Number(formik.values.type) === enumDiscountType.DiscountCode && (
                    <div className='row'>
                        <div className='fv-row mb-7'>
                            <label className='form-label fs-6 fw-bolder'>
                                {intl.formatMessage({ id: 'DISCOUNT.DISCOUNT_CODE' })}
                            </label>
                            <input
                                type='text'
                                className='form-control'
                                {...formik.getFieldProps('discountCode')}
                            />
                            {formik.touched.discountCode && formik.errors.discountCode && (
                                <div className='text-danger'>{formik.errors.discountCode}</div>
                            )}
                        </div>
                    </div>
                )}

                <div className='row'>
                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.START_DATE' })}
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            {...formik.getFieldProps('startDate')}
                        />
                        {formik.touched.startDate && formik.errors.startDate && (
                            <div className='text-danger'>{formik.errors.startDate}</div>
                        )}
                    </div>

                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.END_DATE' })}
                        </label>
                        <input
                            type='date'
                            className='form-control'
                            {...formik.getFieldProps('endDate')}
                        />
                        {formik.touched.endDate && formik.errors.endDate && (
                            <div className='text-danger'>{formik.errors.endDate}</div>
                        )}
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.MAXIMUM_USAGES' })}
                        </label>
                        <input
                            type='number'
                            className='form-control'
                            {...formik.getFieldProps('maximumUsages')}
                        />
                    </div>

                    <div className='col-md-6 fv-row mb-7'>
                        <label className='form-label fs-6 fw-bolder'>
                            {intl.formatMessage({ id: 'DISCOUNT.PER_USER_LIMIT' })}
                        </label>
                        <input
                            type='number'
                            className='form-control'
                            {...formik.getFieldProps('perUserLimit')}
                        />
                    </div>
                </div>

                {!isNewDiscount && (
                    <>
                        <DiscountScopesTable 
                            scopes={scopes}
                            scopeTypeOptions={discountScopeTypes}
                            onAddScope={handleAddScope}
                            onDeleteScope={handleDeleteScope}
                            getScopeValues={getScopeValues}
                        />
                        {/* <DiscountUsagesTable 
                            usages={discount?.usages || []} 
                        /> */}
                    </>
                )}

                <div className='d-flex justify-content-end mt-5'>
                    {!isNewDiscount && (
                        <button
                            type='button'
                            className='btn btn-danger me-2'
                            onClick={handleDeleteDiscount}
                        >
                            {intl.formatMessage({ id: 'GENERAL.DELETE' })}
                        </button>
                    )}
                    <button
                        type='submit'
                        className='btn btn-primary'
                        disabled={!formik.dirty}
                    >
                        {intl.formatMessage({ id: 'GENERAL.SAVE' })}
                    </button>
                </div>
            </form>
        </div>
    );
};

const DiscountCreateEdit = (props) => {
    const intl = useIntl();
    const navigate = useNavigate();
    const { id } = useParams();
    const isNewDiscount = id === '0';

    // Redux state and actions
    const {
        discount,
        discountTypes,
        discountScopeTypes,
        loading,
        success,
        error,
        getDiscount,
        postDiscount,
        patchDiscount,
        patchDiscountActivationState,
        deleteDiscount,
        getDiscountTypes,
        getDiscountScopeTypes,
        getScopeValues
    } = props;

    // Fetch initial data
    useEffect(() => {
        getDiscountTypes();
        getDiscountScopeTypes();
        if (!isNewDiscount) {
            getDiscount(id);
        }
    }, [id]);

    useEffect(() => {
        if (id === '0' && discount && discount.id > 0) {
            navigate(`/order-management/discounts/${discount.id}`);
        }
    }, [discount]);

    // Success and error effects
    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({ id: error }));
        }
    }, [error]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'DISCOUNTS.BREADCRUMB.TITLE' }),
            path: '/order-management/discounts',
            isSeparator: false,
            isActive: false,
        },
        {
            title: isNewDiscount 
                ? intl.formatMessage({ id: 'DISCOUNT.CREATE' }) 
                : intl.formatMessage({ id: 'DISCOUNT.EDIT' }),
            path: '',
            isSeparator: true,
            isActive: true,
        },
    ];

    const handleChangeActivationState = () => {
        if (!isNewDiscount) {
            Swal.fire({
                title: "",
                text: intl.formatMessage({ id: 'CONFIRM.CHANGE_DISCOUNT_ACTIVATION' }),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
            }).then(async (result) => {
                if (result.isConfirmed) {
                    patchDiscountActivationState(id, discount?.isActive ? false : true);
                }
            });
        }
    };

    const getActivationState = (activationState) => {
        if (activationState) {
            return <span className="badge badge-light-success">{intl.formatMessage({ id: 'DISCOUNTS.FIELD.ACTIVE' }) }</span>
        }

        return  <span className="badge badge-light-danger">{intl.formatMessage({ id: 'DISCOUNTS.FIELD.INACTIVE' }) }</span>;

        // switch (status) {
        //     case 1:
        //         return <span className="badge badge-light-warning">{name}</span>
        //     case 2:
        //         return <span className="badge badge-light-success">{name}</span>
        //     case 3:
        //         return <span className="badge badge-light-danger">{name}</span>
        //     case 4:
        //         return <span className="badge badge-light-dark">{name}</span>
        //     default:
        //         return <span className="badge badge-light-primary">{name}</span>
        // }
    }

    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>
                {isNewDiscount 
                    ? intl.formatMessage({ id: 'DISCOUNT.CREATE' }) 
                    : intl.formatMessage({ id: 'DISCOUNT.EDIT' })}
            </PageTitle>

            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle>
                    <h3>
                        {isNewDiscount
                        ? intl.formatMessage({ id: 'DISCOUNT.CREATE' })
                        : intl.formatMessage({ id: 'DISCOUNT.EDIT' })} {getActivationState(discount?.isActive)}
                    </h3>
                    </CardTitle>
                    {!isNewDiscount && (
                    <button
                        type='button'
                        className={discount?.isActive === true ? 'btn btn-danger m-2' : 'btn btn-success m-2'} 
                        onClick={handleChangeActivationState}
                    >
                        {intl.formatMessage({ id: `${discount?.isActive ? 'DISCOUNTS.BUTTON.DEACTIVATE' : 'DISCOUNTS.BUTTON.ACTIVATE'}` })}
                    </button>
                    )}
                </CardHeader>
                <CardBody>
                    <DiscountForm
                        discount={discount}
                        discountTypes={discountTypes}
                        discountScopeTypes={discountScopeTypes}
                        postDiscount={postDiscount}
                        patchDiscount={patchDiscount}
                        patchDiscountActivationState={patchDiscountActivationState}
                        deleteDiscount={deleteDiscount}
                        getScopeValues={getScopeValues}
                        intl={intl}
                    />
                </CardBody>
            </Card>
            {loading && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        discount: state.Discount.discount,
        discountTypes: state.Discount.discountTypes,
        discountScopeTypes: state.Discount.discountScopeTypes,
        loading: state.Discount.loading,
        success: state.Discount.success,
        error: state.Discount.error
    };
};

const mapActionsToProps = {
    getDiscount,
    postDiscount,
    patchDiscount,
    patchDiscountActivationState,
    deleteDiscount,
    getDiscountTypes,
    getDiscountScopeTypes,
    getScopeValues
};

export default connect(mapStateToProps, mapActionsToProps)(DiscountCreateEdit);