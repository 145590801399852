import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { register, addressVerify } from '../core/_requests'
import { Link, useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl'
import { Row, Col } from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";
import IBAN from 'iban'
import { getAsync } from '../../../../_metronic/helpers/httpClient'

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY


const initialValues = {
  company: '',
  street: '',
  building: '',
  zipCode: '',
  cityId: '',
  countryId: '',
  iban: '',
  kvkNumber: '',
  btwNumber: '',
  contactEmail: '',
  contactPhoneCode: '',
  contactPhone: '',

  name: '',
  surname: '',
  title: '',
  //phoneCode: '',
  //phone: '',
  email: '',
  acceptTerms: false,
  captcha: ''
}

export function Registration() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const intl = useIntl();
  let captcha;

  const [countryList, setCountryList] = useState<any>([]);
  const [cityList, setCityList] = useState<any>([]);
  const [phoneCodeList, setPhoneCodeList] = useState<any>([]);
  const [countryId, setCountryId] = useState<any>('');
  const [zipCode, setZipCode] = useState<any>('');

  const companyName = process.env.REACT_APP_COMPANY_NAME

  useEffect(() => {
    const requestCountryList = async () => {
      const response = await getAsync("/countries")
      if (response && response.data) {
        setCountryList(response.data)
      }
    }
    const requestCityList = async (countryId) => {
      const response = await getAsync(`/countries/${countryId}/cities`)
      if (response && response.data) {
        setCityList(response.data)
      }
    }
    const requestPhoneCodeList = async () => {
      const response = await getAsync("/countries")
      if (response && response.data) {
        setPhoneCodeList(response.data)
      }
    }

    requestCountryList();
    requestPhoneCodeList();
  }, [])

  useEffect(() => {
    const requestCityList = async (countryId) => {
      const response = await getAsync(`/countries/${countryId}/cities`)
      if (response && response.data) {
        setCityList(response.data)
      }
    }
    if (countryId > 0)
      requestCityList(countryId);
  }, [countryId])





  const registrationSchema = Yup.object().shape({
    //contact
    company: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    street: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    building: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    zipCode: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    cityId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    countryId: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    iban: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
      .test(
        "iban",
        intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }),
        (value) => IBAN.isValid(value)
      ),
    kvkNumber: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
      .matches(RegExp("^[0-9]{8}$"), { message: intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }) }),
    btwNumber: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" }))
      .matches(RegExp("^[A-Za-z]{2}[0-9]{9}[A-Za-z][0-9]{2}$"), { message: intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }) }),
    contactEmail: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }))
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    contactPhone: Yup.string()
      .matches(RegExp("^[0-9]{10}$"), { message: intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }) })
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    contactPhoneCode: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    //contact
    //user
    name: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    surname: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    title: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    // phone: Yup.string()
    //   .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    // phoneCode: Yup.string()
    //   .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "VALIDATION.WRONG_FORMAT" }))
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
    //user
    acceptTerms: Yup.bool().required(intl.formatMessage({ id: "VALIDATION.TERMS_CONDITIONS" })),
    captcha: Yup.string()
      .required(intl.formatMessage({ id: "VALIDATION.REQUIRED_FIELD" })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const { data: auth } = await register({ ...values })

        setSubmitting(false)
        setLoading(false)

        toast.success(intl.formatMessage({ id: "AUTH.REGISTER.SUCCESS" }))
        captcha.reset();

        setTimeout(function () {
          navigate("/auth/login");
        }, 1000)

      } catch (error) {

        if (error instanceof AxiosError) {
          var message = `${error && error.response && error.response.data && error.response.data.message ? error.response.data.message : intl.formatMessage({ id: 'SYSTEM.ERROR' })}`;
          setStatus(message)
          toast.error(message)
        }
        else {
          setStatus(intl.formatMessage({ id: "AUTH.REGISTER.FAILED" }))
        }

        setSubmitting(false)
        setLoading(false)
        captcha.reset();
      }
    },
  })

  const onZipCodeChange = (e) => {
    //setZipCode(formik.values['zipCode']);
  }

  useEffect(() => {
    if (zipCode != undefined && zipCode != '' && countryId > 0) {
      const timeOutId = setTimeout(() => addressVerify(countryId, zipCode), 400);
      return () => clearTimeout(timeOutId);
    }
  }, [zipCode]);

  const setCaptchaRef = (ref) => {
    if (ref) {
      return captcha = ref;
    }
  };


  function onChange(value) {
    formik.setFieldValue('captcha', value);
  }

  function onCountryChange(value) {
    formik.setFieldValue('countryId', value);
    //formik.setFieldValue('zipCode', initialValues.zipCode);
    setCountryId(value);
  }

  const renderElement = (label, property, type, inputProps = {}) => {
    return (

      <div className='fv-row mb-2'>
        <label className='form-label fw-bolder text-dark fs-6'>{label}</label>
        <input
          placeholder={label}
          type={type}
          autoComplete='off'
          {...inputProps}
          {...formik.getFieldProps(property)}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched[property] && formik.errors[property] },
            {
              'is-valid': formik.touched[property] && !formik.errors[property],
            }
          )}
        />
        {formik.touched[property] && formik.errors[property] && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors[property]}</span>
            </div>
          </div>
        )}
      </div>

    )
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: "AUTH.REGISTER.TITLE" })}</h1>
        {/* end::Title */}

        <div className='text-gray-500 fw-semibold fs-6'>{companyName}</div>
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.COMPANY" }), "company", "text")}

      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.STREET" }), "street", "text")}
      <Row>
        <Col md={6}>
          {renderElement(intl.formatMessage({ id: "AUTH.INPUT.BUILDING" }), "building", "text")}
        </Col>
        <Col md={6}>
          <div className='fv-row mb-2'>
            <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "AUTH.INPUT.ZIPCODE" })}</label>
            <input
              placeholder={intl.formatMessage({ id: "AUTH.INPUT.ZIPCODE" })}
              type={'text'}
              autoComplete='off'
              onKeyUp={(e) => onZipCodeChange(e)}
              {...formik.getFieldProps('zipCode')}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': formik.touched['zipcode'] && formik.errors['zipCode'] },
                {
                  'is-valid': formik.touched['zipCode'] && !formik.errors['zipCode'],
                }
              )}
            />
            {formik.touched['zipCode'] && formik.errors['zipCode'] && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors['zipCode']}</span>
                </div>
              </div>
            )}
          </div>

        </Col>
      </Row>
      <Row>
        <Col>
          <div className='fv-row mb-2'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({ id: "AUTH.INPUT.COUNTRY" })}
            </label>

            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              {...formik.getFieldProps('countryId')}
              onChange={(e) => onCountryChange(e.target.value)}
            >
              <option value=''>{intl.formatMessage({ id: "AUTH.SELECT.COUNTRY" })}</option>
              {countryList.map((item, index) => {
                return (
                  <option key={`country_${item.id}`} value={item.id}>{item.name}</option>
                )
              })}
            </select>
            {formik.touched.countryId && formik.errors.countryId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.countryId}</div>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <div className='fv-row mb-2'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({ id: "AUTH.INPUT.CITY" })}
            </label>

            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              {...formik.getFieldProps('cityId')}
            >
              <option value=''>{intl.formatMessage({ id: "AUTH.SELECT.CITY" })}</option>
              {cityList && cityList.map((item, index) => {
                return (
                  <option key={`city_${item.id}`} value={item.id}>{item.name}</option>
                )
              })}
            </select>
            {formik.touched.cityId && formik.errors.cityId && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.cityId}</div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.KVK_NUMBER" }), "kvkNumber", "text", { maxLength: "8" })}
      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.IBAN" }), "iban", "text", { maxLength: "40" })}
      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.BTW_NUMBER" }), "btwNumber", "text", { maxLength: "14" })}
      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.EMAIL" }), "contactEmail", "email")}

      <Row>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({ id: "AUTH.INPUT.PHONE" })}
        </label>
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <select
              className='form-select form-select-solid form-select-lg fw-bold'
              {...formik.getFieldProps('contactPhoneCode')}
              style={{ borderRadius: "0.475rem 0px 0px 0.475rem" }}
            >
              <option value=''>{intl.formatMessage({ id: "AUTH.SELECT.PHONE.CODE" })}</option>
              {phoneCodeList.map((item, index) => {
                return (
                  <option key={`contactPhoneCode_${item.id}`} value={item.phoneCode}>{item.phoneCode}</option>
                )
              })}
            </select>
          </div>
          <input
            type="text"
            autoComplete='off'
            maxLength={10}
            {...formik.getFieldProps("contactPhone")}
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched["contactPhone"] && formik.errors["contactPhone"] },
              {
                'is-valid': formik.touched["contactPhone"] && !formik.errors["contactPhone"],
              }
            )}
          />
        </div>
        {((formik.touched.contactPhone && formik.errors.contactPhone) || (formik.touched.contactPhoneCode && formik.errors.contactPhoneCode)) && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.contactPhone || formik.errors.contactPhoneCode}</div>
          </div>
        )}
      </Row>

      <Row>
        <Col md={6}>
          {renderElement(intl.formatMessage({ id: "AUTH.INPUT.NAME" }), "name", "text")}
        </Col>
        <Col md={6}>
          {renderElement(intl.formatMessage({ id: "AUTH.INPUT.SURNAME" }), "surname", "text")}
        </Col>
      </Row>

      <Row>
        <Col>
          {renderElement(intl.formatMessage({ id: "AUTH.INPUT.COMPANY_TITLE" }), "title", "text")}
        </Col>
      </Row>


      {renderElement(intl.formatMessage({ id: "AUTH.INPUT.LOGIN_EMAIL" }), "email", "email")}


      {/* begin::Form group */}
      <div className='fv-row mb-8 mt-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            {intl.formatMessage({ id: "AUTH.REGISTER.TERMS_CONDITIONS" })}
            <a
              href='#'
              target='_blank'
              className='ms-1 link-primary'
            >
              {intl.formatMessage({ id: "AUTH.REGISTER.TERMS_CONDITIONS_LINK" })}
            </a>
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <ReCAPTCHA ref={(r) => setCaptchaRef(r)} className="mb-5" name="captcha" sitekey={siteKey} onChange={onChange} />

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'> {intl.formatMessage({ id: "AUTH.GENERAL.SUBMIT_BUTTON" })}  </span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: 'AUTH.LOGIN.SUBMIT.BUTTON.LOADING' })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({ id: "AUTH.GENERAL.CANCEL_BUTTON" })}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
