/* CALENDAR EVENTS */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'CALENDAR_EVENTS/CLEAR_STATE',

    GET_CALENDAR_EVENT: 'CALENDAR_EVENTS/GET_CALENDAR_EVENT',
    GET_CALENDAR_EVENT_SUCCESS: 'CALENDAR_EVENTS/GET_CALENDAR_EVENT_SUCCESS',
    GET_CALENDAR_EVENT_FAILED: 'CALENDAR_EVENTS/GET_CALENDAR_EVENT_FAILED',

    GET_CALENDAR_EVENTS: 'CALENDAR_EVENTS/GET_CALENDAR_EVENTS',
    GET_CALENDAR_EVENTS_SUCCESS: 'CALENDAR_EVENTS/GET_CALENDAR_EVENTS_SUCCESS',
    GET_CALENDAR_EVENTS_FAILED: 'CALENDAR_EVENTS/GET_CALENDAR_EVENTS_FAILED',

    PATCH_CALENDAR_EVENT: "CALENDAR_EVENTS/PATCH_CALENDAR_EVENT",
    PATCH_CALENDAR_EVENT_SUCCESS: "CALENDAR_EVENTS/PATCH_CALENDAR_EVENT_SUCCESS",
    PATCH_CALENDAR_EVENT_FAILED: "CALENDAR_EVENTS/PATCH_CALENDAR_EVENT_FAILED",

    POST_CALENDAR_EVENT: "CALENDAR_EVENTS/POST_CALENDAR_EVENT",
    POST_CALENDAR_EVENT_SUCCESS: "CALENDAR_EVENTS/POST_CALENDAR_EVENT_SUCCESS",
    POST_CALENDAR_EVENT_FAILED: "CALENDAR_EVENTS/POST_CALENDAR_EVENT_FAILED",
});

export default actionTypes;