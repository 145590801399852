import {useEffect, useState, useRef} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {connect} from 'react-redux';
import {useNavigate, useParams, Link, useLocation} from "react-router-dom";
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {Loader, enumCalendarEventType, enumPolicies, enumQuoteState} from '../../../_metronic/helpers'
import {Button, Row, Col, CardBody, Card, CardFooter} from 'reactstrap';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2'
import Moment from 'react-moment'
import moment from 'moment';
import {Modal} from 'react-bootstrap'
import {AvForm, AvField} from 'availity-reactstrap-validation';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import QuotePrint from './quotePrint';
import Flatpickr from 'react-flatpickr'
import "flatpickr/dist/themes/light.css";
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';

import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { getAsync } from '../../../_metronic/helpers/httpClient'

import LanguageSelector from '../../modules/auth/components/LanguageSelector';
import CalendarDatePickerComponent from '../../modules/CalendarDatePickerComponent';

import {clearState, getQuote, patchQuote, postQuote, putItems, postQuoteEmail, 
    postQuoteAccept, postQuoteReject, postQuoteInvoiced, postQuoteConvertToOrder, postQuoteAddToCart, getHistory} from './quoteActions';
import { getUserList } from '../user-management/userActions';
import { getCustomerList } from '../customer-management/customerActions';
import { getCalendarEvent, patchCalendarEvent, postCalendarEvent } from '../calendar-event/calendarEventActions';


const Quote = (props) => {
    //inputs from url
    const param = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const location = useLocation();
    MenuComponent.reinitialization();

    const fpquotedate = useRef(null);
    const fpexpirydate = useRef(null);

    const [items, setItems] = useState([])
    const [quoteDate, setQuoteDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [userId, setUserId] = useState(null);
    const [customerId, setCustomerId] = useState(null);
    const [showSendEmailModal, setSendEmailModal] = useState(false);
    const [comment, setComment] = useState('');
    const [customContact, setCustomContact] = useState('');
    const [languages, setLanguages] = useState([]);
    const [quoteUserLanguageCode, setQuoteUserLanguageCode] = useState('en');
    const [currentQuoteEmailLanguageCode, setCurrentQuoteEmailLanguageCode] = useState('en');
    
    const [showEventDatePickerModal, setShowEventDatePickerModal] = useState(false);
    const [preparationEvent, setPreparationEvent] = useState(undefined);

    let tabsModel = [];
    if (param.id == 0) {
        tabsModel = [
            { id: 2, name: intl.formatMessage({id: 'QUOTE.SUMMARY'}), active: " active", hash: "#quote_tab_2", show: 'show', policies: [enumPolicies.QuoteWrite, enumPolicies.QuoteEdit] }
        ]
    } else {
        tabsModel = [
            {id: 1, name: intl.formatMessage({id: 'QUOTE.QUOTE'}), active: " active", hash: "#quote_tab_1", show: 'show', policies: [enumPolicies.QuoteRead, enumPolicies.QuoteWrite, enumPolicies.QuoteEdit] },
            {id: 2, name: intl.formatMessage({id: 'QUOTE.SUMMARY'}), active: " ", hash: "#quote_tab_2", show: '', policies: [enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]},
            {id: 3, name: intl.formatMessage({id: 'QUOTE.HISTORY'}), active: " ", hash: "#quote_tab_3", show: '', policies: [enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]}
        ];
    }

    const [tabs, setTabs] = useState(tabsModel);

    //inputs from redux
    const {
        quote,
        historyList,
        success,
        error,
        loading,
        historyLoading,
        userList,
        customerList
    } = props;

    //actions
    const {
        clearState,
        getQuote,
        patchQuote,
        postQuote,
        putItems,
        postQuoteEmail,
        postQuoteAccept,
        postQuoteReject,
        postQuoteInvoiced,
        postQuoteConvertToOrder,
        postQuoteAddToCart,
        getHistory,
        getUserList,
        getCustomerList,
        getCalendarEvent,
        patchCalendarEvent,
        postCalendarEvent
    } = props;

    useEffect(() => {
        const requestLanguages = async () => {
            try {
            const response = await getAsync("/languages")
            if (response && response.data) {
                setLanguages(response.data.map((item) => {
                return ({
                    lang: item.code,
                    name: item.name,
                    flag: toAbsoluteUrl(item.icon),
                })
                }))
                MenuComponent.reinitialization();
            }
            } catch (error) {
                console.error(error)
            }
        }
        requestLanguages();

        if (param.id > 0) {

            getQuote(param.id)
            getHistory(param.id)
        }
        
        getUserList('', -1)
        getCustomerList()

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({id: success}));
        }
    }, [success])

    useEffect(() => {
        if (error) {
            toast.error(error.message);
        }
    }, [error])

    useEffect(() => {

        if (param.id == 0 && quote && quote.id > 0) {
            setTabs(
                [
                    {
                        id: 1,
                        name: intl.formatMessage({id: 'QUOTE.QUOTE'}),
                        active: " active",
                        hash: "#quote_tab_1",
                        show: 'show',
                        policies: [enumPolicies.QuoteRead, enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]
                    },
                    {id: 2, name: intl.formatMessage({id: 'QUOTE.SUMMARY'}), active: " ", hash: "#quote_tab_2", show: '', policies: [enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]},
                    {id: 3, name: intl.formatMessage({id: 'QUOTE.HISTORY'}), active: " ", hash: "#quote_tab_3", show: '', policies: [enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]}
                ]
            )
            navigate(`/order-management/quotes/${quote.id}?tab=2`);
        }

        if (quote) {
            setCustomContact(quote.customContact)
        }

        if (quote?.user) {
            setQuoteUserLanguageCode(quote.user.languageCode)
            setCurrentQuoteEmailLanguageCode(quote.user.languageCode)
        }
        else if (quote?.customer) {
            setQuoteUserLanguageCode(quote.customer.languageCode)
            setCurrentQuoteEmailLanguageCode(quote.customer.languageCode)
        }
        else {
            setQuoteUserLanguageCode('en')
            setCurrentQuoteEmailLanguageCode('en')
        }

        const getCalendarEventInternal = async (preparationEventId) => {
            try {
                const response = await getCalendarEvent(preparationEventId)
                if (response && response.isSuccess && response.data) {
                    setPreparationEvent(response.data)
                }
            } catch (error) {
                console.error(error)
            }
        }

        if (quote?.preparationEventId) {
            getCalendarEventInternal(quote.preparationEventId);
        }

        if (quote && quote.quoteItems) {
            setItems(quote.quoteItems);
            setExpiryDate(quote.expiryDate);
            setQuoteDate(quote.quoteDate);
            setCustomerId(quote.customer === undefined ? null : quote.customer.id);
            setUserId(quote.user === undefined ? null : quote.user.id);
        }
    }, [quote])

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tabParam = queryParams.get('tab'); // Query string parametresini alın

        if (tabParam) {
            const updatedTabs = tabsModel.map(tab => ({
                ...tab,
                active: tab.id === parseInt(tabParam) ? "active" : "",
                show: tab.id === parseInt(tabParam) ? "show" : ""
            }));
            setTabs(updatedTabs);
        }
    }, [location.search, param.id]);

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({id: 'QUOTES.BREADCRUMB.TITLE'}),
            path: '/order-management/quotes',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const validation = {
        required: {
            errorMessage: intl.formatMessage({id: 'VALIDATION.REQUIRED_FIELD'})
        }
    }

    const emailValidation = {
        required: {
            errorMessage: intl.formatMessage({id: 'VALIDATION.REQUIRED_FIELD'})
        },
        email: {
            errorMessage: intl.formatMessage({id: 'VALIDATION.EMAIL_INVALID'})
        }
    }

    const onPrintContent = () => {

        if (quote && quote.quoteItems && quote.quoteItems.length > 0) {
            const markup = renderToStaticMarkup(
                <QuotePrint quote={quote} intl={intl}/>
            );

            const newTab = window.open('', '_blank');
            newTab.document.write(markup);
            newTab.print();
        }
    }

    const onQuoteDateChange = ([date]) => {
        var x = moment(date).format('YYYY-MM-DD');
        setQuoteDate(x);
    }

    const onExpiryDateChange = ([date]) => {
        var x = moment(date).format('YYYY-MM-DD');
        setExpiryDate(x);
    }

    const handleQuoteValidSubmit = (event, values) => {
        // Convert relationshipNr to number
        values.relationshipNr = Number(values.relationshipNr);
        if (param.id > 0) {
            patchQuote(param.id, {
                quoteNr: {
                    set: true,
                    value: values.quoteNr
                },
                quoteDate: {
                    set: true,
                    value: quoteDate
                },
                expiryDate: {
                    set: true,
                    value: expiryDate
                },
                reference: {
                    set: true,
                    value: values.reference
                },
                userId: {
                    set: true,
                    value: userId !== null ? parseInt(userId) : -1
                },
                customerId: {
                    set: true,
                    value: customerId !== null ? parseInt(customerId) : -1
                },
                customContact: {
                    set: true,
                    value: values.customContact
                },
                relationshipNr: {
                    set: true,
                    value: values.relationshipNr
                },
            })
        } else {
            var xdate = quoteDate ? quoteDate : moment().format('YYYY-MM-DD');
            var yDate = expiryDate ? expiryDate : moment().format('YYYY-MM-DD');

            postQuote({
                userId: userId,
                customerId: customerId,
                quoteDate: xdate,
                expiryDate: yDate,
                reference: values.reference,
            })
        }
    }

    const handleItemsValidSubmit = (event, values) => {
        if (values && values.items)
            putItems(param.id, values.items.filter(x => x != null))
        else
            putItems(param.id, [])
    }

    const onInputChange = (index, value, type) => {
        var xAmounts = [...items];
        for (let i = 0; i < xAmounts.length; i++) {

            if (xAmounts[i].show == false) {
                xAmounts[i].quantity = 0;
                xAmounts[i].unitPrice = 0;
                xAmounts[i].totalPrice = 0;
                continue;
            }

            if (i == index) {
                if (type == 1) {
                    xAmounts[i].quantity = value;
                    xAmounts[i].totalPrice = xAmounts[i].unitPrice * value;
                }
                if (type == 2) {
                    xAmounts[i].unitPrice = value;
                    xAmounts[i].totalPrice = xAmounts[i].quantity * value;
                }
                if (type == 3) {
                    xAmounts[i].vat = value;
                }
            }
        }
        setItems(xAmounts);
    }

    const getTotals = (type) => {
        var total = 0;
        if (type == 1) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice;
            })
        }
        if (type == 2) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice * item.vat / 100;
            })
        }
        if (type == 3) {
            items.filter(x => x.show != false).map((item) => {
                total += item.totalPrice + (item.totalPrice * item.vat / 100);
            })
        }
        return total.toFixed(2);
    }

    const onSendEmailClick = () => {
        setCurrentQuoteEmailLanguageCode(quoteUserLanguageCode)
        setSendEmailModal(true);
    }

    const onSendQuoteAcceptClick = () => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.ACCEPT_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteAccept(param.id, {})
            }
        });
    }

    const onSendQuoteRejectClick = () => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.REJECT_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteReject(param.id, {})
            }
        });
    }

    const onSendQuoteInvoicedClick = () => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.INVOICED_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then((result) => {
            if (result.isConfirmed) {
                postQuoteInvoiced(param.id, {})
            }
        });
    }

    const onSendConvertToOrderClick = () => {
        postQuoteConvertToOrder(param.id, {})
    }

    const onSendAddQuoteToCartClick = () => {
        Swal.fire({
            title: "",
            text: intl.formatMessage({ id: 'QUOTE.INFO.ADD_TO_CART_SENTENCE' }),
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: intl.formatMessage({ id: 'GENERAL.YES' })
        }).then(async (result) => {
            if (result.isConfirmed) {
                await postQuoteAddToCart(param.id, {})
                navigate("/checkout-management/checkout-summary")
            }
        });
    }

    const handleSendEmailSubmit = (event, values) => {
        postQuoteEmail(param.id, {
            EmailTo: values.emailAddress,
            LanguageCode: currentQuoteEmailLanguageCode
        })
        setSendEmailModal(false);
    }

    const handleLanguageChange = (language) => {
        setCurrentQuoteEmailLanguageCode(language.lang)
    };

    const addComment = () => {
        if (comment == '') return;
        patchQuote(param.id, {
            note: {
                set: true,
                value: comment
            }
        })
        setComment('')
    }

    const handleDescriptionChange = (index, e) => {
        var xItems = [...items];
        xItems[index].description = e.target.value;
        setItems(xItems);
    }

    const handleCustomContactChange = (e) => {
        setCustomContact(e.target.value);
    }

    const getCurrentUserValue = () => {
        if (quote.user) return `user_${quote.user.id}`;
        if (quote.customer) return `customer_${quote.customer.id}`;
        return '';
    };

    const onSelectedUserChange = (event) => {
        const value = event.target.value;
        if (!value) {
            setUserId(null)
            setCustomerId(null)
            return;
        }

        const [type, id] = value.split('_');
        if (type === 'user') {
            setUserId(id)
            setCustomerId(null)
        } else {
            setUserId(null)
            setCustomerId(id)
        }
    };

    const onHistoryClick = (history) => {
        if (history.tableName === 'Order') {
            navigate(`/order-management/orders/${history.identifier}`);
        }
        else if (history.tableName === 'Invoice') {
            navigate(`/order-management/invoices/${history.identifier}`);
        }
    }

    const getRows = (text) => {
        if (text)
            return text.split('\n').length + 1;

        return 2;
    }

    const getColors = (status, name) => {
        switch (status) {
            case enumQuoteState.Invoiced:
                return <span className="badge badge-primary">{name}</span>
            case enumQuoteState.Accepted:
                return <span className="badge badge-light-success">{name}</span>
            case enumQuoteState.Rejected:
                return <span className="badge badge-light-danger">{name}</span>
            case enumQuoteState.Open:
                return <span className="badge badge-light-warning">{name}</span>
            case enumQuoteState.NotSent:
                return <span className="badge badge-light-dark">{name}</span>
            default:
                return <span className="badge badge-light-primary">{name}</span>
        }
    }
    
    const isQuoteImmutable = () => {
        return quote?.quoteState === enumQuoteState.Invoiced
    }

    const renderAddToCartIcon = () => {
        if (quote?.cartData !== undefined && quote?.cartData !== null && quote?.cartData !== '') {
            return (
                <>
                    <i className={`ki-duotone ki-message-add fs-3x mr-5`} onClick={() => onSendAddQuoteToCartClick()}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </>
            );
        }
        return null;
    }

    const renderQuoteAcceptIcon = () => {
        if (!isQuoteImmutable()) {
            return (
                <>
                    <i className={`ki-duotone ki-user-tick fs-3x mr-5`} onClick={() => onSendQuoteAcceptClick()}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </>
            );
        }
        return null;
    }

    const renderQuoteConvertToOrderIcon = () => {
        if (!isQuoteImmutable()) {
            return (
                <>
                    <i className={`ki-duotone ki-cloud-change fs-3x mr-5`} onClick={() => onSendConvertToOrderClick()}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </>
            );
        }
        return null;
    }

    const renderQuoteRejectIcon = () => {
        if (!isQuoteImmutable()) {
            return (
                <>
                    <i className={`ki-duotone ki-cross-circle fs-3x mr-5`} onClick={() => onSendQuoteRejectClick()}>
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                        <span className="path4"></span>
                    </i>
                </>
            );
        }
        return null;
    }

    const renderSendEmailIcon = () => {
        return (
            <>
                <i className={`ki-duotone fonticon-mail fs-3x mr-5`} onClick={() => onSendEmailClick()}></i>
            </>
        );
    }

    const renderPrintIcon = () => {
        return (
            <>
                <i className={`ki-duotone ki-printer fs-3x mr-5`} onClick={() => onPrintContent()}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                </i>
            </>
        );
    }

    const renderFlatpickr = (ref, onChange, value, placeholder, isReadOnly) => {
        if (isReadOnly === true) {
            return (
                <input
                    type="text"
                    value={value}
                    className="form-control"
                    readOnly
                    placeholder={placeholder}
                />
            )

        } else { 
            return (
                <Flatpickr ref={ref}
                    onChange={onChange}
                    options={{
                        dateFormat: "d-m-Y",
                    }}
                    value={value}
                    className='form-control'
                    placeholder={placeholder}
                />
            )
        }
    }

    const renderUserSelection = (isReadOnly) => {
        const getSelectedOptionText = (entityId) => {
            if (!entityId) return '';
            
            if (entityId.startsWith('user_')) {
                const userId = entityId.replace('user_', '');
                const user = userList?.find(item => item.id === userId);
                if (user) {
                return (user.contact?.company ? `${user.contact.company} - ` : '') + 
                        `${user.name} ${user.surname}`;
                }
            } else if (entityId.startsWith('customer_')) {
                const customerId = entityId.replace('customer_', '');
                const customer = customerList?.find(item => item.id === customerId);
                if (customer) {
                return `${customer.name} ${customer.surname}`;
                }
            }
            return '';
        };
        
        if (isReadOnly === true) {
            return (
                <div className="form-group mb-3">
                    <label className="form-label">
                        {intl.formatMessage({ id: 'QUOTE.FIELD.USER' })}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        value={getSelectedOptionText(getCurrentUserValue())}
                        readOnly
                    />
                </div>
            )

        } else { 
            return (
                <AvField
                    type="select"
                    name="entityId"
                    label={intl.formatMessage({ id: 'QUOTE.FIELD.USER' })}
                    validate={validation}
                    value={getCurrentUserValue()}
                    onChange={onSelectedUserChange}
                >
                    <option value="">
                    {intl.formatMessage({ id: 'GENERAL.SELECT' })}
                    </option>
                    <optgroup label={intl.formatMessage({ id: 'QUOTE.FIELD.USER' })}>
                    {userList?.map((item, index) => (
                        <option 
                        key={`user_${index}`} 
                        value={`user_${item.id}`}
                        >
                        {(item && item.contact && item.contact.company && item.contact.company ? `${item.contact.company} - ` : ``) + item.name + ' ' + item.surname}
                        </option>
                    ))}
                    </optgroup>
                    <optgroup label={intl.formatMessage({ id: 'QUOTE.FIELD.CUSTOMER' })}>
                    {customerList?.map((item, index) => (
                        <option 
                        key={`customer_${index}`} 
                        value={`customer_${item.id}`}
                        >
                        {item.name + ' ' + item.surname}
                        </option>
                    ))}
                    </optgroup>
                </AvField>
            )
        }
    }

    const renderSummaryToolbar = () => {
        if (quote && quote.id > 0 && param.id > 0) {
            return (
                <div className="card-toolbar">
                    <div className="d-flex flex-end rounded-3 mt-5">
                        {renderAddToCartIcon()}
                        {renderQuoteAcceptIcon()}
                        {/* {renderQuoteConvertToOrderIcon()} */}
                        {renderQuoteRejectIcon()}
                        {renderSendEmailIcon()}
                        {renderPrintIcon()}
                    </div>
                </div>
            )
        }
    }

    const renderEventDatePicker = () => {
        var startDate = preparationEvent?.start && moment(new Date(preparationEvent.start).toISOString()).format('DD/MM/YYYY, HH:mm');
        var endDate = preparationEvent?.end && moment(new Date(preparationEvent.end).toISOString()).format('DD/MM/YYYY, HH:mm');
        return (
            <input
                type="text"
                value={preparationEvent && `${startDate}${preparationEvent.end && ' - '}${preparationEvent.end && endDate}`}
                className="form-control form-control-solid cursor-pointer"
                readOnly
                placeholder={intl.formatMessage({ id: "QUOTE.FIELD.PREPARATION_DATE.PLACEHOLDER" })}
                onClick={() => {
                    if (!isQuoteImmutable())
                        setShowEventDatePickerModal(true)
                }}
            />
        )
    }

    const renderSummary = () => {

        var tab = tabs.find(x => x.id == 2);

        return (
            <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                <div className="d-flex flex-column gap-7 gap-lg-10">
                    <div className="card ">
                        <div className="card-header">
                            <div className="card-title">
                                <h2>{intl.formatMessage({id: "QUOTE.QUOTE"})} - (#{quote && quote.quoteNr}) {quote && getColors(quote.quoteState, quote.quoteStateName)}</h2>
                            </div>
                            {renderSummaryToolbar()}
                        </div>
                        <div className="card-body">
                            <AvForm key={1} onValidSubmit={handleQuoteValidSubmit}>
                                <Row>
                                        {
                                            quote && quote.id > 0 && param.id > 0 &&
                                            <Col>
                                                <AvField name="quoteNr" label={intl.formatMessage({id: 'QUOTE.FIELD.NUMBER'})} type="text" value={quote && quote.quoteNr} readOnly={true}/>
                                            </Col>
                                        }
                                    <Col>
                                        <AvField name="reference" label={intl.formatMessage({id: 'QUOTE.FIELD.REFERENCE'})} type="text" value={quote && quote.reference} readOnly={isQuoteImmutable()}/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <label htmlFor="quoteDate" className="form-label">{intl.formatMessage({id: 'QUOTE.FIELD.QUOTE_DATE'})}</label>
                                            {
                                                renderFlatpickr(
                                                    fpquotedate, 
                                                    onQuoteDateChange, 
                                                    quote && moment(quote.quoteDate).format('DD-MM-YYYY'), 
                                                    'Select Quote Date', 
                                                    isQuoteImmutable())
                                            }
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="mb-3">
                                            <label htmlFor="expiryDate" className="form-label">{intl.formatMessage({id: 'QUOTE.FIELD.EXPIRY_DATE'})}</label>
                                            {
                                                renderFlatpickr(
                                                    fpexpirydate, 
                                                    onExpiryDateChange, 
                                                    quote && moment(quote.expiryDate).format('DD-MM-YYYY'), 
                                                    'Select Expiry Date', 
                                                    isQuoteImmutable())
                                            }
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="mb-3">
                                            <label htmlFor="preparationDate" className="form-label">{intl.formatMessage({id: 'QUOTE.FIELD.PREPARATION_DATE'})}</label>
                                            {renderEventDatePicker()}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {renderUserSelection(isQuoteImmutable())}
                                        {
                                            quote && quote.id > 0 && param.id > 0 &&
                                            <AvField name="relationshipNr" label={intl.formatMessage({id: 'QUOTE.FIELD.RELATIONSHIPNR'})} type="number" value={quote && quote.relationshipNr} readOnly={quote.id > 0}/>
                                        }
                                    </Col>
                                    {
                                        quote && quote.id > 0 && param.id > 0 &&
                                        <Col>
                                            <AvField name="customContact" label={intl.formatMessage({id: 'QUOTE.FIELD.CONTACT'})} type="textarea" rows={getRows(customContact)}
                                                    value={quote && quote.customContact} onChange={(e) => handleCustomContactChange(e)} readOnly={isQuoteImmutable()}/>
                                        </Col>
                                    }
                                </Row>
                                {
                                    !isQuoteImmutable() &&
                                    <Row>
                                        <Col className='text-end'>
                                            <AvField type="hidden" name="id" value={quote && quote.id}/>
                                            <Button color="success" type="submit">{intl.formatMessage({id: 'QUOTE.SAVE'})}</Button>
                                        </Col>
                                    </Row>
                                }
                            </AvForm>
                        </div>
                    </div>
                </div>
                {renderItems()}

            </div>
        )
    }

    const moveRow = (index, direction) => {
        const newItems = [...items];
        const targetIndex = direction === 'up' ? index - 1 : index + 1;
        
        // Swap items
        [newItems[index], newItems[targetIndex]] = [newItems[targetIndex], newItems[index]];

        // Update sortOrder based on the new order
        const updatedItems = newItems.map((item, idx) => ({
            ...item,
            sortOrder: idx + 1, // Set sortOrder to match the current index
        }));

        setItems(updatedItems);
    };

    const removeItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);
        
        // Update sortOrder based on the new order
        const updatedItems = newItems.map((item, idx) => ({
            ...item,
            sortOrder: idx + 1, // Set sortOrder to match the current index
        }));

        setItems(updatedItems);
    };

    const addItem = () => {
        var newItem = {
            id: "0",
            description: '',
            unitPrice: 0,
            totalPrice: 0,
            vat: 0,
            sortOrder: items.length + 1,
            show: true
        }
        var newItems = [...items, newItem];
        setItems(newItems)
    };

    const renderMoveRowButtons = (index) => {
        return (
            <div className="flex items-center">
                <div className="flex space-x-2">
                    <Button 
                        className="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
                        onClick={() => moveRow(index, 'up')}
                        disabled={index === 0}
                    >
                        <i className="bi bi-arrow-up"></i>
                    </Button>
                    <Button 
                        className="p-1 hover:bg-gray-200 rounded disabled:opacity-50"
                        onClick={() => moveRow(index, 'down')}
                        disabled={index === items.length - 1}
                    >
                        <i className="bi bi-arrow-down"></i>
                    </Button>
                </div>
            </div>
        )
    };

    const renderItems = () => {
        if (quote && quote.id > 0 && param.id > 0) {

            return (
                <div className="card mt-10">
                    <div className="card-header">
                        <div className="card-title">
                            <h2>{intl.formatMessage({id: "QUOTE.ITEMS"})}</h2>
                        </div>
                    </div>
                    <div className="card-body">
                        <AvForm key={1} onValidSubmit={handleItemsValidSubmit}>
                            <div className='table-responsive'>
                                <table className="table align-middle table-row-dashed" style={{minWidth: "800px"}}>
                                    <thead>
                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                        {
                                            !isQuoteImmutable() &&
                                            <th className="w-75px"></th>
                                        }
                                        <th className="w-400px">{intl.formatMessage({id: "QUOTE.FIELD.DESCRIPTION"})}</th>
                                        <th className="w-75px">{intl.formatMessage({id: "QUOTE.FIELD.QUANTITY"})}</th>
                                        <th className="w-100px">{intl.formatMessage({id: "QUOTE.FIELD.PRICE"})}</th>
                                        <th className="w-100px">{intl.formatMessage({id: "QUOTE.FIELD.AMOUNT"})}</th>
                                        <th className="w-75px">{intl.formatMessage({id: "QUOTE.FIELD.VAT"})}</th>
                                        {
                                            !isQuoteImmutable() &&
                                            <th className="w-75px">{intl.formatMessage({id: "QUOTE.ACTIONS"})}</th>
                                        }
                                    </tr>
                                    </thead>
                                    <tbody className="fw-semibold text-gray-600">

                                    {items && items.map((item, index) => {
                                        if (item.show == false) {
                                            return null;
                                        }
                                        return (
                                            <tr key={`invoce_item_${index}`}>
                                                {
                                                    !isQuoteImmutable() &&
                                                    <td>
                                                        {renderMoveRowButtons(index)}
                                                    </td>
                                                }
                                                <td>
                                                    <AvField 
                                                        type="hidden" 
                                                        name={`items[${index}].id`} 
                                                        value={item.id}
                                                    />
                                                    <AvField 
                                                        type="hidden" 
                                                        name={`items[${index}].sortOrder`} 
                                                        value={item.sortOrder}
                                                    />
                                                    <AvField 
                                                        name={`items[${index}].description`} 
                                                        rows={getRows(item.description)} 
                                                        type="textarea" 
                                                        value={item.description} 
                                                        validate={validation}
                                                        onChange={(e) => handleDescriptionChange(index, e)} 
                                                        readOnly={isQuoteImmutable()}
                                                        className="w-full"
                                                    />
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].quantity`} type="number" value={item.quantity} validate={validation} className="hide-arrow"
                                                             onChange={(e) => onInputChange(index, e.target.value, 1)} readOnly={isQuoteImmutable()}/>
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].unitPrice`} type="number" value={item.unitPrice} validate={validation} className="hide-arrow"
                                                             onChange={(e) => onInputChange(index, e.target.value, 2)} readOnly={isQuoteImmutable()}/>
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].totalPrice`} type="number" value={item.totalPrice} validate={validation} readOnly={true}
                                                             className="form-control-solid"/>
                                                </td>
                                                <td>
                                                    <AvField name={`items[${index}].vat`} type="number" value={item.vat} validate={validation} className="hide-arrow"
                                                             onChange={(e) => onInputChange(index, e.target.value, 3)} readOnly={isQuoteImmutable()}/>
                                                </td>
                                                {
                                                    !isQuoteImmutable() &&
                                                    <td>
                                                        <Button 
                                                            className="p-2 rounded mb-4"
                                                            color="danger" 
                                                            onClick={() => { removeItem(index) }}
                                                        >
                                                            <i className="p-2 bi bi-trash"></i>
                                                        </Button>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td colSpan={6}>
                                        </td>
                                        {
                                            !isQuoteImmutable() &&
                                            <td>
                                                <Button 
                                                    className="p-2 rounded mb-4"
                                                    color="warning" 
                                                    onClick={() => { addItem() }}
                                                >
                                                    <i className="p-2 bi bi-plus-lg"></i>
                                                </Button>
                                            </td>
                                        }
                                    </tr>

                                    <tr className='fs-4 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2}>
                                            {intl.formatMessage({id: 'QUOTE.TOTAL_EXCLUDING_VAT'})}:
                                        </td>
                                        <td>
                                            € {getTotals(1)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    <tr className='fs-4 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2}>
                                            {intl.formatMessage({id: 'QUOTE.TOTAL_VAT'})}:
                                        </td>
                                        <td>
                                            € {getTotals(2)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    <tr className='text-gray-600 fs-2 gs-0'>
                                        <td colSpan={1}></td>
                                        <td colSpan={2}>
                                            {intl.formatMessage({id: 'QUOTE.TOTAL_INCLUDING_VAT'})}:
                                        </td>
                                        <td>
                                            € {getTotals(3)}
                                        </td>
                                        <td colSpan={1}>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <Row className='mt-10'>
                                <Col>
                                </Col>
                                {
                                    !isQuoteImmutable() &&
                                    <Col className='text-end'>
                                        <Button color="success" type="submit">{intl.formatMessage({id: 'QUOTE.SAVE'})}</Button>
                                    </Col>
                                }
                            </Row>
                            <AvField type="hidden" name="id" value={quote && quote.id}/>
                        </AvForm>


                    </div>
                </div>
            )
        }
    }

    const renderQuote = () => {
        if (quote && quote.id > 0 && param.id > 0) {
            var tab = tabs.find(x => x.id == 1);
            return (
                <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                        <div className="card ">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({id: "QUOTE.QUOTE"})} - (#{quote && quote.quoteNr}) {quote && getColors(quote.quoteState, quote.quoteStateName)}</h2>
                                </div>
                                <div className="card-toolbar">
                                    <div className="d-flex flex-end rounded-3 mt-5">
                                        {renderAddToCartIcon()}
                                        {renderQuoteAcceptIcon()}
                                        {/* {renderQuoteConvertToOrderIcon()} */}
                                        {renderQuoteRejectIcon()}
                                        {renderSendEmailIcon()}
                                        {renderPrintIcon()}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body" style={{overflowY: "auto"}}>
                                <QuotePrint quote={quote} component={true} intl={intl}></QuotePrint>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const renderHistory = () => {
        if (quote && quote.id > 0 && param.id > 0) {
            var tab = tabs.find(x => x.id == 3);

            return (
                <div className={`tab-pane fade ${tab.active} ${tab.show}`} id={tab.hash.replace('#', '')} role="tab-panel">
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                        <div className="card card-flush py-4 flex-row-fluid">
                            <div className="card-header">
                                <div className="card-title">
                                    <h2>{intl.formatMessage({id: "ORDER.FIELD.HISTORY"})} {quote && getColors(quote.quoteState, quote.quoteStateName)}</h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <div className="table-responsive">
                                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                        <thead>
                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                            <th className="min-w-100px">{intl.formatMessage({id: "ORDER.FIELD.USER"})}</th>
                                            <th className="min-w-175px">{intl.formatMessage({id: "ORDER.FIELD.COMMENT"})}</th>
                                            <th className="min-w-100px">{intl.formatMessage({id: "ORDER.FIELD.DATE"})}</th>
                                        </tr>
                                        </thead>
                                        <tbody className="fw-semibold text-gray-600">
                                        {historyList && historyList.map((history, index) => {
                                            return (
                                                <tr key={`history_row_${index}`}>
                                                    <td>{history.userName}</td>
                                                    <td 
                                                        className={`${history.tableName === "Order" || history.tableName === "Invoice" ? "cursor-pointer hover:text-primary-light" : ""}`}
                                                        onClick={() => onHistoryClick(history)}>{history.fieldName}
                                                    </td>
                                                    <td><Moment>{history.date}</Moment></td>
                                                </tr>
                                            )
                                        })}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <CardFooter>
                                <Row>
                                    <Col md={10}>
                                        <textarea rows={2} className='form-control' onChange={(e) => setComment(e.target.value)} value={comment}
                                                  placeholder={intl.formatMessage({id: "QUOTE.HISTORY.ADD_NOTE"})}></textarea>
                                    </Col>
                                    <Col md={2}>
                                        <button className="btn btn-primary" onClick={() => addComment()} style={{width: "100%", height: "100%"}}>
                                            <i className="ki-duotone ki-send fs-2x"><span className="path1"></span><span className="path2"></span></i>
                                        </button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </div>
                    </div>
                    {historyLoading && <Loader/>}
                </div>
            )
        }
    }

    const renderSendEmailModal = () => {

        return (
            <Modal centered show={showSendEmailModal} onHide={() => setSendEmailModal(!showSendEmailModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{intl.formatMessage({id: 'QUOTE.MODAL.SEND_EMAIL'})}</Modal.Title>
                    <div className="ms-5">
                        <LanguageSelector 
                            languages={languages}
                            currentLanguageCode={currentQuoteEmailLanguageCode}
                            onLanguageChange={handleLanguageChange}
                            className="ms-5"
                        />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <AvForm key={1} onValidSubmit={handleSendEmailSubmit}>
                        <Row>
                            <Col>
                                <AvField name="emailAddress" label={intl.formatMessage({id: 'QUOTE.MODAL.EMAIL_ADDRESS'})} type="text" 
                                value={quote?.customer?.email ? quote.customer.email : quote?.user?.email} validate={emailValidation}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='text-end'>
                                <AvField type="hidden" name="id" value={quote && quote.id}/>
                                <Button color="success" type="submit">{intl.formatMessage({id: 'QUOTE.MODAL.SEND'})}</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </Modal.Body>
                {loading && <Loader></Loader>}
            </Modal>
        )
    }

    const renderCalendarDataPickerModal = () => {
        var startDate = preparationEvent?.start && moment(new Date(preparationEvent.start).toISOString()).format('YYYY-MM-DDTHH:mm')
        var endDate = preparationEvent?.end && moment(new Date(preparationEvent.end).toISOString()).format('YYYY-MM-DDTHH:mm')
        return (
            <CalendarDatePickerComponent
                show={showEventDatePickerModal}
                onHide={() => setShowEventDatePickerModal(false)}
                onSubmit={handleCalendarPickerSubmit}
                initialData={{
                    title: preparationEvent !== undefined && preparationEvent !== null ? preparationEvent.title :
                        quote.user !== undefined ? `${quote.user.name} ${quote.user.surname}` :
                        quote.customer !== undefined ? `${quote.customer.name} ${quote.customer.surname}` : '',
                    description: preparationEvent !== undefined && preparationEvent !== null ? preparationEvent.description : '',
                    startDate: preparationEvent !== undefined && preparationEvent !== null && preparationEvent.start !== undefined ? startDate : '',
                    endDate: preparationEvent !== undefined && preparationEvent !== null && preparationEvent.end !== undefined ? endDate : ''
                }}
            />
        )
    }

    const handleCalendarPickerSubmit = async (formData) => {
        if (quote && quote.preparationEventId) {
            var response = await patchCalendarEvent(quote.preparationEventId, {
                start: {
                    set: true,
                    value: formData.startDate
                },
                end: {
                    set: true,
                    value: formData.endDate
                },
                title: {
                    set: true,
                    value: formData.title
                },
                description: {
                    set: true,
                    value: formData.description
                },
                eventIdentifier: {
                    set: true,
                    value: enumCalendarEventType.QuotePreparation
                },
                eventSourceId: {
                    set: true,
                    value: quote.id
                }
            })
            if (response.isSuccess) {
                await patchQuote(quote.id, {
                    preparationEventId: {
                        set: true,
                        value: response.data.id
                    }
                })
                setPreparationEvent({
                    start: formData.startDate,
                    end: formData.endDate,
                    title: formData.title,
                    description: formData.description,
                    eventIdentifier: enumCalendarEventType.QuotePreparation,
                    eventSourceId: quote.id
                })
            }
        } else {
            var response = await postCalendarEvent({
                start: formData.startDate,
                end: formData.endDate,
                title: formData.title,
                description: formData.description,
                eventIdentifier: enumCalendarEventType.QuotePreparation,
                eventSourceId: quote.id
            })
            if (response.isSuccess) {
                await patchQuote(quote.id, {
                    preparationEventId: {
                        set: true,
                        value: response.data.id
                    }
                })
                setPreparationEvent({
                    start: formData.startDate,
                    end: formData.endDate,
                    title: formData.title,
                    description: formData.description,
                    eventIdentifier: enumCalendarEventType.QuotePreparation,
                    eventSourceId: quote.id
                })
            }
        }
    }

    return (

        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({id: 'QUOTES.BREADCRUMB.DESCRIPTION'})}</PageTitle>

            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="d-flex flex-column gap-7 gap-lg-10">
                        <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                            <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto">
                                {
                                    tabs.map((tab, index) => (
                                        <PolicyChecker policies={tab.policies}>
                                            <li key={index} className="nav-item">
                                                <a className={`nav-link ${tab.active}`} data-bs-toggle="tab" href={tab.hash} role="tab" aria-selected="true">{tab.name}</a>
                                            </li>
                                        </PolicyChecker>
                                    ))
                                }

                            </ul>
                        </div>
                        <div className="tab-content">
                            <PolicyChecker policies={[enumPolicies.QuoteRead, enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]}>
                                {renderQuote()}
                            </PolicyChecker>
                            <PolicyChecker policies={[enumPolicies.QuoteWrite, enumPolicies.QuoteEdit]}>
                                {renderSummary()}
                                {renderHistory()}
                            </PolicyChecker>
                        </div>
                    </div>
                </div>
            </div>
            {renderSendEmailModal()}
            {renderCalendarDataPickerModal()}

            {loading && <Loader/>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        quote: state.Quote.quote,
        historyList: state.Quote.historyList,
        loading: state.Quote.loading,
        success: state.Quote.success,
        error: state.CalendarEvent.error,
        historyLoading: state.Quote.historyLoading,
        userList: state.User.userList,
        customerList: state.Customer.customerList,
    };
};

const mapActionsToProps = {
    clearState, getQuote, patchQuote, postQuote, putItems, getHistory, postQuoteEmail, postQuoteConvertToOrder, postQuoteAddToCart, postQuoteAccept, postQuoteReject, postQuoteInvoiced, getUserList, getCustomerList, getCalendarEvent, patchCalendarEvent, postCalendarEvent
}

export default connect(mapStateToProps, mapActionsToProps)(Quote)