/* SETTING */
const actionTypes = Object.freeze({
    CLEAR_STATE: 'DISCOUNTS/CLEAR_STATE',

    GET_DISCOUNTS: 'DISCOUNTS/GET_DISCOUNTS',
    GET_DISCOUNTS_SUCCESS: 'DISCOUNTS/GET_DISCOUNTS_SUCCESS',
    GET_DISCOUNTS_FAILED: 'DISCOUNTS/GET_DISCOUNTS_FAILED',

    GET_DISCOUNT: 'DISCOUNTS/GET_DISCOUNT',
    GET_DISCOUNT_SUCCESS : 'DISCOUNTS/GET_DISCOUNT_SUCCESS',
    GET_DISCOUNT_FAILED : 'DISCOUNTS/GET_DISCOUNT_FAILED',
    
    PATCH_DISCOUNT: 'DISCOUNTS/PATCH_DISCOUNT',
    PATCH_DISCOUNT_SUCCESS: 'DISCOUNTS/PATCH_DISCOUNT_SUCCESS',
    PATCH_DISCOUNT_FAILED: 'DISCOUNTS/PATCH_DISCOUNT_FAILED',
    
    POST_DISCOUNT: 'DISCOUNTS/POST_DISCOUNT',
    POST_DISCOUNT_SUCCESS: 'DISCOUNTS/POST_DISCOUNT_SUCCESS',
    POST_DISCOUNT_FAILED: 'DISCOUNTS/POST_DISCOUNT_FAILED',
    
    DELETE_DISCOUNT: 'DISCOUNTS/DELETE_DISCOUNT',
    DELETE_DISCOUNT_SUCCESS: 'DISCOUNTS/DELETE_DISCOUNT_SUCCESS',
    DELETE_DISCOUNT_FAILED: 'DISCOUNTS/DELETE_DISCOUNT_FAILED',

    PATCH_DISCOUNT_ACTIVATION_STATE: 'DISCOUNTS/PATCH_DISCOUNT_ACTIVATION_STATE',
    PATCH_DISCOUNT_ACTIVATION_STATE_SUCCESS: 'DISCOUNTS/PATCH_DISCOUNT_ACTIVATION_STATE_SUCCESS',
    PATCH_DISCOUNT_ACTIVATION_STATE_FAILED: 'DISCOUNTS/PATCH_DISCOUNT_ACTIVATION_STATE_FAILED',

    GET_DISCOUNT_TYPES: 'DISCOUNTS/GET_DISCOUNT_TYPES',
    GET_DISCOUNT_TYPES_SUCCESS: 'DISCOUNTS/GET_DISCOUNT_TYPES_SUCCESS',
    GET_DISCOUNT_TYPES_FAILED: 'DISCOUNTS/GET_DISCOUNT_TYPES_FAILED',

    GET_DISCOUNT_SCOPE_TYPES: 'DISCOUNTS/GET_DISCOUNT_SCOPE_TYPES',
    GET_DISCOUNT_SCOPE_TYPES_SUCCESS: 'DISCOUNTS/GET_DISCOUNT_SCOPE_TYPES_SUCCESS',
    GET_DISCOUNT_SCOPE_TYPES_FAILED: 'DISCOUNTS/GET_DISCOUNT_SCOPE_TYPES_FAILED',

    GET_DISCOUNT_SCOPE_VALUES: 'DISCOUNTS/GET_DISCOUNT_SCOPE_VALUES',
    GET_DISCOUNT_SCOPE_VALUES_SUCCESS: 'DISCOUNTS/GET_DISCOUNT_SCOPE_VALUES_SUCCESS',
    GET_DISCOUNT_SCOPE_VALUES_FAILED: 'DISCOUNTS/GET_DISCOUNT_SCOPE_VALUES_FAILED',
});

export default actionTypes;