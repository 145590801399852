// @flow
import types from './settingConstants';

const INIT_STATE = {
    company: null,
    invoice: null,
    quotation:null,
    lookups: [],
    loading: false,
    success: null,
    error: null
};

const Setting = (state = INIT_STATE, action) => {
    switch (action.type) {
        case types.CLEAR_STATE:
            return {
                ...INIT_STATE
            }

        //COMPANY
        case types.GET_COMPANY_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                company: action.payload.data,
                loading: false
            };
        case types.POST_COMPANY_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_COMPANY_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_COMPANY_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_COMPANY_SETTINGS_FAIL',
                loading: false
            };
        //COMPANY

        //INVOICE
        case types.GET_INVOICE_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_INVOICE_SETTINGS_SUCCESS:
            return {
                ...state,
                invoice: action.payload.data,
                loading: false
            };
        case types.POST_INVOICE_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_INVOICE_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_INVOICE_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_INVOICE_SETTINGS_FAIL',
                loading: false
            };
        //INVOICE

        //QUOTATION
        case types.GET_QUOTATION_SETTINGS:
            return {
                ...state,
                loading: true
            };
        case types.GET_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                quotation: action.payload.data,
                loading: false
            };
        case types.POST_QUOTATION_SETTINGS:
            return {
                ...state,
                loading: false
            };
        case types.POST_QUOTATION_SETTINGS_SUCCESS:
            return {
                ...state,
                success: action.payload.isSuccess ? 'POST_QUOTATION_SETTINGS_SUCCESS' : null,
                error: action.payload.isSuccess ? null : 'POST_QUOTATION_SETTINGS_FAIL',
                loading: false
            };
        //QUOTATION

        //LOOKUP
        case types.GET_LOOKUP_VALUES:
            return {
                ...state,
                lookups: action.payload.data,
                loading: false
            };
        //LOOKUP

        // SMTP SETTINGS
        case types.GET_SMTP_SETTINGS:
        case types.PATCH_SMTP_SETTINGS:
        case types.POST_SMTP_SETTINGS:
        case types.DELETE_SMTP_SETTINGS:
        case types.POST_TEST_EMAIL:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };

        case types.GET_SMTP_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                smtpSettings: action.payload.data.items
            };

        case types.PATCH_SMTP_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.PATCH_SMTP_SETTINGS_SUCCESS'
            };

        case types.POST_SMTP_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.POST_SMTP_SETTINGS_SUCCESS'
            };

        case types.DELETE_SMTP_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.DELETE_SMTP_SETTINGS_SUCCESS'
            };

        case types.POST_TEST_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.POST_TEST_EMAIL_SUCCESS'
            };

        case types.GET_SMTP_SETTINGS_FAILURE:
        case types.PATCH_SMTP_SETTINGS_FAILURE:
        case types.POST_SMTP_SETTINGS_FAILURE:
        case types.DELETE_SMTP_SETTINGS_FAILURE:
        case types.POST_TEST_EMAIL_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        // SMTP SETTINGS

        // NOTIFICATION TEMPLATES
        case types.GET_NOTIFICATION_TEMPLATES:
        case types.GET_NOTIFICATION_TRIGGERS:
        case types.PATCH_NOTIFICATION_TEMPLATES:
        case types.POST_NOTIFICATION_TEMPLATES:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };

        case types.GET_LANGUAGE_CODES_SUCCESS:
            return {
                ...state,
                loading: false,
                code: action.payload
            };

        case types.GET_NOTIFICATION_TRIGGERS_SUCCESS:
            return {
                ...state,
                loading: false,
                triggerTypes: action.payload.data.items
            };

        case types.GET_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                notificationTemplates: action.payload.data.items
            };

        case types.PATCH_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.PATCH_NOTIFICATION_TEMPLATES_SUCCESS'
            };

        case types.POST_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                loading: false,
                success: 'SETTING.PATCH_NOTIFICATION_TEMPLATES_SUCCESS'
            };

        case types.GET_NOTIFICATION_TRIGGERS_FAILURE:
        case types.GET_NOTIFICATION_TEMPLATES_FAILURE:
        case types.PATCH_NOTIFICATION_TEMPLATES_FAILURE:
        case types.POST_NOTIFICATION_TEMPLATES_FAILURE:
        case types.GET_LANGUAGE_CODES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        // NOTIFICATION TEMPLATES

        default:
            return state;
    }
};

export default Setting;
