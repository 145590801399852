import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { Loader, KTIcon } from '../../../../_metronic/helpers'
import { AvGroup, AvForm, AvField, AvInput } from 'availity-reactstrap-validation';
import { Button, Row, Col, Card, Progress, Label } from 'reactstrap';
import Dropzone from 'react-dropzone';
import Barcode from 'react-barcode'
import Moment from 'react-moment'
import Resizer from "react-image-file-resizer";
import Multiselect from 'multiselect-react-dropdown';
import { Audit } from '../../../../_metronic/helpers/components/Audit';
import { Content } from '../../../../_metronic/helpers/components/Content';
import { enumSystemPolicyType } from '../../../../_metronic/helpers';
import { SystemPolicyChecker } from '../../../../_metronic/helpers/components/SystemPolicyChecker';

import { clearState, getProduct, postProduct, patchProduct, uploadImage, getImages, deleteImage, patchImage, getVariants, postVariant, patchVariant, getOptionValues, getCategories, getSuppliers, getModels, getLookupValues } from './productActions';

const Product = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    const navigate = useNavigate();
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        product,
        variants,
        images,
        loading,
        uploadedImageId,
        optionValues,
        categories,
        suppliers,
        models,
        productTypes,
        success
    } = props;

    //actions
    const {
        clearState,
        getProduct,
        postProduct,
        patchProduct,
        patchImage,
        uploadImage,
        getImages,
        deleteImage,
        getVariants,
        postVariant,
        patchVariant,
        getOptionValues,
        getCategories,
        getSuppliers,
        getModels,
        getLookupValues
    } = props;


    const [pageTitle, setPageTitle] = useState('Product');
    const [newVariant, setNewVariant] = useState({});
    const [myFiles, setMyFileList] = useState([])
    const [counter, setCounter] = useState(0)
    const [variantForm, setVariantForm] = useState()
    const [sequence, setSequence] = useState()

    //const modelDropdown = useRef();
    const supplierDropdown = useRef();

    useEffect(() => {

        if (params.id > 0)
            getProduct(params.id);

        getCategories();
        getOptionValues(1);
        getSuppliers();
        getModels();
        getLookupValues(3);

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {

        if (params.id === '0' && product && product.id > 0) {
            navigate(`/catalog-management/products/${product.id}`);
        }

    }, [product])


    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));

            if (success == 'VARIANT.CREATED.SUCESS') {
                variantForm && variantForm.reset();
            }
        }
    }, [success])

    useEffect(() => {
        if (uploadedImageId) {
            onDeleteFile(uploadedImageId);
        }
    }, [uploadedImageId])

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CATALOG.MANAGEMENT.TITLE' }),
            path: '/catalog-management/products',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const validation = {
        required: {
            errorMessage: intl.formatMessage({ id: 'VALIDATION.REQUIRED_FIELD' })
        }
    }

    const onTabClick = (tab) => {
        if (tab == 1) {
            setPageTitle(intl.formatMessage({ id: 'PRODUCT.TITLE' }))
        }

        if (tab == 2) {
            setPageTitle(intl.formatMessage({ id: 'PRODUCT.VARIANTS.TITLE' }))
            if (params.id > 0)
                getVariants(params.id)
        }

        if (tab == 3) {
            setPageTitle(intl.formatMessage({ id: 'PRODUCT.IMAGES.TITLE' }))
            if (params.id > 0)
                getImages(params.id)
        }
    }

    const handleValidSubmit = (event, values) => {

        values = {
            ...values,
            supplierIds: supplierDropdown.current.getSelectedItems().map(s => s.id),
            //modelIds: modelDropdown.current.getSelectedItems().map(s => s.id)
        };

        if (product.id > 0) {
            patchProduct(product.id, { ...values })
        } else {
            postProduct({ ...values })
        }
    }

    const onDropRejected = () => {
        toast.error(intl.formatMessage({ id: 'GENERAL.FILE.INVALID' }));
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                600,
                600,
                "png",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "blob"
            );
        });

    const handleAcceptedFiles = async (filesRaw) => {

        let files = []
        for (let i = 0; i < filesRaw.length; i++) {
            let f = filesRaw[i];

            var image = await resizeFile(f);
            image.name = f.name;

            var counterr = counter + 1;
            setCounter(counterr);

            files.push({
                id: image.name + "-" + counterr,
                file: image,
                name: image.name,
                actionType: 2,
                extension: image.type.split('/')[1],
            })
        }

        files.map(item =>
            Object.assign(item.file, {
                preview: item.file['type'].split('/')[0] === 'image' ? URL.createObjectURL(item.file) : null
            })
        );
        setMyFileList(files)
    };

    const onSaveFile = (id) => {
        var file = myFiles.find(s => s.id == id);
        uploadImage(params.id, file);
    }

    const onDeleteExistFile = (id) => {
        deleteImage(params.id, id)
    }

    const onDeleteFile = (id) => {
        var files = [...myFiles].filter(s => s.id != id);
        setMyFileList(files);
    }

    const handleVariantValidSubmit = (event, values) => {

        if (values.id > 0) {
            patchVariant(params.id, values.id, { ...values })
        } else {
            postVariant(product.id, { ...values })
        }
    }

    const onSequenceDecrease = (item) => {
        if (item.sequence - 1 < 1) return;

        item.sequence = item.sequence - 1;
        setSequence(item.sequence)

        patchImage(product.id, item.id, item)
    }

    const onSequenceIncrease = (item) => {
        if (item.sequence > 100) return;

        item.sequence = item.sequence + 1;
        setSequence(item.sequence)

        patchImage(product.id, item.id, item)
    }

    const getSelectedItems = (list, ids) => {
        if (!list || !ids) return [];

        var items = list.filter((item) => {
            return ids.find(s => s == item.id);
        })

        return items;
    }

    const renderGeneral = () => {

        return (

            <div className="tab-pane fade show active" id="kt_tab_pane_7" role="tabpanel">
                <div className='card'>
                    <div className="card-body">
                        <Audit table="Product" identifier={product.id} />

                        <AvForm key={1} onValidSubmit={handleValidSubmit}>

                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label >{intl.formatMessage({ id: 'PRODUCT.FIELD.NAME' })} <Content table="Product" identifier={product.id} field={"Name"} /></Label>
                                        <AvInput name="name" value={product && product.name} validate={validation} />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvField name="sku" label={intl.formatMessage({ id: 'PRODUCT.FIELD.SKU' })} type="text" value={product && product.sku} validate={validation} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label >{intl.formatMessage({ id: 'PRODUCT.FIELD.DESCRIPTION' })} <Content table="Product" identifier={product.id} field={"Description"} /></Label>
                                        <AvInput name="description" value={product && product.description} validate={validation} />
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvField type="select" name="categoryId" label={intl.formatMessage({ id: 'PRODUCT.FIELD.CATEGORY' })} validate={validation} value={product && product.categoryId}>
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        {categories && categories.map((item, index) => {
                                            return (
                                                <option key={`categories_${index}`} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <AvField name="weight" label={intl.formatMessage({ id: 'PRODUCT.FIELD.WEIGHT' })} type="number" value={product && product.weight} validate={validation} />
                                </Col>
                                <Col md={3}>
                                    <AvField type="select" name="weightUnit" label={intl.formatMessage({ id: 'PRODUCT.FIELD.UNIT' })} validate={validation} value={product && product.weightUnit}>
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        <option value='g'>Gram</option>
                                        <option value='kg'>Kilogram</option>
                                        <option value='t'>Ton</option>
                                    </AvField>
                                </Col>
                                <Col md={6}>
                                    <AvField type="select" name="optionValueId" label={intl.formatMessage({ id: 'PRODUCT.FIELD.COLOR' })} validate={validation} value={product && product.optionValueId}>
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        {optionValues && optionValues.map((item, index) => {
                                            return (
                                                <option key={`optionvalue_${index}`} value={item.id}>{item.name}</option>
                                            )
                                        })}
                                    </AvField>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvField type="select" name="type" label={intl.formatMessage({ id: 'PRODUCT.FIELD.TYPE' })} validate={validation} value={product && product.type}>
                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                        {productTypes && productTypes.map((item, index) => {
                                            return (
                                                <option key={`producttype_${index}`} value={item.value}>{item.name}</option>
                                            )
                                        })}
                                    </AvField>

                                </Col>
                                <Col>
                                    <div className='fv-row mb-8'>
                                        <label className='form-label fs-6'>{intl.formatMessage({ id: 'PRODUCT.FIELD.SUPPLIERS' })}</label>
                                        <Multiselect name="supplierIds" ref={supplierDropdown}
                                            options={suppliers}
                                            selectedValues={getSelectedItems(suppliers, product.supplierIds)}
                                            displayValue="name"
                                            isObject={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className='mb-3'>
                                        <label htmlFor="isActive" className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.ENABLED' })}</label>
                                        <AvField type="checkbox" name="isActive" checked={product && product.isActive} />
                                    </div>
                                </Col>
                            </Row>

                            <AvField type="hidden" name="id" value={product && product.id} />
                            <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                        </AvForm>
                    </div>
                </div>
            </div>
        )
    }

    const renderVariants = () => {
        return (

            <div className="tab-pane fade" id="kt_tab_pane_8" role="tabpanel">
                <div className='card'>
                    <div className='card-header'>
                        <h3 className="card-title">{intl.formatMessage({ id: 'PRODUCT.FIELD.ADDVARIANT' })}</h3>
                    </div>
                    <div className="card-body">

                        <AvForm key={1} onValidSubmit={handleVariantValidSubmit} ref={(e) => (setVariantForm(e))}>
                            <div className='row'>
                                <div className='col-md-7 border border-primary rounded p-5 mt-5'>
                                    <Row>
                                        <Col>
                                            <AvField name="name" label={intl.formatMessage({ id: 'PRODUCT.FIELD.NAME' })} type="text" value={newVariant.name} validate={validation} />
                                        </Col>
                                        <Col>
                                            <AvField name="code" label={intl.formatMessage({ id: 'PRODUCT.FIELD.CODE' })} type="text" value={newVariant.code} validate={validation} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AvField name="weight" label={intl.formatMessage({ id: 'PRODUCT.FIELD.WEIGHT' })} type="number" value={newVariant.weigth} validate={validation} />
                                        </Col>
                                        <Col >
                                            <AvField type="select" name="weightUnit" label={intl.formatMessage({ id: 'PRODUCT.FIELD.UNIT' })} validate={validation} value={newVariant.weightUnit}>
                                                <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                <option value='g'>g</option>
                                                <option value='kg'>kg</option>
                                                <option value='t'>t</option>
                                            </AvField>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col >
                                            <AvField name="width" label={intl.formatMessage({ id: 'PRODUCT.FIELD.WIDTH' })} type="number" value={newVariant.width} validate={validation} />
                                        </Col>
                                        <Col >
                                            <AvField name="height" label={intl.formatMessage({ id: 'PRODUCT.FIELD.HEIGHT' })} type="number" value={newVariant.height} validate={validation} />
                                        </Col>
                                        <Col>
                                            <AvField type="select" name="measurementUnit" label={intl.formatMessage({ id: 'PRODUCT.FIELD.UNIT' })} validate={validation} value={newVariant.weightUnit}>
                                                <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                <option value='mm'>mm</option>
                                                <option value='cm'>cm</option>
                                                <option value='m'>m</option>
                                            </AvField>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AvField name="position" label={intl.formatMessage({ id: 'PRODUCT.FIELD.POSITION' })} type="text" value={newVariant && newVariant.position} />
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <AvField name="redThreshold" label={intl.formatMessage({ id: 'PRODUCT.FIELD.REDTHRESHOLD' })} type="number" value={newVariant.redThreshold} validate={validation} />
                                        </Col>
                                        <Col>
                                            <AvField name="yellowThreshold" label={intl.formatMessage({ id: 'PRODUCT.FIELD.YELLOWTHRESHOLD' })} type="number" value={newVariant.yellowThreshold} validate={validation} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className='mb-3'>
                                            <label htmlFor="isActive" className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.ENABLED' })}</label>
                                            <AvField type="checkbox" name="isActive" value={newVariant.isActive} />
                                        </Col>
                                    </Row>
                                </div>
                                <div className='offset-md-1 col-md-4 border border-primary rounded p-5 mt-5'>
                                    <AvField name="prices[0].price" label={intl.formatMessage({ id: 'PRODUCT.FIELD.PRICE' })} type="number" value={newVariant.price} validate={validation} />
                                    <AvField name="prices[0].salesPrice" label={intl.formatMessage({ id: 'PRODUCT.FIELD.SALESPRICE' })} type="number" value={newVariant.salesPrice} validate={validation} />
                                    <AvField name="prices[0].vat" label={intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' })} type="number" value={newVariant.vat} validate={validation} />
                                    <AvField name="prices[0].isActive" type="hidden" value={true} />
                                </div>

                                <AvField type="hidden" name="id" value={0} />
                                <AvField type="hidden" name="productId" value={product && product.id} />
                                <Button color="success" type="submit">{intl.formatMessage({ id: 'PRODUCT.FIELD.ADDVARIANT' })}</Button>
                            </div>
                        </AvForm>
                    </div>
                </div>

                {variants && variants.map((item, index) => {

                    var price = item.prices && item.prices[0];

                    return (
                        <div key={`variant_${index}`} className='card mt-10'>
                            <div className='card-header'>
                                <div className="card-title">
                                    <h2>{intl.formatMessage({ id: 'PRODUCT.FIELD.VARIANT' })}: {item.name} {item.measurementUnit}</h2>
                                </div>
                                <div className="card-toolbar">

                                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                        <Barcode value={item.code} />
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <AvForm onValidSubmit={handleVariantValidSubmit}>
                                <SystemPolicyChecker policyId={enumSystemPolicyType.StockControl}>
                                    <Row>
                                        <Col>
                                            <div className="d-flex justify-content-end mb-5" data-kt-user-table-toolbar="base">
                                                <Link to={`/stock-management/stocks-print/${item.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='printer' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'GENERAL.PRINT' })}</Link>
                                                <Link to={`/stock-management/stocks-operation/${item.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='scan-barcode' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'PRODUCT.FIELD.ADDDROPSTOCK' })}</Link>
                                                <Link to={`/stock-management/stocks/${item.id}`} className="btn btn-light-info m-1"> <KTIcon iconName='dropbox' className='fs-2x'></KTIcon> {intl.formatMessage({ id: 'STOCK.FIELD.STOCK' })}</Link>
                                                <Audit table="Variant" identifier={item.id} />
                                            </div>
                                        </Col>
                                    </Row>
                                </SystemPolicyChecker>
                                    <div className='row'>
                                        <div className='col-md-7 border border-primary rounded p-10'>

                                            <Row>
                                                <Col>
                                                    <AvGroup>
                                                        <Label >{intl.formatMessage({ id: 'PRODUCT.FIELD.NAME' })} <Content table="Variant" identifier={item.id} field={"Name"} /></Label>
                                                        <AvInput name="name" value={item.name} validate={validation} />
                                                    </AvGroup>
                                                </Col>
                                                <Col>
                                                    <AvField name="code" label={intl.formatMessage({ id: 'PRODUCT.FIELD.CODE' })} type="text" value={item.code} validate={validation} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvField name="weight" label={intl.formatMessage({ id: 'PRODUCT.FIELD.WEIGHT' })} type="number" value={item.weight} validate={validation} />
                                                </Col>
                                                <Col>
                                                    <AvField type="select" name="weightUnit" label={intl.formatMessage({ id: 'PRODUCT.FIELD.UNIT' })} validate={validation} value={item.weightUnit}>
                                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                        <option value='g'>g</option>
                                                        <option value='kg'>kg</option>
                                                        <option value='t'>t</option>
                                                    </AvField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvField name="width" label={intl.formatMessage({ id: 'PRODUCT.FIELD.WIDTH' })} type="number" value={item.width} validate={validation} />
                                                </Col>
                                                <Col>
                                                    <AvField name="height" label={intl.formatMessage({ id: 'PRODUCT.FIELD.HEIGHT' })} type="number" value={item.height} validate={validation} />
                                                </Col>
                                                <Col>
                                                    <AvField type="select" name="measurementUnit" label={intl.formatMessage({ id: 'PRODUCT.FIELD.UNIT' })} validate={validation} value={item.measurementUnit}>
                                                        <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                                        <option value='mm'>mm</option>
                                                        <option value='cm'>cm</option>
                                                        <option value='m'>m</option>
                                                    </AvField>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvField name="position" label={intl.formatMessage({ id: 'PRODUCT.FIELD.POSITION' })} type="text" value={item && item.position} />
                                                </Col>
                                                <Col>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvField name="stockQuantity" disabled={true} label={intl.formatMessage({ id: 'PRODUCT.FIELD.STOCKQUANTITY' })} type="number" value={item.stockQuantity} />
                                                </Col>
                                                <Col>
                                                    <AvField name="availableStockQuantity" disabled={true} label={intl.formatMessage({ id: 'PRODUCT.FIELD.AVAILABLESTOCK' })} type="number" value={item.availableStockQuantity} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <AvField name="redThreshold" label={intl.formatMessage({ id: 'PRODUCT.FIELD.REDTHRESHOLD' })} type="number" value={item.redThreshold} validate={validation} />
                                                </Col>
                                                <Col>
                                                    <AvField name="yellowThreshold" label={intl.formatMessage({ id: 'PRODUCT.FIELD.YELLOWTHRESHOLD' })} type="number" value={item.yellowThreshold} validate={validation} />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <label className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.CREATEDON' })}</label>
                                                    <p> <Moment>{item.createdOn}</Moment></p>
                                                </Col>
                                                <Col>
                                                    <label className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.MODIFIEDON' })}</label>
                                                    <p><Moment>{item.modifiedOn}</Moment></p>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col className='mb-3' md={3}>
                                                    <label htmlFor="isActive" className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.ENABLED' })}</label>
                                                    <AvField type="checkbox" name="isActive" value={item.isActive} />
                                                </Col>
                                            </Row>

                                        </div>
                                        <div className='offset-md-1 col-md-4 border border-primary rounded p-10'>
                                            <AvField name="prices[0].price" label={intl.formatMessage({ id: 'PRODUCT.FIELD.PRICE' })} type="number" value={price && price.price} validate={validation} />
                                            <AvField name="prices[0].salesPrice" label={intl.formatMessage({ id: 'PRODUCT.FIELD.SALESPRICE' })} type="number" value={price && price.salesPrice} validate={validation} />
                                            <AvField name="prices[0].vat" label={intl.formatMessage({ id: 'PRODUCT.FIELD.VAT' })} type="number" value={price && price.vat} validate={validation} />
                                            <div>
                                                <label className="form-label">{intl.formatMessage({ id: 'PRODUCT.FIELD.MODIFIEDON' })}</label>
                                                <p><Moment>{price && price.createdOn}</Moment></p>
                                            </div>
                                        </div>

                                        <AvField type="hidden" name="id" value={item.id} />
                                        <AvField type="hidden" name="productId" value={item.productId} />
                                        <Button color="success" type="submit">{intl.formatMessage({ id: 'GENERAL.SUBMIT' })}</Button>
                                    </div>
                                </AvForm>
                            </div>
                        </div>
                    )
                })}


            </div>
        )
    }

    const renderImages = () => {

        return (

            <div className="tab-pane fade" id="kt_tab_pane_9" role="tabpanel">
                <div className='card'>
                    <div className="card-body">

                        <Dropzone
                            onDrop={acceptedFiles => handleAcceptedFiles(acceptedFiles)}
                            onDropRejected={onDropRejected}
                            accept={['image/png', 'image/jpg', 'image/jpeg']}
                            multiple={true}
                            maxSize={20000000}>
                            {({ getRootProps, getInputProps }) => {
                                return (
                                    <>
                                        <div className="dropzone dz-clickable">
                                            <div className="dz-message needsclick" {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <i className="ki-duotone ki-file-up fs-3x text-primary"><span className="path1"></span><span className="path2"></span></i>
                                                <div className="ms-4">
                                                    <h3 className="fs-5 fw-bold text-gray-900 mb-1">{intl.formatMessage({ id: 'PRODUCT.IMAGE.UPLOAD' })} </h3>
                                                    <span className="fs-7 fw-semibold text-primary opacity-75">{intl.formatMessage({ id: 'PRODUCT.IMAGE.MAX' })}</span>
                                                    <br></br>
                                                    <span className="fs-7 fw-semibold text-primary opacity-75">{intl.formatMessage({ id: 'PRODUCT.IMAGE.FORMATS' })}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }}
                        </Dropzone>

                        <div className={"dropzone-previews mt-3"} id="file-previews">
                            {myFiles.map((f, i) => {
                                return (
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={i + '-file'}>
                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        width={250}
                                                        data-dz-thumbnail=""
                                                        className="avatar-sm rounded bg-light"
                                                        alt={f.file.name}
                                                        src={f.file.preview}
                                                    />
                                                </Col>
                                                <Col className="pl-0">
                                                    <Link to="#" className="text-muted font-weight-bold">
                                                        {f.file.name}
                                                    </Link>
                                                </Col>

                                                <Col className="col-auto">
                                                    <Button
                                                        className="btn btn-icon btn-circle btn-active-success"
                                                        onClick={() => onSaveFile(f.id)}>
                                                        <i className="ki-duotone ki-check-circle fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                        </i>
                                                    </Button>
                                                </Col>

                                                <Col className="col-auto">
                                                    <Button
                                                        className="btn btn-icon btn-circle btn-active-danger"
                                                        onClick={() => onDeleteFile(f.id)}>
                                                        <i className="ki-duotone ki-cross-circle fs-2x">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                        </i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>




                    </div>
                </div>

                <div className='card mt-20'>
                    <div className='card-header'>
                        <h3 className="card-title">{intl.formatMessage({ id: 'PRODUCT.FIELD.RECOREDIMAGES' })}</h3>
                    </div>
                    <div className='card-body'>
                        <div className='row'>

                            {images && images.map((item, index) => {
                                return (
                                    <div key={item.id} className='col-md-3'>
                                        <div className="card h-100 ">
                                            <Audit table="ProductImage" identifier={item.id} />
                                            <div className="card-body d-flex justify-content-center text-center flex-column p-8">
                                                <a href={item.link} download={true} target="_blank" className="text-gray-800 text-hover-primary d-flex flex-column">
                                                    <div className="symbol symbol-150px mb-5">
                                                        <img src={item.link} alt="" />
                                                    </div>
                                                    <div className="fs-7 fw-semibold text-gray-500"><Moment>{item.createdOn}</Moment> </div>
                                                </a>
                                                <div className="fs-7 fw-semibold text-gray-500 mt-5">{intl.formatMessage({ id: 'PRODUCT.FIELD.SEQUENCE' })}
                                                    <Row>
                                                        <Col>
                                                            <div className="input-group" >
                                                                <button className="btn btn-icon btn-outline btn-active-color-primary" type="button" onClick={() => { onSequenceDecrease(item) }}><i className="ki-duotone ki-minus fs-2"></i></button>
                                                                <input type="text" className="form-control" readOnly="readonly" value={item.sequence} />
                                                                <button className="btn btn-icon btn-outline btn-active-color-primary" type="button" onClick={() => { onSequenceIncrease(item) }}><i className="ki-duotone ki-plus fs-2"></i></button>
                                                            </div>
                                                        </Col>

                                                    </Row>


                                                </div>

                                                <div className="fs-7 fw-semibold text-gray-500  mt-5">
                                                    <button type="button" className="btn btn-icon btn-flex btn-active-light-danger w-30px h-30px me-3" onClick={() => onDeleteExistFile(item.id)}>
                                                        <i className="ki-duotone ki-trash fs-3">
                                                            <span className="path1"></span>
                                                            <span className="path2"></span>
                                                            <span className="path3"></span>
                                                            <span className="path4"></span>
                                                            <span className="path5"></span>
                                                        </i>
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'PRODUCT.ITEM.BREADCRUMB' })}</PageTitle>

            <div className="card">
                <div className="card-header card-header-stretch">
                    <h3 className="card-title">{pageTitle}</h3>
                    <div className="card-toolbar">


                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="tab" href="#kt_tab_pane_7" onClick={() => onTabClick(1)}>{intl.formatMessage({ id: 'PRODUCT.TITLE' })}</a>
                            </li>

                            {
                                params.id > 0 ? <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_8" onClick={() => onTabClick(2)}>{intl.formatMessage({ id: 'PRODUCT.VARIANTS.TITLE' })}</a>
                                </li> : <></>
                            }

                            {
                                params.id > 0 ? <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#kt_tab_pane_9" onClick={() => onTabClick(3)}>{intl.formatMessage({ id: 'PRODUCT.IMAGES.TITLE' })}</a>
                                </li> : <></>
                            }

                        </ul>
                    </div>
                </div>
            </div>

            <div className="tab-content" id="myTabContent">
                {renderGeneral()}

                {renderVariants()}

                {renderImages()}
            </div>

            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        product: state.Product.product,
        variants: state.Product.variants,
        images: state.Product.images,
        uploadedImageId: state.Product.uploadedImageId,
        optionValues: state.Product.optionValues,
        categories: state.Product.categories,
        suppliers: state.Product.suppliers,
        models: state.Product.models,
        productTypes: state.Product.productTypes,
        loading: state.Product.loading,
        success: state.Product.success
    };
};

const mapActionsToProps = {
    clearState, getProduct, postProduct, patchProduct, uploadImage, getImages, deleteImage, patchImage, getVariants, postVariant, patchVariant, getOptionValues, getCategories, getSuppliers, getModels, getLookupValues
}

export default connect(mapStateToProps, mapActionsToProps)(Product)